<div class="question container contents_wrap">
  <div class="row justify-content-center mb-4">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="card">
        <div class="card-header d-flex justify-content-between">
          <span class="card-toggler" data-toggle="collapse" data-target="#mainCard" aria-controls="mainCard"
            aria-expanded="false"><i class="fas fa-caret-up nav white rt180 mr-1"></i>質問フォーム</span>
          <a class="no-decoration" [routerLink]="urlDashboard"><i class="fas fa-share nav white mr-1"></i>ダッシュボードへ戻る</a>
        </div>
        <div class="card-body-wrap collapse" id="mainCard">
          <div class="card-body">

            <!-- <div class="row">
              <div class="col-12">
                <span class="small text-monospace text-muted" title="PCの状態やログインからの時間経過により正確でない場合があります。">{{clock}}</span>
              </div>
            </div> -->

            <div class="row">
              <div class="col-12">
                <div class="small alert-danger rounded p-1 pl-2 mb-3" *ngIf="isPlanNotMatch">
                  <span class="text-danger">現在のプランでは質問フォームは利用できません。</span>
                  <a [routerLink]="urlPlan">プラン購入／管理画面</a>
                  から質問可能なプランをご購入ください。
                </div>
                <div class="small alert-danger rounded p-1 pl-2 mb-3" *ngIf="isQuestionCountNotRemain && !isFreePlan">
                  <span class="text-danger">質問フォーム利用回数が残っていません。</span>
                  <a [routerLink]="urlPlan">プラン購入／管理画面</a>
                  から追加の質問フォーム利用回数をご購入ください。
                </div>
                <div class="small alert-danger rounded p-1 pl-2 mb-3"
                  *ngIf="!isPlanNotMatch && !isQuestionCountNotRemain && isPlanCancelAtPeriodEnd">
                  <span class="text-danger">{{planName}} は {{planExpiration}} で終了します。</span>
                  それ以降に質問を送信したい場合には
                  <a [routerLink]="urlPlan">プラン購入／管理画面</a> から有料プランをご購入ください。
                </div>
              </div>
            </div>

            <form id="validationWrap" name="validationWrap" class="needs-validation" novalidate>

              <div class="form-group row">
                <label for="title" class="col-md-2 col-form-label text-md-right">タイトル</label>
                <div class="col-md-10">
                  <input id="title" name="title" type="text" class="form-control" required [(ngModel)]="title"
                    placeholder="分かりやすいタイトルを付けてください">
                  <span class=" invalid-feedback" role="alert">
                    <strong>タイトルを入力してください</strong>
                  </span>
                </div>
              </div>

              <div class="form-group row">
                <label for="content" class="col-md-2 col-form-label text-md-right">本文</label>
                <div class="col-md-10">
                  <textarea id="content" name="content" type="text" class="form-control" required [(ngModel)]="content"
                    placeholder="下記のポイントを参考に質問を作成してください"></textarea>
                  <span class="invalid-feedback" role="alert">
                    <strong>本文を入力してください</strong>
                  </span>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-md-2 col-form-label text-md-right">参考画像</label>
                <div class="col-md-10">
                  <label>
                    <span class="btn btn-primary">
                      画像を追加する
                      <input id="imageFile" type="file" accept="image/*" multiple style="display:none"
                        (change)="addScreenShots($event);">
                    </span>
                  </label>
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 d-flex flex-column align-items-start"
                      *ngFor="let screenShotUrl of screenShotUrls; index as index">
                      <button type="button" class="close" aria-label="Close" (click)="removeScreenShot(index);">
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <img class="img-thumbnail rounded clickable" [src]="screenShotUrl"
                        (click)="openScreenShot(index);">
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row mb-0">
                <label class="col-md-2 col-form-label text-md-right">ポイント</label>
                <div class="col-md-10">
                  <div class="alert alert-success small">
                    欲しい回答を得られるように、以下の点に注意して質問を作成しましょう。<br>
                    ◆「どのコースの」「どのページを学習中に」を忘れずに書きましょう。<br>
                    ◆「～ができない／わからない」だけでなく「～をしようとした際に」などの前提を伝えましょう。<br>
                    ◆「～が～の時に」などの状態／状況を正確に伝えましょう。<br>
                    ◆プログラムコードを載せたり、参考画像を共有したりして問題点を明確に伝えましょう。<br>
                  </div>
                </div>
              </div>

              <div class="form-group row mb-0">
                <div class="col-md-10 offset-md-2">
                  <button (click)="postQuestion()" type="submit" class="btn btn-outline-success"
                    *ngIf="!(isPlanNotMatch || isQuestionCountNotRemain || (isFreePlan && isExpired))">質問を送信する</button>
                  <button type="submit" class="btn btn-outline-secondary"
                    *ngIf="isPlanNotMatch || isQuestionCountNotRemain || (isFreePlan && isExpired)"
                    disabled>質問を送信する</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
      <div class="card" id="question">
        <div class="card-header d-flex justify-content-between">
          <span class="card-toggler" [class.collapsed]="questionList.length === 0" data-toggle="collapse"
            data-target="#questionList" aria-controls="questionList" aria-expanded="false"><i
              class="fas fa-caret-up nav white rt180 mr-1"></i>質問／回答履歴</span>
        </div>
        <div class="card-body-wrap collapse" id="questionList">
          <div class="card-body d-flex flex-row row">
            <div class="item-wrap col-md-10 order-md-2">
              <ng-container *ngFor="let item of questionList; index as index">
                <div class="card my-3">
                  <div class="card-header bg-light text-dark" (click)="enableReplyFlag(item)">
                    <span class="card-toggler d-flex flex-column flex-sm-row" [class.collapsed]="index !== 0"
                      data-toggle="collapse" [attr.data-target]="'#question' + index"
                      [attr.aria-controls]="'#question' + index" aria-expanded="false">
                      <i class="fas fa-caret-up nav rt180 mr-1 align-self-center"></i>
                      <span class="solved text-white small rounded mr-2 px-1 align-self-center text-nowrap"
                        *ngIf="item.replyContent; else noReply">回答済</span>
                      <ng-template #noReply>
                        <span
                          class="no-reply text-white small rounded mr-2 px-1 align-self-center text-nowrap">未回答</span>
                      </ng-template>
                      <span class="unread text-white small rounded mr-2 px-1 align-self-center text-nowrap mt-1 mt-sm-0"
                        *ngIf="item.replyFlag">未読</span>
                      <span class="flex-grow-1 text-selectable">{{item.title}}</span>
                      <span class="flex-shrink-0 align-self-center">{{item.postedAt.toDate() | date:"yyyy年MM月dd日
                        HH:mm"}}</span>
                    </span>
                  </div>
                  <div class="card-body-wrap collapse" [class.show]="index === 0" [id]="'question' + index">
                    <div class="card-body">
                      <div class="text-prewrap text-selectable">{{item.content}}</div>
                      <div *ngIf="item.screenShotUrls[0]" class="mt-2">
                        <span class="card-toggler collapsed small" data-toggle="collapse"
                          [attr.data-target]="'#questionScreenShot' + index"
                          [attr.aria-controls]="'#questionScreenShot' + index" aria-expanded="false"><i
                            class="fas fa-caret-up nav rt180 mr-1"></i>参考画像を表示</span>
                        <div class="collapse" [id]="'questionScreenShot' + index">
                          <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4"
                              *ngFor="let screenShotUrl of item.screenShotUrls; index as imgIndex">
                              <img class="img-thumbnail rounded clickable mt-2" [src]="screenShotUrl"
                                (click)="openPostedScreenShot(item.screenShotUrls, imgIndex);">
                            </div>
                          </div>
                        </div>
                      </div>
                      <ng-container *ngIf="item.replyContent">
                        <hr>
                        <div class="d-flex justify-content-between">
                          <span
                            class="reply-label text-white small rounded mr-2 px-1 align-self-center text-nowrap">回答内容</span>
                          <span>{{item.replyAt.toDate() | date:"yyyy年MM月dd日 HH:mm"}}</span>
                        </div>
                        <div class="text-prewrap text-selectable">{{item.replyContent}}</div>
                        <div *ngIf="item.replyScreenShotUrls[0]" class="mt-2">
                          <span class="card-toggler collapsed small" data-toggle="collapse"
                            [attr.data-target]="'#replyScreenShot' + index"
                            [attr.aria-controls]="'#replyScreenShot' + index" aria-expanded="false"><i
                              class="fas fa-caret-up nav rt180 mr-1"></i>参考画像を表示</span>
                          <div class="collapse" [id]="'replyScreenShot' + index">
                            <div class="row">
                              <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4"
                                *ngFor="let replyScreenShotUrl of item.replyScreenShotUrls; index as imgIndex">
                                <img class="img-thumbnail rounded clickable mt-2" [src]="replyScreenShotUrl"
                                  (click)="openPostedScreenShot(item.replyScreenShotUrls, imgIndex);">
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="col-md-2 order-md-1">
              <div class="progress-pie-chart" id="progress-pie-chart">
                <div class="ppc-progress" id="ppc-progress">
                  <div class="ppc-progress-fill" id="ppc-progress-fill"></div>
                </div>
                <div class="ppc-percents" id="ppc-percents">
                  <div class="pcc-percents-wrapper" id="pcc-percents-wrapper">
                    <span class="text-prewrap"></span>
                  </div>
                </div>
              </div>
              <div class="ask-question" id="ask-question">
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>