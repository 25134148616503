import { CommonModule } from '@angular/common';
import {NgModule} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {SignupComponent} from './signup.component';

@NgModule({
declarations:[SignupComponent],
imports:[
    CommonModule,
    FormsModule
],
exports:[SignupComponent]
})
export class SignupModule {}