import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { CourseService } from 'src/app/services/course.service';
import { LoadingService } from 'src/app/utilities/static/loading.service';

import { User } from 'src/app/models/class/User';
import { Course } from 'src/app/models/interface/Course';

import { AuthConstants as AC } from '../../constants/auth.constants';
import { CommonConstants as CC } from 'src/app/constants/common.constants';
import * as PC from 'src/app/constants/plan.constants';

import { SignupComponent } from 'src/app/components/trial/signup/signup.component';
import { Router } from '@angular/router';
import { CourseIndex } from 'src/app/models/interface/CourseIndex';
import { UserService } from 'src/app/services/user.service';
import { JqueryService } from 'src/app/utilities/static/jquery.service';
import { MomentService } from 'src/app/utilities/static/moment.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-trial',
  templateUrl: './trial.component.html',
  styleUrls: ['./trial.component.sass']
})
export class TrialComponent implements OnInit, AfterViewInit {
  public pc = PC.PLAN_EXT;

  public courseListData: Course[] = [];
  public courseInfoTitle: string = CC.BLANK;
  public courseInfoIndex: CourseIndex[] = [];

  public user: User = null;
  public planCode: PC.PLANCODE_EXT = null;
  public year: number = MomentService.newDate().getFullYear();
  public page = { plan: 'plan', lp: 'lp' };
  public allCourseList: string = CC.COURSE_SORT_TYPE.KEY.ALL;
  public javaCourseList: string = CC.COURSE_SORT_TYPE.KEY.JAVA;
  public phpCourseList: string = CC.COURSE_SORT_TYPE.KEY.PHP;
  public cookieCourseList: string = CC.COURSE_SORT_TYPE.KEY.ALL;
  public isExpired = true;

  @ViewChild(SignupComponent, { static: true })
  private signupComponent: SignupComponent;

  constructor(
    private userService: UserService,
    private cookieService: CookieService,
    private authService: AuthService,
    private courseService: CourseService,
    private router: Router
  ) { }

  ngOnInit() {
    this.authService.userState
      .subscribe(user => { this.init(); });
    this.init();
  }

  init() {
    this.user = this.authService.getUser() || new User;
    this.planCode = this.user.planCode;
    this.courseService.getTrialCourseList()
      .then((courseListData) => {
        this.courseListData = courseListData;
      });
    const authTime: Date = MomentService.newDate();
    const expiration: Date = this.user.expiration;
    this.isExpired = !MomentService.isSameOrBeforeWithDateObject(authTime, expiration);
    this.initCourseList();
  }

  ngAfterViewInit() {
    const cookie = this.cookieService.get(AC.GTAG.TRIAL);
    if (!cookie) {
      // Event snippet for LPから本サイト conversion page
      gtag('event', 'conversion', { 'send_to': 'AW-10944845857/OIxkCPymhswDEKGw9OIo' });
      this.cookieService.set(AC.GTAG.TRIAL, AC.GTAG.TRIAL, 3650);
    }
  }

  dummyLoading() {
    LoadingService.dummy(0.6);
  }

  showSignupPanel() {
    LoadingService.on();
    this.signupComponent.showPanel();
    LoadingService.off();
  }

  navigate(page) {
    if (page === this.page.plan) {
      if (this.planCode === this.pc.free_20210901 && (this.isExpired || !this.user.expiration)) {
        this.router.navigate([CC.URL.AUTH.PLAN]);
      } else {
        this.router.navigate([CC.URL.DASHBOARD]);
      }
    } else if (page === this.page.lp) {
      window.location.href = CC.URL.OTHER.LP;
    }
  }

  showCourseInfoPanel(title: string, course: string, event: any) {
    event.stopPropagation();
    this.courseInfoTitle = title;
    this.courseService.getCourseIndex(course)
      .then(couseIndex => {
        this.courseInfoIndex = couseIndex;
        JqueryService.showModal(CC.ID.COURSE_INFO_PANEL);
      })
  }

  sort(courseList: string) {
    this.dummyLoading();
    this.courseListData.filter(course => {
      return Object.keys(CC.COURSE_SORT_TYPE.LIST[courseList]).includes(course.key);
    }).forEach(course => {
      if (course.key !== 'introduction' && course.key !== 'setup') {
        JqueryService.addClass(course.key, 'fadeIn');
        if (courseList !== CC.COURSE_SORT_TYPE.KEY.ALL && CC.COURSE_SORT_TYPE.LIST[courseList][course.key] !== 0) {
          JqueryService.addData(course.key + ' .course-link', 'number', CC.COURSE_SORT_TYPE.LIST[courseList][course.key]);
          JqueryService.addData(course.key, 'number-show', 'true');
        } else {
          JqueryService.removeData(course.key, 'number');
          JqueryService.addData(course.key, 'number-show', 'false');
        }
      } else {
        JqueryService.removeClass(course.key, 'fadeIn');
      }
    });

    this.courseListData.filter(course => {
      return !Object.keys(CC.COURSE_SORT_TYPE.LIST[courseList]).includes(course.key);
    }).forEach(course => {
      JqueryService.removeClass(course.key, 'fadeIn');
    })

    this.userService.setCourseSortType(courseList, true);
    courseList === CC.COURSE_SORT_TYPE.KEY.JAVA ? JqueryService.scrollToAnchor('java-basic', -60) : JqueryService.scrollToAnchor('php-basic', -60);
  }

  initSort(course: string): boolean {
    if (course !== 'introduction' && course !== 'setup') {
      return Object.keys(CC.COURSE_SORT_TYPE.LIST[this.cookieCourseList]).includes(course);
    } else {
      return false;
    }
  }

  initNumber(course: string): number | null {
    if (this.cookieCourseList !== CC.COURSE_SORT_TYPE.KEY.ALL) {
      return CC.COURSE_SORT_TYPE.LIST[this.cookieCourseList][course] !== 0 ? CC.COURSE_SORT_TYPE.LIST[this.cookieCourseList][course] : null;
    }
    return null;
  }

  initNumberIsShow(course: string): boolean {
    return ((this.cookieCourseList !== CC.COURSE_SORT_TYPE.KEY.ALL) && (CC.COURSE_SORT_TYPE.LIST[this.cookieCourseList][course] !== 0));
  }

  showBillingGuidePanel = (): void => {
    JqueryService.showModal(CC.ID.BILLING_GUIDE_PANEL);
  }

  private initCourseList() {
    this.cookieCourseList = this.cookieService.get(CC.COURSE_SORT_TYPE.COOKIE.TRIAL_KEY) || CC.COURSE_SORT_TYPE.KEY.ALL;
  }
}
