import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { UserGuard } from '../guards/user.guard';

import { CommonConstants as CC } from 'src/app/constants/common.constants';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BookingComponent } from './booking/booking.component';
import { QuestionComponent } from './question/question.component';
import { TrialComponent } from './trial/trial.component';
import { CourseComponent } from './course/course.component';

const routes: Routes = [
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'booking', component: BookingComponent, canActivate: [AuthGuard] },
  { path: 'question', component: QuestionComponent, canActivate: [AuthGuard] },
  { path: 'course', children: [{ path: '**', component: CourseComponent, canActivate: [UserGuard] }] },
  { path: 'trial', component: TrialComponent },
  { path: CC.BLANK, redirectTo: CC.URL.DASHBOARD, pathMatch: 'full' },
  { path: '**', redirectTo: CC.URL.DASHBOARD }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
