import { Injectable } from '@angular/core';
import {
  arrayRemove, arrayUnion, collection, collectionGroup,
  deleteField, doc, DocumentReference, DocumentSnapshot,
  endAt, FieldValue, Firestore, getDoc, getDocs,
  limit, orderBy, query, Query, QuerySnapshot,
  runTransaction, serverTimestamp, setDoc, startAt,
  Transaction, where, WhereFilterOp, writeBatch, WriteBatch
} from '@angular/fire/firestore';
import isUndefined from 'lodash/isundefined';
import { CommonConstants as CC } from 'src/app/constants/common.constants';
import { getDirection, OrderBy } from 'src/app/models/interface/OrderBy';
import { Where } from 'src/app/models/interface/Where';
import { MomentService } from 'src/app/utilities/static/moment.service';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(
    private firestore: Firestore
  ) { }

  private getCollectionReference = (path: string[]): Query =>
    collection(this.firestore, path[0], ...path.slice(1));

  private getCollectionGroupReference = (key: string): Query =>
    collectionGroup(this.firestore, key);

  public getDocumentReference = (path: string[]): DocumentReference =>
    doc(this.firestore, path[0], ...path.slice(1));

  public getCollection = (path: string[]): Promise<QuerySnapshot> =>
    getDocs(this.getCollectionReference(path));

  public getDocument = (path: string[]): Promise<DocumentSnapshot> =>
    getDoc(this.getDocumentReference(path));

  public getDocumentFromReference = (docRef: DocumentReference):
    Promise<DocumentSnapshot> => getDoc(docRef);

  public setDocument = (path: string[], data: any, merge: boolean = true):
    Promise<void> => setDoc(this.getDocumentReference(path), data, { merge });

  public searchDocument = (path: string[], whereClauses: Where[],
    limitCount?: number, orderByField?: OrderBy): Promise<QuerySnapshot> =>
    getDocs(this.setWhereClause(this.getCollectionReference(path),
      whereClauses, limitCount, orderByField));

  private setWhereClause = (queryRef: Query, whereClauses: Where[],
    limitCount?: number, orderByField?: OrderBy): Query => {
    if (whereClauses.length > 0) {
      whereClauses.forEach(whereClause => {
        const op: WhereFilterOp = (isUndefined(whereClause.op)
          ? CC.DOCUMENT.OP.EQ : whereClause.op) as WhereFilterOp;
        const value: any = whereClause.value === null ? whereClause.value
          : whereClause.isDate ? MomentService.newDate(whereClause.value)
            : whereClause.isNumber ? +whereClause.value
              : whereClause.isBoolean ? (whereClause.value === CC.TRUE)
                : whereClause.value;
        queryRef = query(queryRef, where(whereClause.field, op, value));
        if (limitCount !== undefined) {
          queryRef = query(queryRef, limit(limitCount));
        }
        if (orderByField !== undefined) {
          queryRef = query(queryRef, orderBy(orderByField.field, getDirection(orderByField)));
        }
      });
    }
    return queryRef;
  }

  private setForwardMatches = (queryRef: Query, forwardMatches: Where[]):
    Query => (forwardMatches.length === 0) ? queryRef : query(queryRef,
      ...forwardMatches.map(where => orderBy(where.field)),
      startAt(...forwardMatches.map(where => where.value)),
      endAt(...forwardMatches.map(where => where.value + CC.DOCUMENT.OP.F8FF)));

  public getRangeWhereClause = (field: string, start: string, end: string):
    Where[] => {
    const whereClause: Where[] = [];
    CC.IS_BLANK(start) || whereClause.push({
      field: field, op: CC.DOCUMENT.OP.GTEQ, value: start
    });
    CC.IS_BLANK(end) || whereClause.push({
      field: field, op: CC.DOCUMENT.OP.LTEQ, value: end
    });
    return whereClause;
  }

  public getNewDocumentReference = (path: string): DocumentReference =>
    doc(collection(this.firestore, path[0]));

  public searchDocumentByRange = (path: string[], field: string,
    start: string, end: string): Promise<QuerySnapshot> =>
    this.searchDocument(path, this.getRangeWhereClause(field, start, end));

  public searchDocumentFromCollectionGroup = (key: string,
    whereClause: Where[]): Promise<QuerySnapshot> =>
    getDocs(this.setWhereClause(this.getCollectionGroupReference(key), whereClause));

  public searchDocumentByRangeFromCollectionGroup = (key: string,
    field: string, start: string, end: string): Promise<QuerySnapshot> =>
    this.searchDocumentFromCollectionGroup(key, this.getRangeWhereClause(field, start, end));

  public searchDocumentWithForwardMatches = (path: string[],
    whereClause: Where[], forwardMatches: Where[]): Promise<QuerySnapshot> =>
    getDocs(this.setForwardMatches(this.setWhereClause(
      this.getCollectionReference(path), whereClause), forwardMatches));

  public batch = (): WriteBatch => writeBatch(this.firestore);

  public runTransaction = (updateFunction: (transaction: Transaction)
    => Promise<boolean>): Promise<boolean> =>
    runTransaction<boolean>(this.firestore, transaction => updateFunction(transaction));

  public static getServerTimestamp = (): FieldValue => serverTimestamp();

  public static getDeleteField = (): FieldValue => deleteField();

  public static arrayRemove = (elem: any): FieldValue => arrayRemove(elem);

  public static arrayUnion = (elem: any): FieldValue => arrayUnion(elem);

}
