import { Component, OnInit } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { BookingService } from '../../../services/booking.service';
import { LoadingService } from '../../../utilities/static/loading.service';
import { ReportingService } from '../../../utilities/injectable/reporting.service';
import { JqueryService } from '../../../utilities/static/jquery.service';
import { MomentService } from '../../../utilities/static/moment.service';

import { CommonConstants as CC } from '../../../constants/common.constants';
import { BookingConstants as BC } from '../../../constants/booking.constants';
import { BookingCell, BookingCellMode } from '../../../models/class/BookingCell';
import { BookingData } from '../../../models/class/BookingData';

@Component({
  selector: 'app-booking-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.sass']
})
export class ModalComponent implements OnInit {

  private modalSubject: Subject<void> = new Subject();
  public modalState: Observable<void> = this.modalSubject.asObservable();

  public timeSlotStart: string[] = [];
  public timeSlotEnd: string[] = [];
  public dateMin: string = CC.BLANK;
  public dateMax: string = CC.BLANK;
  public title: string = CC.BLANK;
  public saveBtnTitle: string = CC.BLANK;
  public bookingDateWeekDay: string = CC.BLANK;
  public bookingData: BookingData = null;
  public isBooked = false;
  public notice: string = BC.MESSAGE.ERROR.IS_LIMIT_OVER;

  constructor(
    private bookingService: BookingService,
    private reportingService: ReportingService
  ) { }

  ngOnInit() {
    this.timeSlotStart = this.bookingService.getTimeSlotPullDownData();
    this.timeSlotEnd = this.bookingService.getTimeSlotPullDownData(true);
    this.dateMin = this.bookingService.getDate();
    this.dateMax = this.bookingService.getMaxDate();
    this.bookingData = new BookingData();
  }

  validation(): boolean {
    const result: boolean = JqueryService.formValidation();
    if (!result) {
      this.reportingService.errorToast(
        BC.MESSAGE.ERROR.DATE_INVALID,
        BC.TITLE.FAIL.DATE_VALIDATION
      );
    }
    return result;
  }

  cellClick(cell: BookingCell) {
    this.dateMin = this.bookingService.getDate();
    this.dateMax = this.bookingService.getMaxDate();
    if (cell.mode === BookingCellMode.open) {
      this.title = BC.MODAL.TITLE.INPUT;
      this.saveBtnTitle = BC.MODAL.BUTTON.INPUT;
      this.isBooked = false;
      this.bookingData.setData(cell);

      if (BC.TIME.UNIT === 30) {
        if (this.bookingData.end === BC.TIME.END) {
          this.bookingData.start = MomentService.getTime(this.bookingData.start, - BC.TIME.UNIT);
        } else {
          this.bookingData.end = MomentService.getTime(this.bookingData.end, BC.TIME.UNIT);
        }
      }

      this.bookingDateWeekDay = this.bookingService.getWeekDay(this.bookingData.date);
      JqueryService.showModal(CC.ID.MODAL_FORM_ID);
    } else if (cell.mode === BookingCellMode.booked) {
      this.title = BC.MODAL.TITLE.UPDATE;
      this.saveBtnTitle = BC.MODAL.BUTTON.UPDATE;
      this.isBooked = true;
      this.bookingData.setData(cell);
      this.bookingData.original.display = this.bookingService.getDisplayInfo(this.bookingData);
      this.bookingDateWeekDay = this.bookingService.getWeekDay(this.bookingData.date);
      JqueryService.showModal(CC.ID.MODAL_FORM_ID);
    }
    this.bookingService.setModalOpenData(null);
  }

  bookingDateChanged() {
    this.bookingDateWeekDay = this.bookingService.getWeekDay(this.bookingData.date);
  }

  bookingTimeChanged(isEnd: boolean, offset: number = CC.ZERO) {
    const target: any = isEnd
      ? JqueryService.getOptions(CC.ID.MODAL_FORM_BOOKING_END_ID)
      : JqueryService.getOptions(CC.ID.MODAL_FORM_BOOKING_START_ID);
    if (!((offset === CC.MINUS_ONE && target.selectedIndex <= CC.ZERO) ||
      (offset === CC.ONE && target.selectedIndex >= target.length + CC.MINUS_ONE))) {
      if (offset !== CC.ZERO) {
        isEnd
          ? this.bookingData.end = this.bookingService.getOffsetTime(this.bookingData.end, offset)
          : this.bookingData.start = this.bookingService.getOffsetTime(this.bookingData.start, offset);
      }
      // if (this.bookingData.start >= this.bookingData.end) {
        isEnd
          ? this.bookingData.start = this.bookingService.getOffsetTime(this.bookingData.end, CC.MINUS_ONE)
          : this.bookingData.end = this.bookingService.getOffsetTime(this.bookingData.start, CC.ONE);
      // }
    }
  }

  bookingSave() {
    if (this.validation()) {
      const param: any = { ...CC.CONFIRM_DEFAULTS };
      Object.assign(param, {
        onConfirm: () => this.bookingSaveConfirm(true),
        onCancel: () => this.bookingSaveConfirm(false),
        title: () => {
          this.bookingData.display = this.bookingService.getDisplayInfo(this.bookingData);
          return this.bookingData.display + (this.isBooked
            ? BC.MODAL.MESSAGE.CONFIRM.UPDATE
            : BC.MODAL.MESSAGE.CONFIRM.SAVE);
        }
      });
      LoadingService.on(false);
      JqueryService.showConfirmation(CC.ID.MODAL_CONFIRM_SAVE_ID, param);
      JqueryService.onHiddenConfirmation(CC.ID.MODAL_CONFIRM_SAVE_ID, () => {
        LoadingService.off();
        JqueryService.offHiddenConfirmation(CC.ID.MODAL_CONFIRM_SAVE_ID);
      });
    }
  }

  bookingCancel() {
    if (this.validation()) {
      const param: any = { ...CC.CONFIRM_DEFAULTS };
      Object.assign(param, {
        onConfirm: () => this.bookingCancelConfirm(true),
        onCancel: () => this.bookingCancelConfirm(false),
        title: () => this.bookingData.original.display
          + BC.MODAL.MESSAGE.CONFIRM.CANCEL
      });
      LoadingService.on(false);
      JqueryService.showConfirmation(CC.ID.MODAL_CONFIRM_CANCEL_ID, param);
      JqueryService.onHiddenConfirmation(CC.ID.MODAL_CONFIRM_CANCEL_ID, () => {
        LoadingService.off();
        JqueryService.offHiddenConfirmation(CC.ID.MODAL_CONFIRM_CANCEL_ID);
      });
    }
  }

  private bookingSaveConfirm = (isConfirmed: boolean): void => {
    LoadingService.off();
    if (isConfirmed) {
      this.bookingService.bookingSave(this.bookingData)
        .then(needsReload => {
          if (needsReload) {
            JqueryService.hideModal(CC.ID.MODAL_FORM_ID);
            this.modalSubject.next();
          }
        });
    }
  }

  private bookingCancelConfirm = (isConfirmed: boolean): void => {
    LoadingService.off();
    if (isConfirmed) {
      this.bookingService.bookingCancel(this.bookingData)
        .then(needsReload => {
          if (needsReload) {
            JqueryService.hideModal(CC.ID.MODAL_FORM_ID);
            this.modalSubject.next();
          }
        });
    }
  }
}
