import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import { CommonConstants as CC } from 'src/app/constants/common.constants';
import * as PC from 'src/app/constants/plan.constants';
import { User } from 'src/app/models/class/User';
import { AuthService } from 'src/app/services/auth.service';
import { JqueryService } from 'src/app/utilities/static/jquery.service';
import { LoadingService } from 'src/app/utilities/static/loading.service';
import { MomentService } from 'src/app/utilities/static/moment.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {

  public title: string = environment.appname;
  public urlRoot: string = CC.URL.ROOT;
  public urlSignin: string = CC.URL.AUTH.SIGNIN;
  public urlSignup: string = CC.URL.AUTH.SIGNUP;
  public urlSignout: string = CC.URL.AUTH.SIGNOUT;
  public urlUpdateInfo: string = CC.URL.AUTH.UPDATE.INFO;
  public urlAdmin: string = CC.URL.ADMIN.ROOT;
  public urlTrial: string = CC.URL.TRIAL;
  public urlPlan: string = CC.URL.AUTH.PLAN;

  public user: User = null;
  public isNavShow: boolean = true;
  public isExpired: boolean = true;

  public pc = PC.PLAN_EXT;

  constructor(
    private titleService: Title,
    private authService: AuthService,
    private router: Router
  ) {
    this.authService.userState.subscribe(user => {
      this.user = user;
      if (this.authService.isAuthenticated()) {
        const authTime: Date = MomentService.newDate();
        const expiration: Date = this.user.expiration;
        this.isExpired = !MomentService.isSameOrBeforeWithDateObject(authTime, expiration);
      }
      LoadingService.off();
    });
  }

  async ngOnInit() {
    LoadingService.on();
    await this.router.events.subscribe((events) => {
      if (events instanceof NavigationStart) {
        if (events.url === this.urlTrial) {
          this.isNavShow = false;
        } else {
          this.isNavShow = true;
        }
        if (!events.url.includes('/course/')) {
          this.titleService.setTitle(this.title);
        }
      }
    });
  }

  hideDropdownMenu() {
    JqueryService.hideCollapse(CC.ID.NAV_COLLAPSE);
  }

  endMasquerade() {
    this.authService.endMasquerade();
  }
}
