export class ErrorConstants {
    static readonly ERRORS = {
        'auth/email-already-in-use': 'メールアドレスが既に登録されています。',
        'auth/invalid-email': 'メールアドレスの形式が正しくありません。',
        'auth/weak-password': 'パスワードは半角英数混在、8文字以上、32文字以下で作成してください。',
        'auth/user-not-found': 'このメールアドレスは登録されていません。',
        'auth/wrong-password': 'パスワードが正しくありません。',
        'auth/network-request-failed': '通信が正常に行えませんでした。',
        'auth/user-mismatch': 'すべてのブラウザでログアウトしてください。',
        'booking/already-booked': '予約時間内に予約済みの時間帯が含まれています。',
        'booking/full-booked': '予約時間内に満枠の時間帯が含まれています。',
        'booking/unbooked': '対象の予約が存在しません。',
        'booking/room-assign-failed': '予約時間内に満枠の時間帯が含まれています。',
        'booking/room-reassign-failed': '同じルームでの延長ができませんでした。',
        'booking/plan-not-match': 'このプランでは授業を予約できません。',
        'booking/count-not-remain': '予約可能回数が残っていません。',
        'booking/unknown': '通信が正常に行えませんでした。',
        'question/plan-not-match': 'このプランでは質問を送信できません。',
        'question/count-not-remain': '質問可能回数が残っていません。',
        'question/unknown': '通信が正常に行えませんでした。',
    };
}
