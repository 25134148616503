import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { IAlbum, Lightbox } from 'ngx-lightbox';

import { CommonConstants as CC } from 'src/app/constants/common.constants';
import { QuestionConstants as QC } from 'src/app/constants/question.constants';
import { MomentConstants as MC } from 'src/app/constants/moment.constants';
import { BookingConstants as BC } from 'src/app/constants/booking.constants';
import * as PC from 'src/app/constants/plan.constants';
import { AuthService } from 'src/app/services/auth.service';
import { MomentService } from 'src/app/utilities/static/moment.service';
import { JqueryService } from 'src/app/utilities/static/jquery.service';
import { QuestionService } from 'src/app/services/question.service';
import { LoadingService } from 'src/app/utilities/static/loading.service';
import { User } from 'src/app/models/class/User';
import { Question } from 'src/app/models/interface/Question';


@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.sass']
})
export class QuestionComponent implements OnInit, OnDestroy {

  private clockSubscription: Subscription = new Subscription();
  private refreshInterval: number = 1800;
  private refreshCountDown: number = this.refreshInterval;
  public isExpired: boolean = true;
  public clock: string = CC.BLANK;

  public urlDashboard: string = CC.URL.DASHBOARD;
  public urlPlan: string = CC.URL.AUTH.PLAN;
  public title: string = CC.BLANK;
  public content: string = CC.BLANK;
  public screenShotUrls: string[] = [];
  public pc = PC.PLAN;
  private screenShots: File[] = [];
  private lightboxAlbum: IAlbum[] = [];
  private postedLightboxAlbum: IAlbum[] = [];
  private replyCount: number = 0;
  private zoomCount: number = 0;
  private upperLimit: number = CC.CUSTOM_LIGHT_BOX.ZOOM_COUNT;
  private lowerLimit: number = -CC.CUSTOM_LIGHT_BOX.ZOOM_COUNT;
  private zoomIn: string = '.lb-zoomIn';
  private zoomOut: string = '.lb-zoomOut';

  public questionList: Question[] = [];
  public planName: string = CC.BLANK;
  public planExpiration: string = CC.BLANK;
  public isFreePlan: boolean = false;
  public isQuestionCountNotRemain: boolean = false;
  public isPlanNotMatch: boolean = false;
  public isPlanCancelAtPeriodEnd: boolean = false;

  private user: User = null;

  constructor(
    private authService: AuthService,
    private questionService: QuestionService,
    private lightbox: Lightbox
  ) { }

  ngOnDestroy() {
    this.clockSubscription.unsubscribe();
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.pingAndSetClock();
    this.user = this.authService.getUser(true);
    this.resetQuestionForm();
    this.setPlanInfo();
    this.questionService.getQuestionList(this.user.uid)
      .then(questionList => {
        this.questionList = questionList;
        JqueryService.showCollapse(CC.ID.MAIN_CARD_ID);
        if (this.questionList.length !== 0) {
          JqueryService.showCollapse(CC.ID.QUESTION_LIST_ID);
          this.enableReplyFlag(questionList[0]);
        }
        this.setReplyCount();
        this.setPercent();
      });
    JqueryService.setTextareaAutoResize(CC.ID.FORM.CONTENT);
  }

  resetQuestionForm() {
    this.title = CC.BLANK;
    this.content = CC.BLANK;
    this.screenShots = [];
    this.screenShotUrls = [];
    JqueryService.clearFormValidation();
  }

  setPlanInfo() {
    const planStatus: PC.PLANSTATUS = this.user.stripeSubscription ?
      this.user.stripeSubscription.status as PC.PLANSTATUS : PC.STATUS.active;
    this.planName = PC.getPlanTitle(this.user.planCode as PC.PLANCODE);
    this.planExpiration = this.user.getExpirationDateTimeString();
    this.isFreePlan = this.user.planCode === PC.PLAN.free_20210901;
    this.isQuestionCountNotRemain = ((this.user.questionCount < CC.ONE)
      && (planStatus !== PC.STATUS.canceled)
      && (this.user.planCode === PC.PLAN.free_20210901
        || this.user.planCode === PC.PLAN.light_20210901));
    this.isPlanNotMatch = ((planStatus === PC.STATUS.canceled)
      || !(this.user.planCode === PC.PLAN.free_20210901
        || this.user.planCode === PC.PLAN.light_20210901));
    this.isPlanCancelAtPeriodEnd = this.user.stripeSubscription ?
      this.user.stripeSubscription.cancel_at_period_end : false;
  }

  enableReplyFlag(question) {
    if (question.replyFlag) {
      this.questionList.forEach((questionCheck) => {
        if (questionCheck.uid === question.uid) {
          this.questionService.updateReplyFlag(question.uid);
          questionCheck.replyFlag = false;
        }
      })
    }
  }

  setReplyCount() {
    this.replyCount = 0;
    this.questionList.forEach((question) => {
      if (question.replyContent) {
        this.replyCount++;
      }
    })
  }

  setPercent() {
    const questionCount = this.questionList.length;
    let percent = 0;
    if (this.replyCount !== 0 && questionCount !== 0) {
      percent = Math.floor(this.replyCount / questionCount * 100);
    }
    const deg = (360 * percent) / 100;
    if (percent > 50) {
      JqueryService.addClass(QC.PPC.PPC, QC.PPC.GT50);
    }
    JqueryService.getById(QC.PPC.PPC_PF).css(CC.CSS.TRANSFORM,
      CC.CSS.ROTATE_DEG_S + deg + CC.CSS.ROTATE_DEG_E);
    if (questionCount !== 0) { // 質問済み
      JqueryService.getById(QC.PPC.PPC_PS).text(this.replyCount
        + CC.SLASH + questionCount + CC.LINE_BREAK + QC.PPC.TEXT);
      JqueryService.removeClass(QC.PPC.PPC, QC.PPC.NO_Q);
    }
    else { // 未質問
      JqueryService.addClass(QC.PPC.PPC, QC.PPC.NO_Q);
    }
  }

  addScreenShots(event) {
    let addError = false;
    const addErrorFiles: string[] = [];
    LoadingService.on();
    [].forEach.call(event.target.files, (file: File) => {
      if (QC.REG_EXP_IMG.test(file.name)) {
        this.screenShots = this.screenShots.filter(screenShot =>
          !(screenShot.name === file.name &&
            screenShot.lastModified === file.lastModified &&
            screenShot.size === file.size)); // 重複削除
        this.screenShots.push(file); // 最後に追加
      } else {
        addError = true;
        addErrorFiles.push(file.name);
      }
    });
    this.screenShots.forEach((screenShot, index) => {
      const reader: FileReader = new FileReader();
      reader.onload = (() => {
        const src: string = reader.result.toString();
        this.screenShotUrls[index] = src;
        this.lightboxAlbum[index] = {
          src, thumb: reader.result.toString()
        }
      });
      reader.readAsDataURL(screenShot);
    });
    if (addError) {
      let message = QC.MESSAGE.ERROR.ADD_SCREEN_SHOT_FAILED;
      addErrorFiles.forEach((addErrorFile) => {
        message += CC.LINE_BREAK + addErrorFile;
      });
      alert(message);
    }
    JqueryService.clearValue('imageFile');
    LoadingService.off();
  }

  removeScreenShot(index) {
    this.screenShots.splice(index, 1);
    this.screenShotUrls.splice(index, 1);
  }

  openScreenShot(index) {
    this.zoomCount = 0;
    this.lightbox.open(this.lightboxAlbum, index, { showZoom: true });
    setTimeout(() => {
      this.zoomButtonCheck();
    }, 1000);
  }

  openPostedScreenShot(screenShots, screenShotIndex) {
    this.zoomCount = 0;
    this.postedLightboxAlbum = [];
    screenShots.forEach((screenShot, index) => {
      this.postedLightboxAlbum[index] = {
        src: screenShot, thumb: screenShot
      }
    });
    this.lightbox.open(this.postedLightboxAlbum, screenShotIndex, { showZoom: true });
    setTimeout(() => {
      this.zoomButtonCheck();
    }, 1000);
  }

  postQuestion() {
    if (this.validation()) {
      this.questionService.postQuestion(this.user.uid,
        this.title, this.content, this.screenShots)
        .then(() => {
          this.authService.regetAdditionalUserInfo().then(() => {
            this.init();
          });
        });
    }
  }

  validation(): boolean {
    return JqueryService.formValidation();
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    if (!document.hidden) {
      this.pingAndSetClock();
    }
  }

  private pingAndSetClock = async () => {
    const authTime: Date = await this.authService.ping();
    const expiration: Date = this.user.expiration;
    this.isExpired = !MomentService.isSameOrBeforeWithDateObject(authTime, expiration);
    this.clock = MomentService.getDateFromDateObject(authTime, MC.DATE.FORMAT.Y_s_M_s_D_r_d_r_b_H_c_m_c_s);
    this.clockSubscription.unsubscribe();
    MomentService.setClock(authTime);
    this.clockSubscription = MomentService.clock
      .subscribe(current => {
        this.isExpired = !MomentService.isSameOrBeforeWithDateObject(current, expiration);
        this.clock = MomentService.getDateFromDateObject(current, MC.DATE.FORMAT.Y_s_M_s_D_r_d_r_b_H_c_m_c_s);
        this.refreshCountDown--;
        if (this.refreshCountDown <= 0 || ((current.getSeconds() === 0) &&
          (current.getMinutes() === 0 || current.getMinutes() === BC.TIME.UNIT))) {
          this.refreshCountDown = this.refreshInterval;
          this.pingAndSetClock();
        }
      });
  }

  private zoomButtonCheck = () => {
    JqueryService.setFunction(CC.EVENT.CLICK, this.zoomOut, () => {
      if (this.zoomCount === this.upperLimit) {
        JqueryService.setStyleBySelector(this.zoomIn, 'pointer-events', 'auto');
      }
      this.zoomCount = (this.zoomCount === this.lowerLimit) ? this.zoomCount : --this.zoomCount;
      if (this.zoomCount <= this.lowerLimit) {
        JqueryService.setStyleBySelector(this.zoomOut, 'pointer-events', 'none');
      }
    });
    JqueryService.setFunction(CC.EVENT.CLICK, this.zoomIn, () => {
      if (this.zoomCount === this.lowerLimit) {
        JqueryService.setStyleBySelector(this.zoomOut, 'pointer-events', 'auto');
      }
      this.zoomCount = (this.zoomCount === this.upperLimit) ? this.zoomCount : ++this.zoomCount;
      if (this.zoomCount >= this.upperLimit) {
        JqueryService.setStyleBySelector(this.zoomIn, 'pointer-events', 'none');
      }
    });

  }
}
