<div class="booking container contents_wrap">
  <div class="row justify-content-center mb-4">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="card">
        <div class="card-header d-flex justify-content-between">
          <span class="card-toggler" data-toggle="collapse" data-target="#mainCard" aria-controls="mainCard"
            aria-expanded="false"><i class="fas fa-caret-up nav white rt180 mr-1"></i>授業予約一覧</span>
          <a class="no-decoration" [routerLink]="urlDashboard"><i class="fas fa-share nav white mr-1"></i>ダッシュボードへ戻る</a>
        </div>
        <div class="card-body-wrap collapse" id="mainCard">
          <div class="card-body pb-md-1">

            <!-- <div class="row">
              <div class="col-12">
                <span class="small text-monospace text-muted" title="PCの状態やログインからの時間経過により正確でない場合があります。">{{clock}}</span>
              </div>
            </div> -->

            <div class="row">
              <div class="col-12">
                <div class="small alert-danger rounded p-1 pl-2 mb-3" *ngIf="isPlanNotMatch">
                  <span class="text-danger">現在のプランではオンライン授業は利用できません。</span>
                  <a [routerLink]="urlPlan">プラン購入／管理画面</a>
                  から授業可能なプランをご購入ください。
                </div>
                <div class="small alert-danger rounded p-1 pl-2 mb-3"
                  *ngIf="!isPlanNotMatch && !isOnlineCountNotRemain && isPlanCancelAtPeriodEnd">
                  <span class="text-danger">{{planName}} は {{planExpiration}} で終了します。</span>
                  それ以降に授業を実施したい場合には
                  <a [routerLink]="urlPlan">プラン購入／管理画面</a> から自動更新を再開してください。
                </div>
                <div class="small alert-danger rounded p-1 pl-2 mb-3" *ngIf="isOnlineCountNotRemain && !isFreePlan">
                  <span class="text-danger">オンライン授業予約回数が残っていません。</span>
                  <a [routerLink]="urlPlan">プラン購入／管理画面</a>
                  から追加の授業予約をご購入ください。
                </div>
                <div class="small alert-danger rounded p-1 pl-2 mb-3"
                  *ngIf="!isOnlineCountNotRemain && (isFreePlan && !isExpired)">
                  <span class="text-danger">{{planName}} は {{planExpiration}} で終了します。</span>
                  それ以降に授業を実施したい場合には
                  <a [routerLink]="urlPlan">プラン購入／管理画面</a> から授業可能なプランをご購入ください。
                </div>
                <div class="small alert-danger rounded p-1 pl-2 mb-3"
                  *ngIf="!isOnlineCountNotRemain && (isFreePlan && isExpired)">
                  <span class="text-danger">{{planName}} は {{planExpiration}} で終了しました。</span>
                  授業を実施したい場合には
                  <a [routerLink]="urlPlan">プラン購入／管理画面</a> から授業可能なプランをご購入ください。
                </div>
                <div class="small alert-danger rounded p-1 pl-2 mb-3" *ngIf="isOnlineCountNotRemain && isFreePlan">
                  <span class="text-danger">オンライン授業予約回数が残っていません。</span>
                  <a [routerLink]="urlPlan">プラン購入／管理画面</a>
                  から授業可能なプランをご購入ください。
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 mb-3">
                <div class="input-group">
                  <input name="displayDate" class="form-control" type="date" [(ngModel)]="displayDate" min="{{dateMin}}"
                    max="{{dateMax}}" (change)="displayDateChanged(mode.date)">
                  <div class="input-group-append">
                    <span class="input-group-text">を含む週を表示</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 mb-md-3 d-flex justify-content-between">
                <button class="btn btn-outline-success" (click)="displayDateChanged(mode.prev)"><i
                    class="fas fa-chevron-left arrow mr-1"></i>前週</button>
                <button class="btn btn-outline-success" (click)="displayDateChanged(mode.next)">次週<i
                    class="fas fa-chevron-right arrow ml-1"></i></button>
                <button class="btn btn-outline-success" (click)="displayDateChanged(mode.today)">今週</button>
                <button class="btn btn-outline-success" (click)="displayDateChanged(mode.reload)">再読込</button>
              </div>
            </div>

            <div id="bookingTable" class="d-flex flex-column flex-md-row">
              <div *ngFor="let cols of data" class="flex-fill mb-3 d-flex flex-row">
                <div *ngFor="let col of cols" class="{{col.class}}">
                  <div *ngFor="let cell of col.cells" title="{{cell.title}}"
                    class="{{cell.class}} bt_cell d-flex flex-wrap justify-content-center align-content-center"
                    (click)="cellClick(cell)">
                    <span>{{cell.text}}</span></div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 mb-3">
                <div class="input-group">
                  <input name="displayDate" class="form-control" type="date" [(ngModel)]="displayDate" min="{{dateMin}}"
                    max="{{dateMax}}" (change)="displayDateChanged(mode.date)">
                  <div class="input-group-append">
                    <span class="input-group-text">を含む週を表示</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 mb-md-3 d-flex justify-content-between">
                <button class="btn btn-outline-success" (click)="displayDateChanged(mode.prev)"><i
                    class="fas fa-chevron-left arrow mr-1"></i>前週</button>
                <button class="btn btn-outline-success" (click)="displayDateChanged(mode.next)">次週<i
                    class="fas fa-chevron-right arrow ml-1"></i></button>
                <button class="btn btn-outline-success" (click)="displayDateChanged(mode.today)">今週</button>
                <button class="btn btn-outline-success" (click)="displayDateChanged(mode.reload)">再読込</button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-booking-modal></app-booking-modal>
