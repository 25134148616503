import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonConstants as CC } from '../../constants/common.constants';
import { ErrorConstants as EC } from '../../constants/error.constants';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor(
    private toastrService: ToastrService
  ) { }

  /**
   * お知らせメッセージ表示
   * 
   * - お知らせメッセージをtoastで表示します。
   * @param  {string} message お知らせメッセージの本文
   * @param  {string} title お知らせメッセージのタイトル
   */
  public infoToast = (message: string, title: string) => {
    this.toastrService.info(message, title);
  }

  /**
   * 成功メッセージ表示
   * 
   * - 成功メッセージをtoastで表示します。
   * @param  {string} message 成功メッセージの本文
   * @param  {string} title 成功メッセージのタイトル
   */
  public successToast = (message: string, title: string) => {
    this.toastrService.success(message, title);
  }

  /**
   * 警告メッセージ表示
   * 
   * - 警告メッセージをtoastで表示します。
   * @param  {string} message 警告メッセージの本文
   * @param  {string} title 警告メッセージのタイトル
   * @param  {boolean} disableTimeOut 自動的に消さない場合はtrue、省略可
   */
  public warningToast = (message: string, title: string,
    disableTimeOut: boolean = false, closeButton: boolean = false) => {
    this.toastrService.warning(message, title, { disableTimeOut, closeButton });
  }

  /**
   * エラーメッセージ表示
   * 
   * - エラーメッセージをtoastで表示します。
   * @param  {string} message エラーメッセージの本文
   * @param  {string} title エラーメッセージのタイトル
   */
  public errorToast = (message: string, title: string) => {
    this.toastrService.error(message, title);
  }

  /**
   * エラー処理
   * 
   * - エラーメッセージをtoastで表示します。
   * - consoleに詳細なエラーログを出力します。（@see errorLog）
   * @param  {string} message エラーメッセージの本文
   * @param  {string} title エラーメッセージのタイトル
   * @param  {string} key 発生源を特定するキー情報
   * @param  {any} error エラーオブジェクト
   */
  public errorReport = (
    message: string, title: string, key: string, error: any) => {
    message = EC.ERRORS[error.code] || message;
    this.errorToast(message, title);
    this.errorLog(key, error.code, error.message);
  }

  /**
   * エラーログ出力
   * 
   * - consoleに詳細なエラーログを出力します。
   * - 基本的にはtoastには簡易メッセージのみ出力とします。
   * @param  {string} key 発生源を特定するキー情報
   * @param  {string} code エラーコードなど
   * @param  {string} message エラーメッセージなど
   */
  public errorLog = (key: string, code: string, message: string) => {
    if (!environment.production) console.log(
      CC.BRACKET_L + key + CC.BRACKET_R + code + CC.COLLON + message);
  }

    /**
   * 目次用お知らせメッセージ表示
   * 
   * - お知らせメッセージをtoastで表示します。
   * @param  {string} message お知らせメッセージの本文
   * @param  {string} title お知らせメッセージのタイトル
   */
     public indexInfoToast = (message: string, title: string) => {
      this.toastrService.warning(message, title, {closeButton: true, progressBar: false, timeOut: 0, extendedTimeOut: 0});
    }
}
