<div class="trial contents_wrap">
  <div class="kv-wrapper">
    <div class="kv">
      <div class="kv-inner content-wrapper">
        <div class="kv-text-block">
          <div>
            <h1 class="pc">オンライン<span class="inlineBlock">プログラミング学習サービス</span></h1>
          </div>
          <div class="pc">
            <div class="titleFont">自分のペース<span>で</span></div><br>
            <div class="titleFont">プログラミング<span>を</span>学習<span>しよう</span></div>
          </div>
          <div class="titleBox sp">
            <div class="titleFont">自分のペース<span>で</span></div><br>
            <div class="titleFont">プログラミング<span>を</span>学習<span>しよう</span></div>
          </div>
          <h1 class="sp">オンライン<span class="inlineBlock">プログラミング学習サービス</span></h1>
          <a *ngIf="planCode === pc.none; else elseLink" class="kv-text-link btn btn-sm btn-outline-primary" href="">登録済みの方はこちら</a>
          <ng-template #elseLink>
            <a class="kv-text-link btn btn-sm btn-outline-primary" (click)="navigate(page.plan)">登録済みの方はこちら</a>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="main-wrapper">
    <div class="row justify-content-center mb-4 content-wrapper">
      <div class="col-12 col-sm-12 col-omd-12 col-md-8 col-lg-8 col-xl-9">
        <div class="row justify-content-center mb-4">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="row">

              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 course mb-4 fadeIn introduction-wrapper">
                <div class="card clickable introduction-card" (click)="dummyLoading()">
                  <a href="/course/introduction/" class="course-link g-0 d-flex">
                    <div class="card-img-top">
                      <img [src]="'/assets/img/course_' + 'introduction' + '.png'">
                      <div class="overlay"></div>

                    </div>
                    <div class="card-body">
                      <h5 class="card-title">はじめに／FAQ</h5>
                      <div class="introduction-flex">
                        <p class="card-text">はじめての方はまずこちらを見てみましょう！</p>
                        <span class="btn btn-sm btn-success">開く</span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4 row course-list">

                <div class="course-list-wrapper col-12 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <input type="radio" class="btn-check mr-1" name="options" id="allCourseList" autocomplete="off" (click)="sort(allCourseList)" [checked]="cookieCourseList === allCourseList">
                   <label class="card clickable center-block" for="allCourseList">
                    <div class="course-list-img">
                      <img src="../../../assets/img/course_list_all.png" alt="">
                      <div class="overlay"><div>全て<span>のコースを表示する</span></div></div>
                    </div>
                  </label>
                </div>
        
                <div class="course-list-wrapper col-12 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <input type="radio" class="btn-check mr-1" name="options" id="phpCourseList" autocomplete="off" (click)="sort(phpCourseList)" [checked]="cookieCourseList === phpCourseList">
                  <label class="card clickable center-block" for="phpCourseList">
                    <div class="course-list-img">
                      <img src="../../../assets/img/course_list_php.png" alt="">
                      <div class="overlay"><div>PHP<span>のコースを表示する</span></div></div>
                    </div>
                  </label>
                </div>
                
                <div class="course-list-wrapper col-12 col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <input type="radio" class="btn-check mr-1" name="options" id="javaCourseList" autocomplete="off" (click)="sort(javaCourseList)" [checked]="cookieCourseList === javaCourseList">
                  <label class="card clickable center-block" for="javaCourseList">
                    <div class="course-list-img">
                      <img src="../../../assets/img/course_list_java.png" alt="">
                      <div class="overlay"><div>Java<span>のコースを表示する</span></div></div>
                    </div>
                  </label>
                </div>

              </div>
              <ng-container *ngFor="let item of courseListData; let itemCount = count;index as index">
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 course mb-4" [id]="item.key" [class.fadeIn]="initSort(item.key)" [attr.data-number-show]="initNumberIsShow(item.key)">
                  <div class="card clickable course-card center-block" (click)="dummyLoading()">
                    <a [href]="item.contents" class="course-link" [attr.data-number]="initNumber(item.key)">
                      <div class="card-img-top">
                        <img [src]="'/assets/img/course_' + item.key + '.png'" [alt]="item.text">
                        <div class="overlay"></div>

                      </div>
                      <div class="card-body">
                        <h5 class="card-title">{{item.text}}</h5>
                        <p class="card-text">{{item.description}}</p>
                        <!-- <span class="btn btn-sm btn-success"
                        (click)="showCourseInfoPanel(item.text, item.key, $event);false" title="このコースの目次を参照できます">学習内容を見る</span> -->
                        <span class="btn btn-sm btn-success">学習を開始する</span>
                      </div>
                    </a>
                  </div>
                </div>
              </ng-container>
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4 row setup-wrapper">
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 course mb-4 fadeIn">
                  <div class="card clickable" (click)="dummyLoading()">
                    <a href="/course/setup/" class="course-link">
                      <div class="card-img-top">
                        <img [src]="'/assets/img/course_' + 'setup' + '.png'">
                        <div class="overlay"></div>

                      </div>
                      <div class="card-body">
                        <h5 class="card-title">環境設定</h5>
                        <p class="card-text">それぞれのコースで学習に必要な環境構築の手順がまとめられています。</p>
                        <span class="btn btn-sm btn-success">学習を開始する</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-omd-12 col-md-4 col-lg-4 col-xl-3">
        <div class="row justify-content-center align-items-flex-start">
          <div class="banner-lp banner col-12 col-sm-6 col-omd-6 col-md-12 col-lg-12 col-xl-12 mb-4">
            <div class="card">
              <div class="card-img-top">
                <img src="../../../assets/img/trial_banner_lp.png" alt="">
              </div>
              <div class="card-body" (click)="navigate(page.lp)">
                <p>詳しくはこちら</p>
                <img src="../../../assets/img/arrow_icon.png" alt="">
              </div>
            </div>
          </div>
          <div class="banner-upgrade banner col-12 col-sm-6 col-omd-6 col-md-12 col-lg-12 col-xl-12 mb-4">
            <div class="card">
              <div class="card-img-top">
                <img src="../../../assets/img/trial_banner.png" alt="">
              </div>
              <div class="card-body">
                <button *ngIf="planCode === pc.none; else elseButton" type="button" class="btn bannerButton"
                  (click)="showBillingGuidePanel()">有料プランにアップグレード</button>
                <ng-template #elseButton>
                  <button type="button" class="btn bannerButton"
                    (click)="navigate(page.plan)">有料プランにアップグレード</button>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer>
    <section class="footer">
        <div class="wrapper">
            <div class="container">
                <a href="https://www.vv-e-nn.com/company.php" target="_blank" rel="noopener">運営会社</a>
                <a href="https://www.vv-e-nn.com/service_contact.php?id=1&t=1" target="_blank" rel="noopener">お問い合わせ</a>
                &nbsp;&nbsp;&nbsp;&nbsp;<a href="https://www.vv-e-nn.com/policy.php" target="_blank" rel="noopener">個人情報保護方針</a>
                <p>&copy;{{year}}
                    <a href="https://www.vv-e-nn.com/" target="_blank" rel="noopener">WEM, Inc.</a>
                </p>
            </div>
        </div>
    </section>
  </footer>
</div>

<div id="courseInfoPanel" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">「{{courseInfoTitle}}」学習内容</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngFor="let item of courseInfoIndex">
          <div class="list-group list-group-horizontal mb-1">
            <div class="list-group-item depth_space depth_1" *ngIf="item.depth > 1"></div>
            <div class="list-group-item depth_space depth_2" *ngIf="item.depth > 2"></div>
            <div class="list-group-item depth_space depth_3" *ngIf="item.depth > 3"></div>
            <div class="list-group-item depth_space depth_4" *ngIf="item.depth > 4"></div>
            <div [attr.class]="'d-flex justify-content-between list-group-item courseInfoIndex depth_' + item.depth">
              {{item.title}}<i class="fas fa-lock darkgray" *ngIf="item.url !== '' && !item.isFree"></i></div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div id="billingGuidePanel" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-inner">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-title-block">
          <h1><span>有料プラン</span>のご紹介</h1>
        </div>
        <div class="modal-list-block">
          <div>
            <img src="../../../assets/img/banner_all_curriculum.png" alt="">
            <p>有料プランは税込 1,100円 / 30日 のサブスクリプションです。<br>
              有効期間中は<span>全コース全ページの学習が可能</span>です。<br>
              初回ご購入時に下記の質問フォームとオンライン授業の<br class="over420">
              <span>お試し利用券が１回ずつもらえます</span>！</p>
          </div>
          <div>
            <img src="../../../assets/img/banner_question.png" alt="">
            <p>有料プランでは税込 330円 / 1回 で質問フォームから<br
              class="over420">質問を送ることができ、最短で1時間以内に<br
              class="over420">現役エンジニアから回答をもらえます！<br>
              <span class="annotation">※ 平日11~19時以外は翌営業日回答となります</span></p>
          </div>
          <div>
            <img src="../../../assets/img/banner_online_lesson.png" alt="">
            <p>有料プランでは税込 3,300円 / 1回で<span><br class="over420">
              現役エンジニアにオンラインでたっぷり1時間、<br
              class="over420">相談や質問</span>をすることができます！<br>
              <span class="annotation">※ 平日11~19時の間で予約が必要です（直前でもOK！）</span></p>
          </div>
        </div>
        <div class="modal-text-block">
          <p><span>次の画面でメールアドレスをご登録いただき、</span><span>メンバーサイトへログインしてから、</span><br class="over420"><span>セキュリティが確保されたご購入画面にて</span>カード情報をご入力いただきます。</p>
        </div>
        <div class="modal-button-block">
          <button data-dismiss="modal" (click)="planCode === pc.none ? showSignupPanel() : navigate(page.plan)"
            class="btn upgrade-button">有料プランにアップグレード</button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-trial-signup></app-trial-signup>
<div id="script"></div>
