import moment from 'moment';

export class MomentConstants {
  static readonly DATE = {
    LOCALE: 'ja',
    WEEK_DAY: {
      MON: 1,
      TUE: 2,
      WED: 3,
      THU: 4,
      FRI: 5,
      SAT: 6,
      SUN: 7,
    },
    FORMAT: {
      Y: 'YYYY',
      M: 'MM',
      YM: 'YYYYMM',
      YMD: 'YYYYMMDD',
      Y_h_M_h_D: 'YYYY-MM-DD',
      Y_h_M_h_D_b_H_c_m: 'YYYY-MM-DD HH:mm',
      Y_h_M_h_D_b_H_c_m_c_s_p_S: 'YYYY-MM-DD HH:mm:ss.SSS',
      Y_h_M_h_D_b_H_c_m_c_s: 'YYYY-MM-DD HH:mm:ss',
      YMDHmsS: 'YYYYMMDDHHmmssSSS',
      Y_s_M_s_D_b_H_c_m: 'YYYY/MM/DD HH:mm',
      Y_s_M_s_D_r_d_r_b_H_c_m: 'YYYY/MM/DD(dd) HH:mm',
      Y_s_M_s_D_b_H_c_m_c_s: 'YYYY/MM/DD HH:mm:ss',
      Y_s_M_s_D_r_d_r_b_H_c_m_c_s: 'YYYY/MM/DD(dd) HH:mm:ss',
      M_s_D_r_d_r: 'MM/DD(dd)',
      Y_s_M_s_D_r_d_r: 'YYYY/MM/DD(dd)',
      d: 'dd',
      d4: 'dddd',
      YEARS: 'y' as moment.unitOfTime.DurationConstructor,
      MONTHS: 'M' as moment.unitOfTime.DurationConstructor,
      DAYS: 'd' as moment.unitOfTime.DurationConstructor,
    },
  };

  static readonly TIME = {
    DAY_SECONDS: 86400,
    MID_NIGHT: '00:00',
    MID_NIGHT_SYNONYM: '24:00',
    FORMAT: {
      H_c_m: 'HH:mm',
      H_c_m_c_s: 'HH:mm:ss',
      MINUTES: 'm' as moment.unitOfTime.DurationConstructor,
      UNIX_TIME_STAMP: 'X' as moment.unitOfTime.DurationConstructor,
      SECONDS: 's' as moment.unitOfTime.DurationConstructor,
    },
  };
}
