import { environment } from 'src/environments/environment';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

// https://levelup.gitconnected.com/making-hammerjs-work-with-angular-9-81d289159320
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { LightboxModule } from 'ngx-lightbox';

import { ReportingService } from 'src/app/utilities/injectable/reporting.service';
import { AuthService } from 'src/app/services/auth.service';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { UserGuard } from 'src/app/guards/user.guard';
import { BookingService } from 'src/app/services/booking.service';
import { QuestionService } from 'src/app/services/question.service';
import { DetailService } from 'src/app/services/detail.service';
import { SafePipe } from 'src/app/pipes/safe.pipe';

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAnalytics, provideAnalytics, ScreenTrackingService } from '@angular/fire/analytics';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getStorage, provideStorage } from '@angular/fire/storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BookingComponent } from './booking/booking.component';
import { ModalComponent } from './booking/modal/modal.component';
import { QuestionComponent } from './question/question.component';
import { TrialComponent } from './trial/trial.component';
import { SignupModule } from './trial/signup/signup.module';
import { CourseComponent } from './course/course.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    BookingComponent,
    ModalComponent,
    QuestionComponent,
    TrialComponent,
    CourseComponent,
    SafePipe
  ],
  imports: [
    BrowserModule,
    HammerModule,
    SignupModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      closeButton: false,
      timeOut: 3000,
      extendedTimeOut: 3000,
      progressBar: true,
      enableHtml: true,
      positionClass: 'toast-bottom-full-width'
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideStorage(() => getStorage()),
    provideAnalytics(() => getAnalytics()),
    LightboxModule
  ],
  providers: [
    CookieService,
    ReportingService,
    AuthService,
    AuthGuard,
    UserGuard,
    BookingService,
    QuestionService,
    DetailService,
    ScreenTrackingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
