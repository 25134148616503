import { CommonConstants as CC } from '../../constants/common.constants';
export class QuestionSearchCriteria {
    postedUid?: string = CC.BLANK;
    replyUid?: string = CC.BLANK;
    postedAtMin?: string = CC.BLANK;
    postedAtMax?: string = CC.BLANK;
    replyAtMin?: string = CC.BLANK;
    replyAtMax?: string = CC.BLANK;
    onlyNoReply?: boolean = false;
    limit?: number = undefined;

    constructor(partial?: Partial<QuestionSearchCriteria>) {
        Object.assign(this, partial);
    }
}
