export class StripeConstants {
  static readonly PURCHASE = {
    RESULT: {
      PURCHASED: 'purchased',
      CANCELED: 'canceled',
    },
  };

  static readonly FUNCTIONS = {
    CHECK_OUT_SESSION: {
      NAME: 'stripeCheckOutSession',
      RESULT: 'sessionId',
    },
    BILLING_PORTAL_SESSION: {
      NAME: 'stripeBillingPortalSession',
      RESULT: 'url',
    },
    CUSTOMER_EMAIL_UPDATE: {
      NAME: 'stripeUpdateCustomerEmail',
      RESULT: 'result',
    },
  };
}
