import { Injectable } from '@angular/core';

import { FirestoreService } from '../utilities/injectable/firestore.service';
import { ReportingService } from '../utilities/injectable/reporting.service';
import { LoadingService } from '../utilities/static/loading.service';
import { MomentService } from '../utilities/static/moment.service';
import { QuestionService } from './question.service';

import { CommonConstants as CC } from '../constants/common.constants';
import * as PC from '../constants/plan.constants';
import { User } from '../models/class/User';
import { Info } from '../models/interface/Info';

@Injectable({
  providedIn: 'root'
})
export class InfoService {

  constructor(
    private firestoreService: FirestoreService,
    private reportingService: ReportingService,
    private questionService: QuestionService
  ) { }

  private static setReadMore = (info: Info): Info => {
    const text: string[] = info.text.split(CC.READ_MORE.MARK);
    if (text.length === 2) {
      info.text = text[0] + CC.READ_MORE.START + text[1] + CC.READ_MORE.END;
    }
    return info;
  }

  /**
   * お知らせ情報を取得する
   *
   * - isTestで管理者のみ表示を判定
   * - uidで特定ユーザーのみ表示を判定
   * - start/endで表示期間を判定
   * @param user 表示対象判定用のユーザー情報
   * @returns お知らせ情報の配列
   */
  public getInfoListData = async (user: User):
    Promise<Info[]> => {
    LoadingService.on();
    const list: Info[] = [];
    await Promise.all([
      this.firestoreService.getCollection([CC.COLLECTION.KEY.INFORMATION]),
      this.questionService.isExistsReplyFlag(user.uid)
    ])
      .then(([snapshot, isExistsReplyFlag]) => {
        snapshot.docs.forEach(doc => {
          const info: Info = doc.data() as Info;
          if (info &&
            (
              (!info.isTest && CC.IS_BLANK(info.uid))
              || (info.isTest && user.isAdmin)
              || (info.uid === user.uid)
            ) && (
              CC.IS_BLANK(info.start)
              || (!CC.IS_BLANK(info.start)
                && MomentService.isSameOrBefore(MomentService.getMomentFromDateTime
                  .apply(null, info.start.split(CC.SPACE))))
            ) && (
              CC.IS_BLANK(info.end)
              || (!CC.IS_BLANK(info.end)
                && MomentService.isAfter(MomentService.getMomentFromDateTime
                  .apply(null, info.end.split(CC.SPACE))))
            )
          ) {
            list.push(InfoService.setReadMore(info));
          }
        });
        if (isExistsReplyFlag) {
          list.unshift(this.setExistsReplyFlag());
        }
      })
      .catch(error => this.reportingService.errorReport(
        CC.MESSAGE.ERROR.PLEASE_RETRY,
        CC.TITLE.FAIL.GETDATA,
        'getInfoListData', error));
    if (!user.birthdate) {
      list.unshift(this.setPleaseRegisterBirthdate());
    }
    if (user.isTemporaryPassword) {
      list.unshift(this.setPleaseChangePassword());
    }
    // if (!user.isEmailVerified) {
    //   list.unshift(this.setPleaseVerifyEmail());
    // }
    if (user.stripeSubscription
      && user.stripeSubscription.status === PC.STATUS_EXT.pending) {
      list.unshift(this.setPleaseChangeCard());
    }
    if (!list.length) {
      list.unshift(this.setNoInformation());
    }
    LoadingService.off();
    return list;
  }

  private setPleaseRegisterBirthdate = (): Info => ({
    isTest: false, uid: CC.BLANK, start: CC.BLANK, end: CC.BLANK,
    class: CC.CLASS.DASHBOARD.INFO.DANGER,
    text: '<strong>生年月日登録のお願い</strong><br>'
      + 'パスワードを失念され再発行を行う場合、メールアドレスと生年月日によるご本人確認が必要となります。<br>'
      + '右上のお名前をクリックして「登録情報変更」画面から生年月日のご登録をお願いいたします。',
  });

  private setPleaseChangePassword = (): Info => ({
    isTest: false, uid: CC.BLANK, start: CC.BLANK, end: CC.BLANK,
    class: CC.CLASS.DASHBOARD.INFO.DANGER,
    text: '<strong>パスワード変更のお願い</strong><br>'
      + 'パスワードが仮パスワードから変更されていません。<br>'
      + '右上のお名前をクリックして「登録情報変更」画面からパスワードのご変更をお願いいたします。',
  });

  private setPleaseVerifyEmail = (): Info => ({
    isTest: false, uid: CC.BLANK, start: CC.BLANK, end: CC.BLANK,
    class: CC.CLASS.DASHBOARD.INFO.DANGER,
    text: '<strong>メールアドレス認証のお願い</strong><br>'
      + '「no-reply@member.digskill.net」からのメール「【Dig Skill】ご登録メールアドレスの確認」に<br>'
      + '記載されているリンクをクリックしてメールアドレスの認証を行ってください。',
  });

  private setPleaseChangeCard = (): Info => ({
    isTest: false, uid: CC.BLANK, start: CC.BLANK, end: CC.BLANK,
    class: CC.CLASS.DASHBOARD.INFO.DANGER,
    text: '<strong>カード変更のお願い</strong><br>'
      + '自動更新の決済に失敗しました。プラン購入／管理画面から、有効なカードに変更してください。<br>'
      + 'プラン期間終了時点から約3日以内に有効なカードに変更しない場合、プランは失効します。',
  });

  private setExistsReplyFlag = (): Info => ({
    isTest: false, uid: CC.BLANK, start: CC.BLANK, end: CC.BLANK,
    class: CC.CLASS.DASHBOARD.INFO.DANGER,
    text: '<strong>お送りいただいたご質問に回答いたしました！</strong>',
  });

  private setNoInformation = (): Info => ({
    isTest: false, uid: CC.BLANK, start: CC.BLANK, end: CC.BLANK,
    class: CC.CLASS.DASHBOARD.INFO.SUCCESS,
    text: '現在お知らせはありません。',
  });
}