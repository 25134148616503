// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appname: 'Dig Skill (staging) - オンラインプログラミング学習サービス',
  now: null,
  offsetFrom: null,
  offsetTo: null,
  // now: '2021-08-11 13:59:50.000',
  // offsetFrom: '2021-08-16 15:48:00.000',
  // offsetTo: '2021-08-20 14:00:00.000',
  open: '11:00',
  close: '19:00',
  admin_open: '11:00',
  admin_close: '19:00',
  minAge: 12,
  maxAge: 120,
  production: false,
  firebase: {
    apiKey: 'AIzaSyCdDKqWmDYcXC8QX576orfS1jqZW-sA7gc',
    authDomain: 'digskill-member-staging.firebaseapp.com',
    databaseURL: 'https://digskill-member-staging.firebaseio.com',
    projectId: 'digskill-member-staging',
    storageBucket: 'digskill-member-staging.appspot.com',
    messagingSenderId: '520810106023',
    appId: '1:520810106023:web:ac8d532f21acf32cd81f82',
    measurementId: 'G-WRNB3JL712'
  },
  stripePK: 'pk_test_51JDn80B2XQgDTMjbqqEMnAByt7ZSG9fLyV471rx5RvEW2ld6by8uwwzKnaMiGcpsqv01hnOQb931bkHtBfVocNKw00V12P8vbZ',
  trial: {
    courseListType: 'dev_eng',
    courseListVersion: '20230810102709169'
  },
  shareUrl: {
    domainUri: 'https://staging.share.member.digskill.net',
    link: 'https://staging.member.digskill.net/',
    apiUrl: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCdDKqWmDYcXC8QX576orfS1jqZW-sA7gc',
    description: 'Dig Skillは充実したカリキュラムでプログラミングを学べる、完全オンラインのプログラミング学習サービスです。',
    imageLink: {
      git: 'https://firebasestorage.googleapis.com/v0/b/digskill-member-staging.appspot.com/o/image%2Fcourse%2Fcourse_git.png?alt=media&token=1bf0cdf0-793b-4455-a958-e9e562ab51a7',
      html_css: 'https://firebasestorage.googleapis.com/v0/b/digskill-member-staging.appspot.com/o/image%2Fcourse%2Fcourse_html-css.png?alt=media&token=068019cb-9b9d-4a4f-aa4c-6fe983c00bf4',
      introduction: 'https://firebasestorage.googleapis.com/v0/b/digskill-member-staging.appspot.com/o/image%2Fcourse%2Fcourse_introduction.png?alt=media&token=5efedc59-7908-4ef3-b0c9-97dcef9d2512',
      java_basic: 'https://firebasestorage.googleapis.com/v0/b/digskill-member-staging.appspot.com/o/image%2Fcourse%2Fcourse_java-basic.png?alt=media&token=ef8de85a-663e-4528-baa8-67afa6798307',
      java_jsp: 'https://firebasestorage.googleapis.com/v0/b/digskill-member-staging.appspot.com/o/image%2Fcourse%2Fcourse_java-jsp.png?alt=media&token=913e27e7-9071-47e9-bd8e-58c334ffa4f7',
      java_servlet: 'https://firebasestorage.googleapis.com/v0/b/digskill-member-staging.appspot.com/o/image%2Fcourse%2Fcourse_java-servlet.png?alt=media&token=c37fad26-bea3-4978-b601-885b51bf5d68',
      java_springboot: 'https://firebasestorage.googleapis.com/v0/b/digskill-member-staging.appspot.com/o/image%2Fcourse%2Fcourse_java-springboot.png?alt=media&token=971e0666-b57b-4ff7-8dc5-4b0c69523d6b',
      php_basic: 'https://firebasestorage.googleapis.com/v0/b/digskill-member-staging.appspot.com/o/image%2Fcourse%2Fcourse_php-basic.png?alt=media&token=285798c9-c614-42a3-b9ad-3463d62bbed3',
      php_laravel: 'https://firebasestorage.googleapis.com/v0/b/digskill-member-staging.appspot.com/o/image%2Fcourse%2Fcourse_php-laravel.png?alt=media&token=75bb8b31-caf7-4682-a81f-88d54484c792',
      php_web_beginner: 'https://firebasestorage.googleapis.com/v0/b/digskill-member-staging.appspot.com/o/image%2Fcourse%2Fcourse_php-web-beginner.png?alt=media&token=a9fd8fd8-1f23-40a9-abdb-d81ffdc61e39',
      php_web_middle: 'https://firebasestorage.googleapis.com/v0/b/digskill-member-staging.appspot.com/o/image%2Fcourse%2Fcourse_php-web-middle.png?alt=media&token=ee6bc816-43e5-422b-bc1a-9368c4ef9098',
      python_ml_basic: 'https://firebasestorage.googleapis.com/v0/b/digskill-member-staging.appspot.com/o/image%2Fcourse%2Fcourse_python-machine-learning.png?alt=media&token=f90e0aab-d0d0-48ad-af56-1bdf2f606c29',
      python: 'https://firebasestorage.googleapis.com/v0/b/digskill-member-staging.appspot.com/o/image%2Fcourse%2Fcourse_python.png?alt=media&token=25890cb8-7a18-41ff-bba8-995504a30936',
      react: 'https://firebasestorage.googleapis.com/v0/b/digskill-member-staging.appspot.com/o/image%2Fcourse%2Fcourse_react.png?alt=media&token=b35813d5-abf9-447e-a0c4-d996908a95d3',
      setup: 'https://firebasestorage.googleapis.com/v0/b/digskill-member-staging.appspot.com/o/image%2Fcourse%2Fcourse_setup.png?alt=media&token=6f2dddba-c42e-4178-9788-9f7d3b500bae',
      sql: 'https://firebasestorage.googleapis.com/v0/b/digskill-member-staging.appspot.com/o/image%2Fcourse%2Fcourse_sql.png?alt=media&token=f29911c3-9706-4ade-b0b5-bde42db18597',
    },
    snsUrl: {
      twitter: 'https://twitter.com/share',
      facebook: 'https://www.facebook.com/share.php',
      line: 'https://timeline.line.me/social-plugin/share',
      hatena: 'https://b.hatena.ne.jp/entry/s',
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
