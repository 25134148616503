import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CommonConstants as CC } from '../constants/common.constants';
import { User } from '../models/class/User';

@Injectable({
  providedIn: 'root'
})
export class DetailService {

  private user: User = null;
  private referrer: string = CC.BLANK;

  constructor(
    private router: Router,
  ) { }

  /**
   * ユーザー一覧からの詳細情報ボタン押下時の処理
   *
   * - 詳細情報画面から戻るユーザー一覧のURLを保存
   * - 詳細情報画面へ遷移
   * @param user 詳細情報表示対象のユーザー情報
   */
  public showDetail = (user: User): void => {
    this.user = user;
    this.referrer = this.router.url;
    this.router.navigate([CC.URL.ADMIN.USER.DETAIL]);
  }

  public getReferrer = (): string => this.referrer;
  public getEditUser = (): User => this.user;
  public setEditUser = (user: User) => this.user = user;
}
