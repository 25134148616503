import { CommonConstants as CC } from '../../constants/common.constants';
import { JqueryService } from './jquery.service';

export class LoadingService {

  /**
   * ローディングマスクを表示します
   *
   * @param isLoading ローディング文字列の表示有無、省略時true
   */
  public static on = (isLoading: boolean = true): void => {
    JqueryService.setDisplay(CC.ID.MASK_ID, CC.STYLES.DISPLAY.FLEX);
    JqueryService.setDisplay(CC.ID.NOW_LOADING_ID,
      isLoading ? CC.STYLES.DISPLAY.BLOCK : CC.STYLES.DISPLAY.NONE);
    JqueryService.setFocus(CC.ID.DUMMY_BUTTON_ID);
  }

  /**
   * ローディングマスクを非表示にします
   */
  public static off = (): void => {
    JqueryService.setDisplay(CC.ID.MASK_ID, CC.STYLES.DISPLAY.NONE);
    JqueryService.setDisplay(CC.ID.NOW_LOADING_ID, CC.STYLES.DISPLAY.NONE);
  }

  /**
   * ローディングマスクをwait秒間表示します
   *
   * @param wait ローディング表示秒数、省略時1
   */
  public static dummy = (wait: number = 1): void => {
    LoadingService.on();
    setTimeout(() => LoadingService.off(), wait * 1000);
  }
}
