import { Injectable } from '@angular/core';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';
import { FirefuncService } from 'src/app/utilities/injectable/firefunc.service';
import { StripeConstants as SC } from 'src/app/constants/stripe.constants';

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  constructor(
    private firefuncService: FirefuncService
  ) { }

  public startCheckOutSession =
    async (uid: string, email: string, planCode: string, customer: string) =>
      await this.firefuncService.call(SC.FUNCTIONS.CHECK_OUT_SESSION.NAME,
        { uid, email, planCode, customer, referrer: location.href })
        .then(result => result.data[SC.FUNCTIONS.CHECK_OUT_SESSION.RESULT])
        .then(async sessionId => (await loadStripe(environment.stripePK))
          .redirectToCheckout({ sessionId }))
        .then(result => !result.error || alert(result.error.message))
        .catch(error => console.error('Error:', error))

  public startBillingPortalSession =
    async (customer: string) =>
      await this.firefuncService.call(SC.FUNCTIONS.BILLING_PORTAL_SESSION.NAME,
        { customer, referrer: location.href })
        .then(result => result.data[SC.FUNCTIONS.BILLING_PORTAL_SESSION.RESULT])
        .then(url => window.location.href = url)
        .catch(error => console.error('Error:', error))

  public updateCustomerEmail =
    async (customer: string, email: string) =>
      await this.firefuncService.call(SC.FUNCTIONS.CUSTOMER_EMAIL_UPDATE.NAME,
        { customer, email })
        .then(result => result.data[SC.FUNCTIONS.CUSTOMER_EMAIL_UPDATE.RESULT])
        .then(result => !!result)
        .catch(error => console.error('Error:', error))
}
