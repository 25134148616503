import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/components/app.module';
import { environment } from './environments/environment';

// https://medium.com/angular-in-depth/gestures-in-an-angular-application-dde71804c0d0
// https://medium.com/developer-rants/what-to-do-when-hammerjs-is-blocking-scrolling-on-mobile-9f79b460ac84
import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
