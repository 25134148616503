import { Location } from '@angular/common';
import {
  AfterViewInit, Component, HostListener,
  OnDestroy, OnInit, ViewChild
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DocumentReference } from 'firebase/firestore';
import { CookieService } from 'ngx-cookie-service';
import { SignupComponent } from 'src/app/components/trial/signup/signup.component';
import { AuthConstants as AC } from 'src/app/constants/auth.constants';
import { CommonConstants as CC } from 'src/app/constants/common.constants';
import * as PC from 'src/app/constants/plan.constants';
import { User } from 'src/app/models/class/User';
import { CourseIndex, CourseIndexNested } from 'src/app/models/interface/CourseIndex';
import { ChapterStatus, CourseStatus } from 'src/app/models/interface/CourseStatus';
import * as WM from 'src/app/models/interface/WebMessagingAPI';
import { AuthService } from 'src/app/services/auth.service';
import { CourseService } from 'src/app/services/course.service';
import { ReportingService } from 'src/app/utilities/injectable/reporting.service';
import { JqueryService } from 'src/app/utilities/static/jquery.service';
import { LoadingService } from 'src/app/utilities/static/loading.service';
import { MomentService } from 'src/app/utilities/static/moment.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.sass']
})
export class CourseComponent implements OnInit, OnDestroy, AfterViewInit {

  public ta: string = 'btn btn-sm btn-outline-primary';
  public ulroot: string = 'list-group list-group-flush ' + CC.CLASS.ROOT;
  public ul: string = 'list-group list-group-flush ' + CC.CLASS.COLLAPSE;
  public liparent: string = 'list-group-item list-group-item-action ' + CC.CLASS.PARENT;
  public li: string = 'list-group-item list-group-item-action';
  public span: string = 'd-flex justify-content-between align-items-center';
  public iconToggle: string = 'fas fa-plus ml-2';
  public iconNone: string = 'fas fa-arrow-circle-right ml-2 ' + ChapterStatus.none;
  public iconOngoing: string = 'fas fa-exclamation-circle ml-2 ' + ChapterStatus.ongoing;
  public iconCompleted: string = 'fas fa-check-circle ml-2 ' + ChapterStatus.completed;
  public iconLock: string = 'fas fa-lock';
  public current: string = CC.CLASS.CURRENT;

  public statusAll: number = CC.ZERO;
  public statusOngoing: number = CC.ZERO;
  public statusCompleted: number = CC.ZERO;
  public progressRate: number = CC.ZERO;

  public introUrl: string = '/course/introduction/introduction/';
  public trialIntroUrl: string = '/course/introduction/introduction-trial/';
  public iframeUrlBase: string = window.location.protocol + '//staging.wp.digskill.net';
  public currentUrl: string = CC.BLANK;
  public urlPlan: string = CC.URL.AUTH.PLAN;

  // これを使うhtmlでは async pipe を使うこと！
  public courseIndexNested$: Promise<CourseIndexNested[]>;

  private iframe;
  private courseIndex: CourseIndex[];
  private courseStatus: CourseStatus;

  private course: string = CC.BLANK;
  private firstUrl: string = CC.BLANK;
  private menuOpenWidth: number = CC.ZERO;

  public planCode: PC.PLANCODE_EXT = PC.PLAN_EXT.none;
  public pc = PC.PLAN_EXT;
  public user: User = null;
  public isTrial: boolean = false;
  private isAdmin: boolean = false;
  private isTrainee: boolean = false;
  public isExpired: boolean = true;
  private originalTitle: string = environment.appname;
  private shortLink: string = CC.BLANK;
  private docId: string = CC.BLANK;

  @ViewChild(SignupComponent, { static: true })
  private signupComponent: SignupComponent;

  constructor(
    private router: Router,
    private location: Location,
    private cookieService: CookieService,
    private reportingservice: ReportingService,
    private authService: AuthService,
    private courseService: CourseService,
    private titleService: Title
  ) { }

  ngOnInit() {
    if (window.location.hostname === 'member.digskill.net') {
      this.iframeUrlBase = window.location.protocol + '//wp.digskill.net';
    }
    this.init();
    if (!this.authService.isAuthenticated()) {
      this.isTrial = true;
    }
    // URLからcourseと初期URLを設定
    this.setCourseAndFirstUrl();
    // 目次とその進捗状況を取得
    this.getCourseData()
      .finally(() => {
        // iframe関連の設定
        this.setupIFrame();
        LoadingService.off();
      });
    if (this.authService.isAuthenticated() && !this.firstUrl) {
      this.authService.ping();
    }
    if (!this.authService.isAuthenticated() && !this.firstUrl) {
      this.courseIndexInfoToast();
    }
    // 目次メニューの表示制御
    this.setupMenu();
    this.toggleAll();
  }

  ngOnDestroy() {
    JqueryService.removeOnMessage();
  }

  ngAfterViewInit() {
    if (!this.firstUrl) {
      setTimeout(() => {
        this.toggleAll();
      }, 500)
    }
    if (!this.cookieService.get(AC.GTAG.COURSE)) {
      // Event snippet for 無料プランで学習開始 conversion page
      gtag('event', 'conversion', { 'send_to': 'AW-10944845857/uXuaCP-mhswDEKGw9OIo' });
      this.cookieService.set(AC.GTAG.COURSE, AC.GTAG.COURSE, 3650);
    }
  }

  @HostListener(CC.EVENT.WINDOW_RESIZE, [CC.EVENT.EVENT])
  onResize(event) {
    JqueryService.setCssVariableVH(CC.ID.COURSE);
  }

  init() {
    if (this.authService.isAuthenticated()) {
      this.user = this.authService.getUser() || new User();
      this.planCode = this.user.planCode;
      this.isAdmin = this.user.isAdmin;
      this.isTrainee = this.planCode === PC.PLAN_EXT.trainee;
      const authTime: Date = MomentService.newDate();
      const expiration: Date = this.user.expiration;
      this.isExpired = !MomentService.isSameOrBeforeWithDateObject(authTime, expiration);
    }
  }

  toggleMenu() {
    const currentMenuOpenWidth: number = parseInt(
      JqueryService.getStyle(CC.ID.COURSE_MENU, CC.CSS.WIDTH), 10);
    this.menuOpenWidth = currentMenuOpenWidth > 0
      ? currentMenuOpenWidth : this.menuOpenWidth;
    JqueryService.setStyle(CC.ID.COURSE_MENU, CC.CSS.WIDTH,
      ((currentMenuOpenWidth > 0) ? CC.ZERO : this.menuOpenWidth) + CC.PX);
  }

  toggle(event) {
    const target: any = (event.target.tagName.toLowerCase() === CC.HTML.I)
      ? event.target.parentNode : event.target;
    JqueryService.toggleNodeClass(target.parentNode, CC.CLASS.OPEN);
    JqueryService.toggleNodeCollapse(target.nextElementSibling);
  }

  toggleAll() {
    JqueryService.toggleAllCollapse(CC.HTML.DIV + CC.HASH + CC.ID.COURSE_MENU);
    JqueryService.toggleAllClass(CC.HTML.LI + CC.PERIOD + CC.CLASS.PARENT, CC.CLASS.OPEN);
  }

  iconClass(url: string) {
    return this.isLightPlanNotExpiredOrAdminOrTrainee()
      ? this.getIconClass(this.courseStatus[url]) : this.iconNone;
  }

  open(item: CourseIndexNested) {
    this.changeUrl(item.url);
  }

  showSignupPanel() {
    LoadingService.on();
    this.signupComponent.showPanel();
    LoadingService.off();
  }

  navigate() {
    this.router.navigate([CC.URL.AUTH.PLAN]);
  }

  copy() {
    navigator.clipboard.writeText(this.shortLink);
    JqueryService.addClass('btn-linkcopy', 'click');
    setTimeout(() => {
      JqueryService.removeClass('btn-linkcopy', 'click')
    }, 500);
  }

  private isLightPlanNotExpiredOrAdminOrTrainee = (): boolean =>
    this.authService.isAuthenticated() && (
      (this.planCode === this.pc.light_20210901 && !this.isExpired)
      || this.isTrainee || this.isAdmin);

  private setCourseAndFirstUrl = (): void => {
    const urlParts: string[] = this.router.url.split(CC.SLASH);
    this.course = (urlParts.length >= 3)
      ? urlParts[2] : CC.BLANK;
    this.firstUrl = (urlParts.length >= 4)
      ? this.router.url : CC.BLANK;
  }

  private getCourseData = async (): Promise<void> => {
    LoadingService.on();
    if (this.isLightPlanNotExpiredOrAdminOrTrainee()) {
      this.courseStatus = await this.courseService.getCourseStatus(
        this.authService.getUser(), this.course);
    } else {
      this.setSampleStatus();
    }
    this.courseIndex = await this.courseService.getCourseIndex(this.course);
    if (!this.courseIndex.length || (this.firstUrl && !this.courseIndex.find((value) => value.url === (this.firstUrl + '/')))) {
      setTimeout(() => {
        this.errorNavigate();
      }, 500);
    }
    this.courseIndexNested$ = this.courseService.getCourseIndexNested(this.courseIndex);
    if (this.firstUrl === CC.BLANK && this.isLightPlanNotExpiredOrAdminOrTrainee()) {
      this.statusUpdate();
    } else {
      this.statusUpdate(true);
    }
    this.setTitle();
    LoadingService.off();
  }

  private setTitle = (url?: string) => {
    const course: CourseIndex = this.courseIndex.find(value => value.url === url);
    this.titleService.setTitle(((!url || !course) ? '' : (course.title + ' - '))
      + this.courseIndex[0].title + ' / ' + this.originalTitle);
  }

  private setupIFrame = (): void => {
    this.iframe = JqueryService.getById(CC.ID.DISPLAY_IFRAME)[0];
    this.setOnMessage();
    if (this.firstUrl !== CC.BLANK) {
      this.changeUrl(this.firstUrl + CC.SLASH);
    }
  }

  private setOnMessage = (): void => {
    JqueryService.setOnMessage(event => {
      if (event.originalEvent.origin === this.iframeUrlBase) {
        this.acceptMessage(JSON.parse(event.originalEvent.data));
      }
    });
  }

  private acceptMessage = async (data: WM.WebMessage):
    Promise<void> => {
    if (data.messageType === WM.MessageType.onLoad) {
      this.acceptIFrameOnLoad((data.messageBody as WM.OnLoadMessage).location);
    } else if (data.messageType === WM.MessageType.locationChange) {
      this.changeUrl(this.stripUrlBase(
        (data.messageBody as WM.LocationChangeMessage).location));
    } else if (data.messageType === WM.MessageType.statusUpdate) {
      const status: ChapterStatus = (data.messageBody as WM.StatusUpdateMessage).status;
      this.courseStatus = await this.courseService.firestoreSaveCourseStatus(
        this.authService.getUser(), this.course, this.currentUrl, status);
      this.statusChanged(status);
    } else if (data.messageType === WM.MessageType.upgradeBannerOnClick) {
      this.acceptUpgradeBannerOnClick();
    } else if (data.messageType === WM.MessageType.shareButtonOnClick) {
      this.acceptShareButtonOnClick();
    }
  }

  private acceptIFrameOnLoad = async (location: string) => {
    let wait = 0;
    while (!this.courseIndex && wait < 10) {
      await new Promise(r => setTimeout(r, 200));
      wait++;
    }
    const url = this.stripUrlBase(location);
    this.changeUrl(url, true);
    if (this.isLightPlanNotExpiredOrAdminOrTrainee()) {
      this.authService.ping(this.currentUrl);
      const status: ChapterStatus = this.courseStatus[this.currentUrl];
      this.statusChanged(status);
      if (JqueryService.getBySelector(CC.PERIOD + CC.CLASS.CURRENT).length) {
        this.postPurchased(true);
      }
    }
    this.postNavLinks();
    if (JqueryService.getBySelector(CC.PERIOD + CC.CLASS.CURRENT).length) {
      this.focusCurrentIndex();
    }
    LoadingService.off();
    if (!this.authService.isAuthenticated()) {
      this.courseIndexInfoToast();
    }
  };

  private acceptUpgradeBannerOnClick = async () => {
    if (this.authService.isAuthenticated()) {
      this.navigate();
    } else {
      this.showBillingGuidePanel();
    }
  };

  // i.currentを探し出して、そいつの親を再帰的にopenにする
  private focusCurrentIndex = (): void =>
    JqueryService.openParentsAndScrollTo(
      CC.PERIOD + CC.CLASS.CURRENT, CC.ID.COURSE_MENU)

  private statusChanged = (status: ChapterStatus): void => {
    this.postCurrentStatus(status);
    if (JqueryService.getBySelector(CC.PERIOD + CC.CLASS.CURRENT).length) {
      this.setIcon(status);
    }
    this.statusUpdate();
  }

  private setSampleStatus = (): void => {
    this.statusAll = CC.SAMPLE_STATUS.ALL;
    this.statusOngoing = CC.SAMPLE_STATUS.ONGOING;
    this.statusCompleted = CC.SAMPLE_STATUS.COMPLETED;
  }

  private postMessage = (
    messageType: WM.MessageType, messageBody?: WM.MessageBody) =>
    this.iframe.contentWindow.postMessage(
      JSON.stringify({ messageType, messageBody }), '*');

  private postPurchased = (purchased: boolean): void => {
    const purchasedMessage: WM.PurchasedMessage = { purchased };
    this.postMessage(WM.MessageType.purchased, purchasedMessage);
  }

  private postCurrentStatus = (status: ChapterStatus): void => {
    const currentStatusMessage: WM.CurrentStatusMessage = { status };
    this.postMessage(WM.MessageType.currentStatus, currentStatusMessage);
  }

  private postNavLinks = (): void => {
    const linkList: CourseIndex[] = this.courseIndex.filter(value => value.url !== CC.BLANK);
    const curIdx: number = linkList.findIndex(value => value.url === this.currentUrl);
    const lastIdx: number = linkList.length - CC.ONE;
    const prevIdx: number = (curIdx === CC.ZERO) ? lastIdx : curIdx - CC.ONE;
    const nextIdx: number = (curIdx === lastIdx) ? CC.ZERO : curIdx + CC.ONE;
    const prev: CourseIndex = linkList[prevIdx];
    const next: CourseIndex = linkList[nextIdx];
    const isBlank: boolean = this.authService.getForgetMeNot();
    const navLinkMessage: WM.NavLinkMessage = { prev, next, isBlank };
    this.postMessage(WM.MessageType.setNavLinks, navLinkMessage);
  }

  private isFreePage = (url: string) => {
    let isFree = true;
    if (!this.isLightPlanNotExpiredOrAdminOrTrainee()) {
      const course: CourseIndex = this.courseIndex.find(value => value.url === url);
      isFree = course ? course.isFree : true;
    }
    return isFree;
  }

  private changeUrl = (url: string, withoutReload: boolean = false) => {
    if (this.isFreePage(url)) {
      if ((url === this.introUrl || url === this.trialIntroUrl)
        && !this.authService.isAuthenticated()) {
        if (!withoutReload) {
          this.currentUrl = this.introUrl;
          LoadingService.on();
          this.iframe.src = this.iframeUrlBase + this.trialIntroUrl;
        }
      } else {
        this.currentUrl = url;
        if (!withoutReload) {
          LoadingService.on();
          this.iframe.src = this.iframeUrlBase + url;
        }
      }
    } else {
      this.showBillingGuidePanel();
    }
    this.setTitle(url);
    this.location.replaceState(this.currentUrl);
  }

  private stripUrlBase = (url: string): string =>
    url.replace(this.iframeUrlBase, CC.BLANK)

  private statusUpdate = (isTrial: boolean = false): void => {
    if (!isTrial) {
      this.statusAll = CC.ZERO;
      this.statusOngoing = CC.ZERO;
      this.statusCompleted = CC.ZERO;
      this.courseIndex.forEach(item => {
        if (item.url !== CC.BLANK) {
          this.statusAll++;
          const status: ChapterStatus = this.courseStatus[item.url];
          if (status === ChapterStatus.ongoing) {
            this.statusOngoing++;
          } else if (status === ChapterStatus.completed) {
            this.statusCompleted++;
          }
        }
      });
    }
    this.progressRate = Math.floor(
      (this.statusCompleted / this.statusAll) * CC.HUNDRED);
    if (isNaN(this.progressRate)) {
      this.progressRate = CC.ZERO;
    } else {
      JqueryService.setStyle(CC.ID.STATUS_BAR_COMPLETED,
        CC.CSS.WIDTH, this.progressRate + CC.PERCENT);
      const progressRateOngoing: number = Math.floor(
        ((this.statusOngoing + this.statusCompleted === this.statusAll)
          ? CC.HUNDRED - this.progressRate
          : Math.floor((this.statusOngoing / this.statusAll) * CC.HUNDRED))
        / CC.TWO);
      this.progressRate += progressRateOngoing;
      JqueryService.setStyle(CC.ID.STATUS_BAR_ONGOING,
        CC.CSS.WIDTH, progressRateOngoing + CC.PERCENT);
      JqueryService.setStyle(CC.ID.STATUS_BAR_NONE,
        CC.CSS.WIDTH, (CC.HUNDRED - this.progressRate) + CC.PERCENT);
    }
  }

  private getIconClass = (status: ChapterStatus): string =>
    ((status === ChapterStatus.ongoing) ? this.iconOngoing
      : (status === ChapterStatus.completed) ? this.iconCompleted
        : this.iconNone) + CC.SPACE + CC.CLASS.CURRENT

  private setIcon = (status: ChapterStatus): string =>
    JqueryService.getBySelector(CC.PERIOD + CC.CLASS.CURRENT)[0]
      .className = this.getIconClass(status)

  private setupMenu = (): void => {
    this.setCssVariableVH();
    this.setResizable();
    this.setupToggleMenu();
  }

  private setCssVariableVH = (): void =>
    JqueryService.setCssVariableVH(CC.ID.COURSE)

  private setResizable = (): void =>
    JqueryService.setResizable(CC.ID.COURSE_MENU, {
      handleSelector: CC.PERIOD + CC.CLASS.SPLIT_HANDLE,
      resizeHeight: false,
      touchActionNone: false
    })

  private setupToggleMenu = (): number =>
    this.menuOpenWidth = parseInt(
      JqueryService.getStyle(CC.ID.COURSE_MENU, CC.CSS.WIDTH), 10)

  private showBillingGuidePanel = (): void => {
    JqueryService.showModal(CC.ID.BILLING_GUIDE_PANEL);
  }

  private courseIndexInfoToast = (): void => {
    if (parseInt(this.cookieService.get('ciitc') || '0') < 1) {
      this.reportingservice.indexInfoToast(
        '', '画面左下のボタンで目次の表示・非表示を切り替えられます。');
      this.cookieService.set('ciitc',
        (parseInt(this.cookieService.get('ciitc') || '0') + 1).toString(), 30, '/');
    }
  }

  private acceptShareButtonOnClick = (): void => {
    this.createLink();
    JqueryService.showModal(CC.ID.SHARE_PANEL);
  }

  private createLink = async (): Promise<void> => {
    const title: string = this.titleService.getTitle();
    const description: string = environment.shareUrl.description;
    const courseName: string = this.course.split(CC.HYPHEN).join(CC.UNDERSCORE);
    const imageLink: string = environment.shareUrl.imageLink[courseName];
    let newDocIdFlag: boolean = false;
    while (!newDocIdFlag) {
      const doc: DocumentReference = await this.courseService.createDocId();
      this.docId = doc.id.slice(0, 10);
      newDocIdFlag = await this.courseService.docIdCheck(this.docId);
    }
    this.courseService.firestoreSaveShare(this.docId, this.user?.uid, title, description, imageLink, window.location.href);
    const shortLink = environment.shareUrl.domainUri + '/' + this.docId;
    const tweetParams = {
      url: shortLink + '\n\n',
      text: 'おすすめのカリキュラムを共有します！\n\n',
      hashtags: 'DigSkill' + ',' + this.courseIndex[0].title.split(' ').join('')
    };
    const twitterUrl: string = environment.shareUrl.snsUrl.twitter + '/?' + (new URLSearchParams(tweetParams).toString());
    const facebookParams = { u: shortLink };
    const facebookUrl: string = environment.shareUrl.snsUrl.facebook + '/?' + (new URLSearchParams(facebookParams).toString());
    const lineParams = { url: shortLink };
    const lineUrl: string = environment.shareUrl.snsUrl.line + '/?' + (new URLSearchParams(lineParams).toString());
    const hatenaUrl: string = environment.shareUrl.snsUrl.hatena + '/' + window.location.href.replace('https://', '');
    this.shortLink = shortLink;
    JqueryService.addProperty('btn-twitter', 'href', twitterUrl);
    JqueryService.addProperty('btn-facebook', 'href', facebookUrl);
    JqueryService.addProperty('btn-line', 'href', lineUrl);
    JqueryService.addProperty('btn-hatena', 'href', hatenaUrl);
  }

  private errorNavigate = (): void => {
    this.isLightPlanNotExpiredOrAdminOrTrainee()
    ? this.router.navigate([CC.URL.ROOT]) : this.router.navigate([CC.URL.TRIAL]);
  }

}
