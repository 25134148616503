import isDate from 'lodash/isdate';
import isNull from 'lodash/isnull';
import * as moment from 'moment';
import { DateRange, extendMoment } from 'moment-range';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { map, mapTo, scan, tap } from 'rxjs/operators';
import { CommonConstants as CC } from 'src/app/constants/common.constants';
import { MomentConstants as MC } from 'src/app/constants/moment.constants';
import { environment } from 'src/environments/environment';

const exmoment = extendMoment(moment);

export class MomentService {

  private static now: moment.Moment = null;
  private static quartzSubscription: Subscription = new Subscription();
  public static clock: Observable<Date>;
  public static setClock = (start: Date): void => {
    MomentService.now = exmoment(start);
    const quartz: Observable<Date> =
      interval(CC.MILLE).pipe(
        mapTo(CC.MILLE),
        scan((timestamp, msec) => timestamp + msec, start.getTime()),
        map(timestamp => new Date(timestamp)),
        tap(date => MomentService.now = exmoment(date))
      );
    const clockSubject: Subject<Date> = new Subject<Date>();
    MomentService.quartzSubscription.unsubscribe();
    MomentService.quartzSubscription = quartz.subscribe(clockSubject);
    MomentService.clock = clockSubject.asObservable();
  }

  /**
   * momentを生成してLocaleにMC.DATE.LOCALEをセット
   *
   * 1. valueがDate => valueから生成
   * 1. valueがnullでなくformatがnull => valueから生成
   * 1. valueもformatもnullでない => valueとformatから生成
   * 1. valueがnullでenvironment.nowがある => テスト時点
   * 1. MomentService.nowがnullでない => サーバー基準の現在時点
   * 1. それ以外 => クライアント基準の現在時点
   * @param value 元になる時点
   * @param format valueの書式
   * @returns 生成されたmoment
   */
  private static getMoment = (
    value: string | number | Date = null,
    format: string = null
  ): moment.Moment => (
    !isNull(value)
      ? (isDate(value) || isNull(format))
        ? exmoment(value)
        : exmoment(value, format)
      : !isNull(environment.now)
        ? exmoment(environment.now, MC.DATE.FORMAT.Y_h_M_h_D_b_H_c_m_c_s_p_S)
        : !isNull(MomentService.now)
          ? MomentService.now.clone()
          : exmoment()
  ).locale(MC.DATE.LOCALE)

  /**
   * 日付文字列からmomentを生成して
   * javascript標準のDateオブジェクトに変換する
   *
   * - iOSではnew Date()にyyyy-mm-ddを渡すと
   * - Invalid dateとなるため、一旦moment化する
   * @param date 日付文字列
   * @param format 日付文字列の書式
   * @returns javascript標準のDateオブジェクト
   */
  public static newDate = (date: string = null, format: string = null):
    Date => MomentService.getMoment(date, format).toDate()

  /**
   * 日付判定
   *
   * - 書式：MomentConstants.DATE.FORMAT.Y_h_M_h_D
   * @param date 判定したい日付
   * @returns 判定結果
   */
  public static isValidDate = (date: string): boolean =>
    exmoment(date, MC.DATE.FORMAT.Y_h_M_h_D).isValid()

  /**
   * ２つのmoment間のmoment-rangeを生成
   *
   * @param start moment-rangeの開始
   * @param end moment-rangeの終了
   * @returns moment-range
   */
  private static getMomentRange = (
    start: moment.Moment, end: moment.Moment):
    DateRange => exmoment.range(start, end)

  /**
   * Dateオブジェクトからmomentを生成
   *
   * @param date Dateオブジェクト
   * @returns 生成されたmoment
   */
  public static getMomentFromDateObject = (date: Date):
    moment.Moment => MomentService.getMoment(date)

  /**
   * UnixTimeStamp（エポック秒）からmomentを生成
   *
   * @param unixTimeStamp UnixTimeStamp（エポック秒）
   * @returns 生成されたmoment
   */
  public static getMomentFromEpoch = (unixTimeStamp: number):
    moment.Moment => MomentService.getMoment(
      unixTimeStamp, MC.TIME.FORMAT.UNIX_TIME_STAMP)

  /**
   * 西暦年の文字列からmomentを生成
   *
   * - 書式：MomentConstants.DATE.FORMAT.Y
   * @param year 西暦年、省略時はnull（当年）
   * @param offset オフセット、省略時は0
   * @param offsetFormat オフセットの書式、省略時はMomentConstants.DATE.FORMAT.YEARS
   * @returns 生成されたmoment
   */
  public static getMomentFromYear = (
    year: string = null, offset: number = CC.ZERO,
    offsetFormat: moment.unitOfTime.DurationConstructor = MC.DATE.FORMAT.YEARS):
    moment.Moment => MomentService.getMoment(year, MC.DATE.FORMAT.Y)
      .add(offset, offsetFormat)

  /**
   * 西暦年月の文字列からmomentを生成
   *
   * - 書式：MomentConstants.DATE.FORMAT.YM
   * @param yearMonth 西暦年月、省略時はnull（当月）
   * @param offset オフセット、省略時は0
   * @param offsetFormat オフセットの書式、省略時はMomentConstants.DATE.FORMAT.MONTHS
   * @returns 生成されたmoment
   */
  public static getMomentFromYearMonth = (
    yearMonth: string = null, offset: number = CC.ZERO,
    offsetFormat: moment.unitOfTime.DurationConstructor = MC.DATE.FORMAT.MONTHS):
    moment.Moment => MomentService.getMoment(yearMonth, MC.DATE.FORMAT.YM)
      .add(offset, offsetFormat)

  /**
   * 日付と時刻の文字列からmomentを生成
   *
   * - 書式：MomentConstants.DATE.FORMAT.Y_h_M_h_D_b_H_c_m
   * @param date 日付文字列
   * @param time 時刻文字列
   * @returns 生成されたmoment
   */
  public static getMomentFromDateTime = (date: string, time: string):
    moment.Moment => MomentService.getMoment(
      `${date} ${time}`, MC.DATE.FORMAT.Y_h_M_h_D_b_H_c_m)

  /**
   * 日付の文字列からmomentを生成
   *
   * - 書式：MomentConstants.DATE.FORMAT.Y_h_M_h_D
   * @param date 日付文字列、省略時はnull（当日）
   * @param offset オフセット、省略時は0
   * @param offsetFormat オフセットの書式、省略時はMomentConstants.DATE.FORMAT.DAYS
   * @returns 生成されたmoment
   */
  public static getMomentFromDate = (date: string = null, offset: number = CC.ZERO,
    offsetFormat: moment.unitOfTime.DurationConstructor = MC.DATE.FORMAT.DAYS):
    moment.Moment => MomentService.getMoment(date, MC.DATE.FORMAT.Y_h_M_h_D)
      .add(offset, offsetFormat)

  /**
   * 時刻の文字列からmomentを生成
   *
   * - 書式：MomentConstants.TIME.FORMAT.H_c_m
   * @param time 時刻文字列
   * @returns 生成されたmoment
   */
  public static getMomentFromTime = (time: string):
    moment.Moment => MomentService.getMoment(time, MC.TIME.FORMAT.H_c_m)

  /**
   * 開始／終了年の文字列からmoment-rangeを生成
   *
   * - 書式：MomentConstants.DATE.FORMAT.Y
   * @param start 開始年文字列
   * @param end 終了年文字列
   * @returns 生成されたmoment-range
   */
  public static getYearRange = (start: string, end: string):
    DateRange => MomentService.getMomentRange(
      MomentService.getMomentFromYear(start),
      MomentService.getMomentFromYear(end))

  /**
   * 開始／終了西暦年月の文字列からmoment-rangeを生成
   *
   * - 書式：MomentConstants.DATE.FORMAT.YM
   * @param start 開始西暦年月文字列
   * @param end 終了西暦年月文字列
   * @returns 生成されたmoment-range
   */
  public static getYearMonthRange = (start: string, end: string):
    DateRange => MomentService.getMomentRange(
      MomentService.getMomentFromYearMonth(start),
      MomentService.getMomentFromYearMonth(end))

  /**
   * 日付と開始／終了時刻の文字列からmoment-rangeを生成
   *
   * - 書式：MomentConstants.DATE.FORMAT.Y_h_M_h_D_b_H_c_m
   * @param date 日付文字列
   * @param start 開始時刻文字列
   * @param end 終了時刻文字列
   * @returns 生成されたmoment-range
   */
  public static getDateTimeRange = (date: string, start: string, end: string):
    DateRange => MomentService.getMomentRange(
      MomentService.getMomentFromDateTime(date, start),
      MomentService.getMomentFromDateTime(date, end))

  /**
   * 開始／終了日付の文字列からmoment-rangeを生成
   *
   * - 書式：MomentConstants.DATE.FORMAT.Y_h_M_h_D
   * @param start 開始日付文字列
   * @param end 終了日付文字列
   * @returns 生成されたmoment-range
   */
  public static getDateRange = (start: string, end: string):
    DateRange => MomentService.getMomentRange(
      MomentService.getMomentFromDate(start),
      MomentService.getMomentFromDate(end))

  /**
   * 開始／終了時刻の文字列からmoment-rangeを生成
   *
   * - 書式：MomentConstants.TIME.FORMAT.H_c_m
   * @param start 開始時刻文字列
   * @param end 終了時刻文字列
   * @returns 生成されたmoment-range
   */
  public static getTimeRange = (start: string, end: string):
    DateRange => MomentService.getMomentRange(
      MomentService.getMomentFromTime(start),
      MomentService.getMomentFromTime(end))

  /**
   * 開始／終了西暦年の文字列からmoment-rangeを生成、
   * 引数で渡された関数にmomentを渡しながら順次処理する
   *
   * - 範囲の終了時点は含まない
   * - 書式：MomentConstants.DATE.FORMAT.Y
   * @param start 開始西暦年文字列
   * @param end 終了西暦年文字列
   * @param func 実行する関数
   */
  public static forEachYearRange = (start: string, end: string,
    func: (year: moment.Moment) => void): void => {
    const yearRange: DateRange =
      MomentService.getYearRange(start, end);
    for (const year of yearRange.by(MC.DATE.FORMAT.YEARS,
      { excludeEnd: true })) {
      func(year);
    }
  }

  /**
   * 開始／終了西暦年月の文字列からmoment-rangeを生成、
   * 引数で渡された関数にmomentを渡しながら順次処理する
   *
   * - 範囲の終了時点は含まない
   * - 書式：MomentConstants.DATE.FORMAT.YM
   * @param start 開始西暦年月文字列
   * @param end 終了西暦年月文字列
   * @param func 実行する関数
   */
  public static forEachYearMonthRange = (start: string, end: string,
    func: (yearMonth: moment.Moment) => void): void => {
    const yearMonthRange: DateRange =
      MomentService.getYearMonthRange(start, end);
    for (const yearMonth of yearMonthRange.by(MC.DATE.FORMAT.MONTHS,
      { excludeEnd: true })) {
      func(yearMonth);
    }
  }

  /**
   * 日付と開始／終了時刻の文字列からmoment-rangeを生成、
   * 引数で渡された関数にmomentを渡しながら順次処理する
   *
   * - 範囲の終了時点は含まない
   * - 書式：MomentConstants.DATE.FORMAT.Y_h_M_h_D_b_H_c_m
   * @param date 日付文字列、nullならば当日
   * @param start 開始時刻文字列
   * @param end 終了時刻文字列
   * @param step イテレーション間隔
   * @param stepFormat イテレーション間隔の書式
   * @param func 実行する関数
   */
  public static forEachDateTimeRange = (
    date: string, start: string, end: string, step: number,
    stepFormat: moment.unitOfTime.DurationConstructor,
    func: (time: moment.Moment) => void): void => {
    const timeRange: DateRange = isNull(date) ?
      MomentService.getTimeRange(start, end) :
      MomentService.getDateTimeRange(date, start, end);
    for (const time of timeRange.by(stepFormat,
      { step, excludeEnd: true })) {
      func(time);
    }
  }

  /**
   * 開始／終了日付の文字列からmoment-rangeを生成、
   * 引数で渡された関数にmomentを渡しながら順次処理する
   *
   * - 範囲の終了時点は含まない
   * - 書式：MomentConstants.DATE.FORMAT.Y_h_M_h_D
   * @param start 開始日付文字列
   * @param end 終了日付文字列
   * @param func 実行する関数
   */
  public static forEachDateRange = (
    start: string, end: string,
    func: (date: moment.Moment) => void): void => {
    const dateRange: DateRange = MomentService.getDateRange(start, end);
    for (const date of dateRange.by(MC.DATE.FORMAT.DAYS,
      { excludeEnd: true })) {
      func(date);
    }
  }

  /**
   * 西暦年文字列生成
   *
   * @param offset 取得したい年からの差分年数、省略時は0
   * @param year 取得したい年、省略時はnull（当年）
   * @param format 取得したい書式、省略時はMomentConstants.DATE.FORMAT.Y
   * @returns 西暦年文字列
   */
  public static getYear = (offset: number = CC.ZERO,
    year: string = null, format: string = MC.DATE.FORMAT.Y):
    string => MomentService.getYearFromMoment(
      MomentService.getMomentFromYear(
        year, offset, MC.DATE.FORMAT.YEARS), format)

  /**
   * momentから西暦年文字列生成
   *
   * @param year 取得したい西暦年のmoment
   * @param format 取得したい書式、省略時はMomentConstants.DATE.FORMAT.Y
   * @returns 西暦年文字列
   */
  public static getYearFromMoment =
    (year: moment.Moment, format: string = MC.DATE.FORMAT.Y):
      string => year.format(format)

  /**
   * 西暦年月文字列生成
   *
   * @param offset 取得したい年月からの差分月数、省略時は0
   * @param yearMonth 取得したい年月、省略時はnull（当月）
   * @param format 取得したい書式、省略時はMomentConstants.DATE.FORMAT.YM
   * @returns 西暦年月文字列
   */
  public static getYearMonth = (offset: number = CC.ZERO,
    yearMonth: string = null, format: string = MC.DATE.FORMAT.YM):
    string => MomentService.getYearMonthFromMoment(
      MomentService.getMomentFromYear(
        yearMonth, offset, MC.DATE.FORMAT.MONTHS), format)

  /**
   * momentから西暦年月文字列生成
   *
   * @param yearMonth 取得したい西暦年月のmoment
   * @param format 取得したい書式、省略時はMomentConstants.DATE.FORMAT.YM
   * @returns 西暦年月文字列
   */
  public static getYearMonthFromMoment =
    (yearMonth: moment.Moment, format: string = MC.DATE.FORMAT.YM):
      string => yearMonth.format(format)

  /**
   * 日付文字列生成
   *
   * @param offset 取得したい日付からの差分日数、省略時は0
   * @param date 取得したい日付、省略時は空文字（当日）
   * @param format 取得したい書式、省略時はMomentConstants.DATE.FORMAT.Y_h_M_h_D
   * @returns 日付文字列
   */
  public static getDate = (offset: number = CC.ZERO,
    date: string = null, format: string = MC.DATE.FORMAT.Y_h_M_h_D):
    string => MomentService.getDateFromMoment(
      MomentService.getMomentFromDate(
        date, offset, MC.DATE.FORMAT.DAYS), format)

  /**
   * momentから日付文字列生成
   *
   * @param date 取得したい日付のmoment
   * @param format 取得したい書式、省略時はMomentConstants.DATE.FORMAT.Y_h_M_h_D
   * @returns 日付文字列
   */
  public static getDateFromMoment =
    (date: moment.Moment, format: string = MC.DATE.FORMAT.Y_h_M_h_D):
      string => date.format(format)

  /**
   * Dateオブジェクトから日付文字列生成
   *
   * @param date 取得したい日付のDateオブジェクト
   * @param format 取得したい書式、省略時はMomentConstants.DATE.FORMAT.Y_h_M_h_D
   * @returns 日付文字列
   */
  public static getDateFromDateObject =
    (date: Date, format: string = MC.DATE.FORMAT.Y_h_M_h_D):
      string => isNull(date) ? CC.BLANK
        : MomentService.getDateFromMoment(
          MomentService.getMoment(date), format)

  /**
   * Dateオブジェクトから日時文字列生成
   *
   * @param date 取得したい日時のDateオブジェクト
   * @returns 日時文字列（MomentConstants.DATE.FORMAT.Y_s_M_s_D_r_d_r_b_H_c_m）
   */
  public static getDateTimeFromDateObject = (date: Date):
    string => MomentService.getDateFromDateObject(date, MC.DATE.FORMAT.Y_s_M_s_D_r_d_r_b_H_c_m);

  /**
   * UnixTimeStamp（エポック秒）から日付文字列生成
   *
   * @param unixTimeStamp 取得したい日付のUnixTimeStamp（エポック秒）
   * @param format 取得したい書式、省略時はMomentConstants.DATE.FORMAT.Y_h_M_h_D
   * @returns 日付文字列
   */
  public static getDateFromEpoch =
    (unixTimeStamp: number, format: string = MC.DATE.FORMAT.Y_h_M_h_D):
      string => MomentService.getDateFromMoment(
        MomentService.getMomentFromEpoch(unixTimeStamp), format)

  /**
   * UnixTimeStamp（エポック秒）から時刻文字列生成
   *
   * @param unixTimeStamp 取得したい時刻のUnixTimeStamp（エポック秒）
   * @param format 取得したい書式、省略時はMomentConstants.DATE.FORMAT.Y_h_M_h_D
   * @returns 時刻文字列
   */
  public static getTimeFromEpoch =
    (unixTimeStamp: number, format: string = MC.TIME.FORMAT.H_c_m_c_s):
      string => MomentService.getTimeFromMoment(
        MomentService.getMomentFromEpoch(unixTimeStamp), CC.ZERO, format)

  /**
   * 時刻文字列生成
   *
   * @param time 取得したい時刻
   * @param offset 取得したい時刻からの差分（分単位）、省略時は0
   * @param format 取得したい書式、省略時はMomentConstants.TIME.FORMAT.H_c_m
   * @returns 時刻文字列
   */
  public static getTime = (time: string, offset: number = CC.ZERO,
    format: string = MC.TIME.FORMAT.H_c_m):
    string => MomentService.getTimeFromMoment(
      MomentService.getMomentFromTime(time), offset, format)

  /**
   * momentから時刻文字列生成
   *
   * @param time 取得したい時刻のmoment
   * @param offset 取得したい時刻からの差分（分単位）、省略時は0
   * @param format 取得したい書式、省略時はMomentConstants.TIME.FORMAT.H_c_m
   * @returns 時刻文字列
   */
  public static getTimeFromMoment = (time: moment.Moment,
    offset: number = CC.ZERO, format: string = MC.TIME.FORMAT.H_c_m):
    string => {
    let result: string =
      time.add(offset, MC.TIME.FORMAT.MINUTES).format(format);
    // 増加させて00:00なら24:00にする
    if (offset > CC.ZERO && result === MC.TIME.MID_NIGHT) {
      result = MC.TIME.MID_NIGHT_SYNONYM;
    }
    return result;
  }

  /**
   * 年文字列からその年の最初の日付を取得する
   *
   * @param year 年文字列、省略時はnull（当年）
   * @param format 取得したい書式、省略時はMomentConstants.DATE.FORMAT.YEARS
   * @returns 日付文字列
   */
  public static getYearFirstDate = (
    year: string = null, format: string = MC.DATE.FORMAT.Y_h_M_h_D):
    string => MomentService.getMomentFromYear(year)
      .startOf(MC.DATE.FORMAT.YEARS).format(format)

  /**
   * 年文字列からその年の最後の日付を取得する
   *
   * @param year 年文字列、省略時はnull（当年）
   * @param format 取得したい書式、省略時はMomentConstants.DATE.FORMAT.YEARS
   * @returns 日付文字列
   */
  public static getYearEndDate = (
    year: string = null, format: string = MC.DATE.FORMAT.Y_h_M_h_D):
    string => MomentService.getMomentFromYear(year)
      .endOf(MC.DATE.FORMAT.YEARS).format(format)

  /**
   * 年月文字列からその年月の最初の日付を取得する
   *
   * @param yearMonth 年月文字列、省略時はnull（当月）
   * @param format 取得したい書式、省略時はMomentConstants.DATE.FORMAT.YEARS
   * @returns 日付文字列
   */
  public static getMonthFirstDate = (
    yearMonth: string = null, format: string = MC.DATE.FORMAT.Y_h_M_h_D):
    string => MomentService.getMomentFromYearMonth(yearMonth)
      .startOf(MC.DATE.FORMAT.MONTHS).format(format)

  /**
   * 年月文字列からその年月の最後の日付を取得する
   *
   * @param yearMonth 年月文字列、省略時はnull（当月）
   * @param format 取得したい書式、省略時はMomentConstants.DATE.FORMAT.YEARS
   * @returns 日付文字列
   */
  public static getMonthEndDate = (
    yearMonth: string = null, format: string = MC.DATE.FORMAT.Y_h_M_h_D):
    string => MomentService.getMomentFromYearMonth(yearMonth)
      .endOf(MC.DATE.FORMAT.MONTHS).format(format)

  /**
   * 日付文字列からその日付の最初の年月日時分秒を取得する
   *
   * @param date 日付文字列、省略時はnull（当日）
   * @param format 取得したい書式、省略時はMomentConstants.DATE.FORMAT.Y_h_M_h_D_b_H_c_m_c_s_p_S
   * @returns 年月日時分秒文字列
   */
  public static getDayStart = (date: string = null,
    format: string = MC.DATE.FORMAT.Y_h_M_h_D_b_H_c_m_c_s_p_S): string =>
    MomentService.getMomentFromDate(date)
      .startOf(MC.TIME.FORMAT.SECONDS).format(format)

  /**
   * 日付文字列からその日付の最後の年月日時分秒を取得する
   *
   * @param date 日付文字列、省略時はnull（当日）
   * @param format 取得したい書式、省略時はMomentConstants.DATE.FORMAT.Y_h_M_h_D_b_H_c_m_c_s_p_S
   * @returns 年月日時分秒文字列
   */
  public static getDayEnd = (date: string = null,
    format: string = MC.DATE.FORMAT.Y_h_M_h_D_b_H_c_m_c_s_p_S): string =>
    MomentService.getMomentFromDate(date,
      MC.TIME.DAY_SECONDS + CC.MINUS_ONE, MC.TIME.FORMAT.SECONDS)
      .endOf(MC.TIME.FORMAT.SECONDS).format(format)

  /**
   * 指定日付を含む週の月曜日の日付文字列生成
   *
   * - 書式：MomentConstants.DATE.FORMAT.Y_h_M_h_D
   * @param date 月曜日を取得したい週の日付
   * @returns 日付文字列
   */
  public static getMonday = (date: string): string =>
    MomentService.getDate(
      - MomentService.getMomentFromDate(date).isoWeekday() + CC.ONE, date)

  /**
   * ある期間内にある時点が含まれるか判定
   *
   * - 最後の時点は含まない
   * @param range 期間
   * @param momentTarget 判定対象の時点
   * @returns 判定結果
   */
  public static isContains = (range: DateRange, momentTarget: moment.Moment):
    boolean => range.contains(momentTarget, { excludeEnd: true })

  /**
   * ある期間同士に重なり合いがあるか判定
   *
   * @param range 期間
   * @param rangeOther 判定対象の期間
   * @returns 判定結果
   */
  public static isOverlaps = (range: DateRange, rangeOther: DateRange):
    boolean => range.overlaps(rangeOther)

  /**
   * ある時点が基準時点より後（>、超過）か判定
   *
   * @param momentTarget 時点
   * @param momentCriterion 基準の時点、省略時はnull（現時点）
   * @returns 判定結果
   */
  public static isAfter = (momentTarget: moment.Moment,
    momentCriterion: moment.Moment = null):
    boolean => isNull(momentCriterion)
      ? momentTarget.isAfter(MomentService.getMoment())
      : momentTarget.isAfter(momentCriterion)

  /**
   * ある時点が基準時点以降（>=、以降）か判定
   *
   * @param momentTarget 時点
   * @param momentCriterion 基準の時点、省略時はnull（現時点）
   * @returns 判定結果
   */
  public static isSameOrAfter = (momentTarget: moment.Moment,
    momentCriterion: moment.Moment = null):
    boolean => isNull(momentCriterion)
      ? momentTarget.isSameOrAfter(MomentService.getMoment())
      : momentTarget.isSameOrAfter(momentCriterion)

  /**
   * ある時点が基準時点より前（<、未満）か判定
   *
   * @param momentTarget 時点
   * @param momentCriterion 基準の時点、省略時はnull（現時点）
   * @returns 判定結果
   */
  public static isBefore = (momentTarget: moment.Moment,
    momentCriterion: moment.Moment = null):
    boolean => isNull(momentCriterion)
      ? momentTarget.isBefore(MomentService.getMoment())
      : momentTarget.isBefore(momentCriterion)

  /**
   * ある時点が基準時点以前（<=、以前）か判定
   *
   * @param momentTarget 時点
   * @param momentCriterion 基準の時点、省略時はnull（現時点）
   * @returns 判定結果
   */
  public static isSameOrBefore = (momentTarget: moment.Moment,
    momentCriterion: moment.Moment = null):
    boolean => isNull(momentCriterion)
      ? momentTarget.isSameOrBefore(MomentService.getMoment())
      : momentTarget.isSameOrBefore(momentCriterion)

  public static isSameOrBeforeWithDateObject =
    (target: Date, criterion: Date): boolean =>
      MomentService.isSameOrBefore(
        MomentService.getMoment(target),
        MomentService.getMoment(criterion))

  /**
   * 生年月日が年齢の範囲内か判定
   *
   * - 書式：MomentConstants.DATE.FORMAT.DATE
   * @param birthdate 生年月日
   * @param minAge 年齢下限
   * @param maxAge 年齢上限
   * @returns 判定結果
   */
  public static checkBirthDateRange = (birthdate: string,
    minAge: number, maxAge: number): boolean =>
    MomentService.isContains(MomentService.getMomentRange(
      MomentService.getMomentFromDate(null, - (maxAge + 1), MC.DATE.FORMAT.YEARS),
      MomentService.getMomentFromDate(null, - minAge, MC.DATE.FORMAT.YEARS)),
      MomentService.getMoment(birthdate, MC.DATE.FORMAT.YMD))

  public static getCountDownString = (currentDate: Date, expirationDate: Date): string => {
    const current: moment.Moment = MomentService.getMoment(currentDate);
    const expiration: moment.Moment = MomentService.getMoment(expirationDate);
    const diff: number = expiration.diff(current);
    const duration: moment.Duration = exmoment.duration(diff);

    const days: number = Math.floor(duration.asDays());
    const hours: string = duration.hours().toString().padStart(2, CC.ZERO.toString());
    const minutes: string = duration.minutes().toString().padStart(2, CC.ZERO.toString());
    const seconds: string = duration.seconds().toString().padStart(2, CC.ZERO.toString());

    return `${days}日 ${hours}:${minutes}:${seconds}`;
  }

  public static getOffset = (timestamp: Date): Date => {
    const from = exmoment(environment.offsetFrom, MC.DATE.FORMAT.Y_h_M_h_D_b_H_c_m_c_s_p_S);
    const to = exmoment(environment.offsetTo, MC.DATE.FORMAT.Y_h_M_h_D_b_H_c_m_c_s_p_S);
    return exmoment(timestamp).add(to.diff(from)).toDate();
  }

  public static getFormatDateTimeFromDateObject = (date: Date, format: string = MC.DATE.FORMAT.Y_s_M_s_D_b_H_c_m_c_s):
    string => MomentService.getDateFromDateObject(date, format);
}
