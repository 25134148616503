export enum EVENTSTATUS {
    none,
    notMatch,
    duplicated,
    ignored,
    accepted,
    needsRetry,
}

export const EVENT = {
    checkout_session_completed: 'checkout.session.completed',
    invoice_paid: 'invoice.paid',
    invoice_payment_failed: 'invoice.payment_failed',
    customer_subscription_updated: 'customer.subscription.updated',
    customer_subscription_deleted: 'customer.subscription.deleted',
    customer_source_expiring: 'customer.source.expiring',
    customer_updated: 'customer.updated',
} as const

export type EVENTTYPE = keyof typeof EVENT;

export const PLAN = {
    free_20210901: 'free_20210901',
    light_20210901: 'light_20210901',
    standard_20210901: 'standard_20210901',
    online_20210901: 'online_20210901',
    question_20220701: 'question_20220701',
} as const;

export type PLANCODE = keyof typeof PLAN;

export const MODE = {
    payment: 'payment',
    subscription: 'subscription',
} as const

export type PAYMENTMODE = keyof typeof MODE;

export const STATUS = {
    active: 'active',
    canceled: 'canceled',
    pending: 'pending',
} as const;

export type PLANSTATUS = keyof typeof STATUS;


const planTitle: { [key in PLANCODE]?: string; } = {
    [PLAN.free_20210901]: '無料プラン',
    [PLAN.light_20210901]: '有料プラン',
    [PLAN.standard_20210901]: 'スタンダードプラン',
    [PLAN.online_20210901]: 'オンライン授業予約回数',
    [PLAN.question_20220701]: '質問フォーム利用回数',
};

const planExpiration: { [key in PLANCODE]?: number; } = {
    [PLAN.free_20210901]: 3650,
    [PLAN.light_20210901]: 30,
    [PLAN.standard_20210901]: 30,
};

const planQuestionCount: { [key in PLANCODE]?: number; } = {
    [PLAN.free_20210901]: 0,
    [PLAN.light_20210901]: 1,
    [PLAN.question_20220701]: 1,
};

const planOnlineCount: { [key in PLANCODE]?: number; } = {
    [PLAN.free_20210901]: 0,
    [PLAN.light_20210901]: 1,
    [PLAN.online_20210901]: 1,
};

export const getPlanTitle = (planCode: PLANCODE): string => planTitle[planCode] || '';
export const getPlanExpiration = (planCode: PLANCODE): number => planExpiration[planCode] || 0;
export const getPlanQuestionCount = (planCode: PLANCODE): number => planQuestionCount[planCode] || 0;
export const getPlanOnlineCount = (planCode: PLANCODE): number => planOnlineCount[planCode] || 0;

export type StripeMetaData = {
    uid: string;
    planCode: PLANCODE;
};

export type StripeSubscription = {
    customer: string;
    subscription: string;
    number: string;
    status: PLANSTATUS;
    cancel_at_period_end: boolean;
};

export const stripeSubscriptionDefault: StripeSubscription = {
    customer: '',
    subscription: '',
    number: '',
    status: STATUS.active,
    cancel_at_period_end: false,
}
