import { BookingCell } from './BookingCell';

export class BookingData {
  date: string;
  start: string;
  end: string;
  display?: string;
  original: {
    date: string;
    start: string;
    end: string;
    display?: string;
    uid: string;
    key: string;
  };

  constructor(partial?: Partial<BookingData>) {
    Object.assign(this, partial);
  }

  setData = (cell: BookingCell): BookingData => {
    this.date = cell.date;
    this.start = cell.start;
    this.end = cell.end;
    this.original = {
      date: cell.date,
      start: cell.start,
      end: cell.end,
      uid: cell.uid || null,
      key: cell.key || null,
    };
    return this;
  }
}
