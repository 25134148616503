import { CommonConstants as CC } from 'src/app/constants/common.constants';
declare const $;

export class JqueryService {

  public static setUpToolTip = (): void =>
    $('[data-toggle="tooltip"]').tooltip({
      placement: 'top', trigger: 'hover focus',
      html: true, container: 'body',
      sanitize: false, sanitizeFn: content => content
    })

  /**
   * HTMLタグのidを指定してjqueryObjectArrayを取得する
   *
   * @param id 取得したいHTMLタグのid
   * @returns jqueryObjectArray
   */
  public static getById = (id: string): any => $(CC.HASH + id);

  /**
   * selectorを指定してjqueryObjectArrayを取得する
   *
   * @param selector 取得したいNodeListのselector
   * @returns jqueryObjectArray
   */
  public static getBySelector = (selector: string): any => $(selector);

  /**
   * HTMLタグのidを指定してclass名を追加する
   *
   * @param id class名を追加したいHTMLタグのid
   */
  public static addClass = (id: string, className: string): any =>
    JqueryService.getById(id).addClass(className)

  /**
   * HTMLタグのidを指定してclass名を削除する
   *
   * @param id class名を削除したいHTMLタグのid
   */
  public static removeClass = (id: string, className: string): any =>
    JqueryService.getById(id).removeClass(className)

  /**
   * HTMLタグのidを指定して属性を追加する
   * 
   * @param id 属性を追加したいHTMLタグのid
   * @param property 属性名
   * @param value 追加する値
   */
  public static addProperty = (id: string, property: string, value: string): any =>
    JqueryService.getById(id).attr(property, value)
  
  /**
   * HTMLタグのidを指定してdata属性を追加する
   * 
   * @param id data属性を追加したいHTMLタグのid
   * @param property data属性名
   * @param value 追加する値
   */
  public static addData = (id: string, property: string, value: string): any =>
    JqueryService.getById(id).attr('data-' + property, value)
  
  /**
   * HTMLタグのidを指定してdata属性を削除する
   * 
   * @param id data属性を削除したいHTMLタグのid
   * @param property data属性名
   */
  public static removeData = (id: string, property: string): any =>
    JqueryService.getById(id).removeAttr('data-' + property)

  /**
   * HTMLタグのidを指定してclass名を追加／削除する
   *
   * @param id class名を追加／削除したいHTMLタグのid
   */
  public static toggleClass = (id: string, className: string): any =>
    JqueryService.getById(id).toggleClass(className)

  /**
   * HTMLのnodeを指定してclass名を追加する
   *
   * @param node class名を追加したいHTMLのnode
   */
  public static addNodeClass = (node: any, className: string): any =>
    $(node).addClass(className)

  /**
   * HTMLのnodeを指定してclass名を削除する
   *
   * @param node class名を削除したいHTMLのnode
   */
  public static removeNodeClass = (node: any, className: string): any =>
    $(node).removeClass(className)

  /**
   * HTMLのnodeを指定してclass名を追加／削除する
   *
   * @param node class名を追加／削除したいHTMLのnode
   */
  public static toggleNodeClass = (node: any, className: string): any =>
    $(node).toggleClass(className)

  /**
   * HTMLタグのidを指定してcollapseの折りたたみを開く
   *
   * @param id 折りたたみを開きたいcollapseのHTMLタグのid
   */
  public static showCollapse = (id: string): void =>
    JqueryService.getById(id).collapse(CC.SHOW)

  /**
   * HTMLタグのidを指定してcollapseの折りたたみを閉じる
   *
   * @param id 折りたたみを閉じたいcollapseのHTMLタグのid
   */
  public static hideCollapse = (id: string): void =>
    JqueryService.getById(id).collapse(CC.HIDE)

  /**
   * HTMLタグのidを指定してcollapseの折りたたみ状態を変更する
   * 1回目の呼び出し時に「collapse show」が付き、
   * 2回目以降で「show」が消えたり付いたりする。
   *
   * @param id 折りたたみ状態を変更したいcollapseのHTMLタグのid
   */
  public static toggleCollapse = (id: string): void =>
    JqueryService.getById(id).collapse(CC.TOGGLE)

  /**
   * JQuerySelectorで指定した条件に合致する
   * 全てのタグにclass名を追加／削除する
   * class名がひとつも無ければ全てにclass名を付け、
   * class名がひとつでも有れば全てのclass名を取る
   *
   * @param selector 操作したい対象のJQuerySelector
   * @param className 追加／削除したいclass名
   */
  public static toggleAllClass =
    (selector: string, className: string): void => {
      const targetList: any = $(selector);
      const classAddedList: any = targetList.filter(CC.PERIOD + className);
      (classAddedList.length > 0)
        ? classAddedList.each(
          (_: number, element: any) => $(element).removeClass(className))
        : targetList.each(
          (_: number, element: any) => $(element).addClass(className));
    }

  /**
   * HTMLのnodeを指定してcollapseの折りたたみを開く
   *
   * @param node 開きたいcollapseのnode
   */
  public static showNodeCollapse = (node: any): void =>
    $(node).collapse(CC.SHOW)

  /**
   * HTMLのnodeを指定してcollapseの折りたたみを閉じる
   *
   * @param node 開きたいcollapseのnode
   */
  public static hideNodeCollapse = (node: any): void =>
    $(node).collapse(CC.HIDE)

  /**
   * HTMLのnodeを指定してcollapseの折りたたみ状態を変更する
   * 1回目の呼び出し時に「collapse show」が付き、
   * 2回目以降で「show」が消えたり付いたりする。
   *
   * @param node 折りたたみ状態を変更したいcollapseのnode
   */
  public static toggleNodeCollapse = (node: any): void =>
    $(node).collapse(CC.TOGGLE)

  /**
   * JQuerySelectorで指定したnode配下の
   * 全てのcollapseの折りたたみを開く
   *
   * @param baseSelector 操作したい折り畳みの上位nodeのJQuerySelector
   */
  public static showAllCollapse =
    (baseSelector: string = CC.BLANK): void =>
      $(baseSelector + CC.SPACE + CC.PERIOD + CC.CLASS.COLLAPSE)
        .each((_: number, element: any) => $(element).collapse(CC.SHOW))

  /**
   * JQuerySelectorで指定したnode配下の
   * 全てのcollapseの折りたたみを閉じる
   *
   * @param baseSelector 操作したい折り畳みの上位nodeのJQuerySelector
   */
  public static hideAllCollapse =
    (baseSelector: string = CC.BLANK): void => {
      const collapseList: any = $(baseSelector
        + CC.SPACE + CC.PERIOD + CC.CLASS.COLLAPSE);
      const shownCollapseList: any = collapseList.filter(CC.PERIOD + CC.SHOW);
      if (shownCollapseList.length > 0) {
        shownCollapseList.each(
          (_: number, element: any) => $(element).collapse(CC.TOGGLE));
      }
    }

  /**
   * JQuerySelectorで指定したnode配下の
   * 全てのcollapseの折りたたみ状態を変更する
   * showがひとつも無ければ全てにshowを付け、
   * showがひとつでも有れば全てのshowを取る
   *
   * @param baseSelector 操作したい折り畳みの上位nodeのJQuerySelector
   */
  public static toggleAllCollapse =
    (baseSelector: string = CC.BLANK): void => {
      const collapseList: any = $(baseSelector
        + CC.SPACE + CC.PERIOD + CC.CLASS.COLLAPSE);
      const shownCollapseList: any = collapseList.filter(CC.PERIOD + CC.SHOW);
      (shownCollapseList.length > 0)
        ? shownCollapseList.each(
          (_: number, element: any) => $(element).collapse(CC.HIDE))
        : collapseList.each(
          (_: number, element: any) => $(element).collapse(CC.SHOW));
    }

  /**
   * JQuerySelectorで指定したnode配下の
   * 全てのnodeのidを配列にして返却
   *
   * @param baseSelector idを取得したい上位nodeのJQuerySelector
   */
  public static getIdList = (baseSelector: string): string[] =>
    $(baseSelector).map((_: number, element: any) => element.id).get()

  /**
   * HTMLタグのidを指定してmodalを開く
   *
   * @param id 開きたいmodalのHTMLタグのid
   */
  public static showModal = (id: string): void =>
    JqueryService.getById(id).modal(CC.SHOW)

  /**
   * HTMLタグのidを指定してmodalを閉じる
   *
   * @param id 閉じたいmodalのHTMLタグのid
   */
  public static hideModal = (id: string): void =>
    JqueryService.getById(id).modal(CC.HIDE)

  /**
   * modalを開いたままの状態で戻る／進むボタンにより<br>
   * 遷移した場合にmodalの残骸が残る不具合への対処。<br>
   * 遷移先のngOnInitへ仕込んでね。
   */
  public static killModal = (): void => {
    if ($('body').hasClass('modal-open')) {
      $('.modal-backdrop').remove();
      $('body').removeClass('modal-open');
      $('body').removeAttr('style');
      $('nav').removeAttr('style');
    }
  }

  public static killBackDrop = (): void => {
    $('.modal-backdrop').remove();
  }

  /**
   * HTMLタグのidを指定してconfirmationを開く
   *
   * @param id 開きたいconfirmationのHTMLタグのid
   * @param param confirmationのオプションオブジェクト
   */
  public static showConfirmation = (id: string, param: any): any =>
    JqueryService.getById(id).confirmation(param).confirmation(CC.SHOW)

  /**
   * HTMLタグのidを指定したcollapseが開き終わった際のコールバックを設定する
   *
   * @param id コールバックを設定したいcollapseのHTMLタグのid
   * @param func コールバックする関数
   */
  public static onCollapseShown = (id: string, func: () => void): void =>
    JqueryService.getById(id).on(CC.EVENT.SHOWN_BS_COLLAPSE,
      event => (event.target.id !== id) || func())

  /**
   * HTMLタグのidを指定したcollapseが開き終わった際のコールバックを削除する
   *
   * @param id コールバックを削除したいcollapseのHTMLタグのid
   */
  public static offCollapseShown = (id: string): void =>
    JqueryService.getById(id).off(CC.EVENT.SHOWN_BS_COLLAPSE)

  /**
   * HTMLタグのidを指定したmodalが閉じ終わった際のコールバックを設定する
   *
   * @param id コールバックを設定したいmodalのHTMLタグのid
   * @param func コールバックする関数
   */
  public static onHiddenModal = (id: string, func: () => void): void =>
    JqueryService.getById(id).on(CC.EVENT.HIDDEN_BS_MODAL, func)

  /**
   * HTMLタグのidを指定したconfirmationが閉じ終わった際のコールバックを設定する
   *
   * @param id コールバックを設定したいconfirmationのHTMLタグのid
   * @param func コールバックする関数
   */
  public static onHiddenConfirmation = (id: string, func: () => void): void =>
    JqueryService.getById(id).on(CC.EVENT.HIDDEN_BS_CONFIRMATION, func)

  /**
   * HTMLタグのidを指定したconfirmationが閉じ終わった際のコールバックを削除する
   *
   * @param id コールバックを削除したいconfirmationのHTMLタグのid
   * @param func コールバックする関数
   */
  public static offHiddenConfirmation = (id: string): void =>
    JqueryService.getById(id).off(CC.EVENT.HIDDEN_BS_CONFIRMATION)

  /**
   * HTMLタグのidを指定してstyle.displayを設定する
   *
   * @param id displayを設定したいHTMLタグのid
   * @param display 設定するstyle.displayの値
   */
  public static setDisplay = (id: string, display: string): void =>
    JqueryService.getById(id).css(CC.CSS.DISPLAY, display)

  /**
   * selectorを指定してiframe内の要素を削除する
   *
   * @param selector iframe内で削除したいselector
   */
  public static removeContentByIframe = (selector: string): void =>
    $('iframe').contents().find(selector).remove()

  /**
   * iframe内の親selectorを指定してnodeを追加する
   *
   * @param selector iframe内でnodeを追加する親selector
   * @param node iframe内に追加するnode
   */
  public static appendNodeByIframe = (selector: string, node: any):void =>
    $('iframe').contents().find(selector).append(node)

  /**
   * iframe内のselectorを指定してstyleを設定する
   *
   * @param selector iframe内でstyleを設定したいselector
   * @param style
   */
  public static setStyleByIframe = (selector: string, styles: any): void =>
    Object.entries(styles).forEach(([key, value]) => {
    $('iframe').contents().find(selector).css(key, value);
  })

  /**
   * HTMLタグのidを指定してstyleを設定する
   *
   * @param id styleを設定したいHTMLタグのid
   * @param key 設定するstyleの名前
   * @param value 設定するstyleの値
   */
  public static setStyle = (id: string, key: string, value: string): void =>
    JqueryService.getById(id).css(key, value)

  /**
   * selectorを指定してstyleを設定する
   * @param selector styleを設定したいselector
   * @param key 設定するstyleの名前
   * @param value 設定するstyleの値
   * @returns 
   */
  public static setStyleBySelector = (selector: string, key: string, value: string): void =>
    JqueryService.getBySelector(selector).css(key, value)

  /**
   * HTMLタグのidを指定してstyleを取得する
   *
   * @param id styleを取得したいHTMLタグのid
   * @param key 取得するstyleの名前
   * @returns 取得したstyleの値
   */
  public static getStyle = (id: string, key: string): string =>
    JqueryService.getById(id).css(key)

  /**
   * HTMLタグのidを指定したセレクトが内包するオプションを取得
   *
   * @param id オプションを取得したいセレクトのHTMLタグのid
   * @returns jqueryObjectArray
   */
  public static getOptions = (id: string): any =>
    JqueryService.getById(id)[0].options

  /**
   * HTMLタグのidを指定したinputのvalueを取得
   *
   * @param id valueを取得したいinputのHTMLタグのid
   * @returns idを指定したinputのvalue
   */
  public static getValue = (id: string): any =>
    JqueryService.getById(id)[0].value

  /**
   * HTMLタグのidを指定したinputのvalueをクリアする
   */
  public static clearValue = (id: string): void =>
    JqueryService.getById(id).val('')

  /**
   * HTMLタグのidを指定したinputのcheckedをfalseにする
   *
   * @param name 選択を解除したいinputのname属性
   */
  public static unCheckedInputByName = (name: string): void =>
    $(`input[name="${name}"]:checked`)
      .each((_: number, element: any) => element.checked = false)

  /**
   * anchor文字列にスクロール、空文字なら先頭
   *
   * @param anchor スクロール先のHTMLタグのid、省略時空文字
   * @param offset スクロール後のオフセット、省略時0
   * @param id スクロールしたいHTMLタグのid、省略時空文字
   * @param duration スクロールスピードmsec、省略時400
   */
  public static scrollToAnchor = (
    anchor: string = CC.BLANK, offset: number = CC.ZERO,
    id: string = CC.BLANK, duration: number = 400
  ): void => {
    let scrollTop: number = 0;
    const layer: any = (id === CC.BLANK)
      ? $('html') : JqueryService.getById(id);
    if (anchor !== CC.BLANK) {
      const target: any = JqueryService.getById(anchor);
      scrollTop = (id === CC.BLANK)
        ? target.offset().top
        : target.position().top + layer.scrollTop();
    }
    scrollTop += offset;
    layer.animate({ scrollTop }, duration);
  }

  /**
   * tagNameのidx番目をjqueryObjectArrayで取得する
   *
   * @param tagName 取得したいHTMLタグのタグ名
   * @param tagIdx 取得したいHTMLタグの出現順
   * @returns jqueryObjectArray
   */
  public static getByTagNameNth =
    (tagName: string, tagIdx: number)
      : any => $($(tagName)[tagIdx - CC.ONE])

  /**
   * tagNameのidx番目にスクロール、空文字なら先頭
   *
   * @param id スクロールしたいHTMLタグのid
   * @param tagName スクロール先のHTMLタグのタグ名、省略時空文字
   * @param tagIdx スクロール先のtagNameの出現順、省略時1番目
   * @param offset スクロール後のオフセット、省略時0
   */
  public static scrollToNthTag = (
    id: string, tagName: string = CC.BLANK,
    tagIdx: number = CC.ONE, offset: number = CC.ZERO
  ): void => {
    const layer: any = JqueryService.getById(id);
    if (tagName !== CC.BLANK) {
      const target: any = JqueryService.getByTagNameNth(tagName, tagIdx);
      layer.scrollTop(target.position().top + layer.scrollTop() + offset);
    } else {
      layer.scrollTop(0);
    }
  }

  /**
   * selectorで示すノードまでのcollapseを全て開き、
   * idで示すHTMLをスクロールする
   *
   * @param id スクロールしたいHTMLタグのid
   * @param selector collapseで隠れている基準nodeまでのselector
   * @param offset スクロール後のオフセット、省略時
   */
  public static openParentsAndScrollTo =
    (selector: string, id: string, offset: number = -160): void => {
      const parentLi: any = $(selector).parent().parent();
      JqueryService.openParent(parentLi);
      const layer: any = JqueryService.getById(id);
      layer.animate({
        scrollTop: parentLi.offset().top + layer.scrollTop() + offset
      });
    }

  public static openParent = (node: any): void => {
    const parentUl: any = node.parent();
    if (!parentUl.hasClass(CC.CLASS.ROOT)
      && parentUl.prop('nodeName').toLowerCase() === CC.HTML.UL) {
      parentUl.collapse(CC.SHOW);
      const parentLi: any = parentUl.parent();
      parentLi.addClass(CC.CLASS.OPEN);
      JqueryService.openParent(parentLi);
    }
  }

  public static setCssVariableVH = (id: string): void => {
    // https://coliss.com/articles/build-websites/operation/css/viewport-units-on-mobile.html
    const vh: number = window.innerHeight * 0.01;
    JqueryService.getById(id)[0].style
      .setProperty(CC.HYPHEN + CC.HYPHEN + CC.VH, vh + CC.PX);
  }

  /**
   * HTML5フォームバリデーションを実行して結果を返却する
   *
   * @returns boolean バリデーション結果
   */
  public static formValidation = (): boolean => {
    const isValid: boolean = JqueryService
      .addClass(CC.ID.FORM.VALIDATION_WRAP, CC.CLASS.WAS_VALIDATED)[0]
      .checkValidity();
    if (!isValid) {
      $('.was-validated .form-control:invalid')[0].focus();
    }
    return isValid;
  }

  /**
   * HTML5フォームバリデーションの結果をクリアする
   */
  public static clearFormValidation = (): void =>
    JqueryService.removeClass(CC.ID.FORM.VALIDATION_WRAP, CC.CLASS.WAS_VALIDATED)

  /**
   * HTMLのidを指定したフォーム部品にフォーカスする
   *
   * @param id フォーカスしたいHTMLタグのid
   */
  public static setFocus = (id: string): void =>
    JqueryService.getById(id)[0].focus()

  /**
   * selectorを指定してfunctionを追加する
   * @param event イベント
   * @param className 取得したいNodeListのselector
   * @param func コールバックする関数
   */
  public static setFunction = (event: string, className: string, func: () => void): void =>
    JqueryService.getBySelector(className).eq(0).on(event, func)

  /**
   * HTMLのidを指定したプルダウンの
   * 「次」「前」の項目を選択してその値を返却する
   *
   * @param id プルダウンのid
   * @param offset 次：1 | 前：-1
   * @returns 選択後のプルダウンの値
   */
  public static pulldownController = (id: string, offset: number): any => {
    const target: any = JqueryService.getOptions(id);
    if (offset === CC.MINUS_ONE && target.selectedIndex > CC.ZERO) {
      target.selectedIndex--;
    } else if (offset === CC.MINUS_ONE && target.selectedIndex <= CC.ZERO) {
      target.selectedIndex = target.length + CC.MINUS_ONE;
    } else if (offset === CC.ONE && target.selectedIndex < target.length + CC.MINUS_ONE) {
      target.selectedIndex++;
    } else if (offset === CC.ONE && target.selectedIndex === target.length + CC.MINUS_ONE) {
      target.selectedIndex = CC.ZERO;
    }
    return target[target.selectedIndex].value;
  }

  // https://github.com/RickStrahl/jquery-resizable
  public static setResizable = (id: string, option: any = null): void =>
    JqueryService.getById(id).resizableSafe(option)

  static readonly sort = (list: any[], key: string, isDesc: boolean = false): any[] =>
    list.sort((a, b) => isDesc
      ? JqueryService.compare(b[key], a[key])
      : JqueryService.compare(a[key], b[key]))

  static readonly sortNested = (list: any[], key1: string, key2: string, isDesc: boolean = false): any[] =>
    list.sort((a, b) => {
      const min = Number.MIN_SAFE_INTEGER.toString();
      const aa = a[key1] ? a[key1][key2] !== CC.BLANK ? a[key1][key2] : min : min;
      const bb = b[key1] ? b[key1][key2] !== CC.BLANK ? b[key1][key2] : min : min;
      return isDesc
        ? JqueryService.compare(bb, aa)
        : JqueryService.compare(aa, bb)
    })

  static readonly compare = (a: any, b: any): number =>
    ($.isNumeric(a) && $.isNumeric(b))
      ? (Number(a) - Number(b))
      : (a > b) ? CC.ONE : (a < b) ? CC.MINUS_ONE : CC.ZERO

  // enterによるsubmitHandler呼出抑止
  static readonly preventEnter = (id: string) => {
    JqueryService.getById(id).keydown(function (event: any) {
      if (event.key === CC.ENTER) {
        if (event.target.tagName !== CC.TEXTAREA) {
          event.preventDefault();
          return false;
        }
      }
    });
  }

  static readonly setIFrameOnLoad =
    (iframeId: string, func: () => void): void =>
      JqueryService.getById(iframeId).on(CC.EVENT.LOAD, func)

  static readonly setIFrameOnClick =
    (iframeId: string, selector: string, func: () => void): void =>
      JqueryService.setIFrameOnLoad(iframeId, async () =>
        JqueryService.getById(iframeId).contents().find(selector).on(CC.EVENT.CLICK, func))


  static readonly setOnMessage = (func: (event) => void): void =>
    $(window).on(CC.EVENT.MESSAGE, func)

  static readonly removeOnMessage = (): void =>
    $(window).off(CC.EVENT.MESSAGE)

  // jquery関係ないけど、文字列全角化
  static readonly han2zen = (target: string): string =>
    target.replace(/[A-Za-z0-9]/g, (s) =>
      String.fromCharCode(s.charCodeAt(0) + 0xFEE0))

  static readonly getRandomInt = (min: number, maxInclusive: number):
    number => Math.floor(Math.random() * (maxInclusive - min + 1)) + min

  static readonly setTextareaAutoResize = (id: string): void =>
    $('#' + id).on('input', function (e) {
      JqueryService.textareaResize(id);
    });

  static readonly textareaResize = (id: any): void => {
    const target = $('#' + id);
    const lineHeight = parseInt($(target).css('lineHeight'));
    if ($(target).get(0).scrollHeight > $(target).get(0).offsetHeight) {
      // CASE1: スクロールする高さが対象セレクタの高さよりも大きい場合 ※スクロール表示される場合
      // スクロールする高さをheightに指定
      $(target).height($(target).get(0).scrollHeight - lineHeight / 2);
    } else {
      // CASE2: スクロールする高さが対象セレクタの高さよりも小さい場合
      // lineHeight値を数値で取得
      while (true) {
        // lineHeightずつheightを小さくする
        $(target).height($(target).height() - lineHeight);
        // スクロールする高さが対象セレクタの高さより大きくなるまで繰り返す
        if ($(target).get(0).scrollHeight > $(target).get(0).offsetHeight) {
          $(target).height($(target).get(0).scrollHeight - lineHeight / 2);
          break;
        }
      }
    }
  };
}
