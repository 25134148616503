<div id="bookingModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{title}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form id="validationWrap" name="validationWrap" class="needs-validation">
          <div *ngIf="isBooked">
            <div class="input-group">
              <span class="input-group-text mr-3">予約内容</span>
              <label class="col-form-label text-left text-selectable">{{bookingData.original.display}}</label>
            </div>
            <hr>
          </div>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">予約日付</span>
            </div>
            <input name="bookingDate" class="form-control text-center" type="date" required min="{{dateMin}}"
              max="{{dateMax}}" step="1" [(ngModel)]="bookingData.date" (change)="bookingDateChanged()">
            <div class="input-group-append">
              <span class="input-group-text">{{bookingDateWeekDay}}</span>
            </div>
          </div>
          <div class="input-group mt-2">
            <div class="input-group-prepend">
              <span class="input-group-text">開始時刻</span>
            </div>
            <select id="bookingStart" name="bookingStart" class="form-control" required [(ngModel)]="bookingData.start"
              (change)="bookingTimeChanged(false)">
              <option *ngFor="let timeSlot of timeSlotStart" value="{{timeSlot}}">{{timeSlot}}</option>
            </select>
            <div class="input-group-append btn-group" role="group">
              <button type="button" class="btn btn-outline-success" (click)="bookingTimeChanged(false,-1)">▲</button>
              <button type="button" class="btn btn-outline-success" (click)="bookingTimeChanged(false,1)">▼</button>
            </div>
          </div>
          <div class="input-group mt-2">
            <div class="input-group-prepend">
              <span class="input-group-text">終了時刻</span>
            </div>
            <select id="bookingEnd" name="bookingEnd" class="form-control" required [(ngModel)]="bookingData.end"
              (change)="bookingTimeChanged(true)">
              <option *ngFor="let timeSlot of timeSlotEnd" value="{{timeSlot}}">{{timeSlot}}</option>
            </select>
            <div class="input-group-append btn-group" role="group">
              <button type="button" class="btn btn-outline-success" (click)="bookingTimeChanged(true,-1)">▲</button>
              <button type="button" class="btn btn-outline-success" (click)="bookingTimeChanged(true,1)">▼</button>
            </div>
          </div>
        </form>
        <div class="notice">{{notice}}</div>
      </div>
      <div class="modal-footer">
        <button data-toggle="confirmation" id="confirmCancel" type="button" class="btn btn-outline-danger"
          (click)="bookingCancel()" *ngIf="isBooked">予約取消</button>
        <button data-dismiss="modal" type="button" class="btn btn-outline-secondary">閉じる</button>
        <button data-toggle="confirmation" id="confirmSave" type="button" class="btn btn-outline-success"
          (click)="bookingSave()">{{saveBtnTitle}}</button>
      </div>
    </div>
  </div>
</div>
