<!-- テンプレート -->
<ng-template #liTemplate let-items>
  <ng-container *ngFor="let item of items">
    <li *ngIf="item.url === ''" [class]="liparent">
      <span (click)="toggle($event)" [ngClass]="[span, 'depth_' + item.depth]">{{item.title}}
        <i [class]="iconToggle"></i></span>
      <ul *ngIf="item.childs.length > 0" [class]="ul">
        <ng-container *ngTemplateOutlet="liTemplate; context: {$implicit: item.childs}"></ng-container>
      </ul>
    </li>
    <li *ngIf="item.url !== ''" [class]="li">
      <span (click)="open(item)" [ngClass]="[span, 'depth_' + item.depth]">{{item.title}}
        <i [class]="iconClass(item.url)" [class.current]="item.url === currentUrl"></i></span>
    </li>
  </ng-container>
</ng-template>
<!-- テンプレート -->

<div class="course contents_wrap d-flex" id="course">
  <div class="split_handle" (tap)="toggleMenu()"><i class="fas fa-bars"></i></div>
  <div class="menu" id="course_menu">
    <div class="menu_inner">
      <ul class="controll">
        <li><a [class]="ta" (click)="toggleAll()">全開閉</a></li>
      </ul>
      <div id="statusArea">
        <div
          *ngIf="isTrial || (user.planCode === pc.light_20210901 && isExpired) || (user.planCode === pc.free_20210901 && (isExpired || !user.expiration))"
          id="lockStatusArea"><i [class]="iconLock"></i></div>
        <div id="statusDisplay">進捗率 {{progressRate}}%</div>
        <div id="statusBar">
          <div id="statusBarCompleted"></div>
          <div id="statusBarOngoing"></div>
          <div id="statusBarNone"></div>
        </div>
      </div>
      <ul [class]="ulroot">
        <ng-container *ngTemplateOutlet="liTemplate; context: {$implicit: courseIndexNested$ | async}"></ng-container>
      </ul>
    </div>
  </div>
  <div class="display">
    <iframe id="display_iframe" frameborder="0"></iframe>
  </div>
</div>

<div id="billingGuidePanel" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-inner">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-title-block">
          <h1>このページを学習するには<br><span>有料プラン</span>へのアップグレードが必要です</h1>
        </div>
        <div class="modal-list-block">
          <div>
            <img src="../../../assets/img/banner_all_curriculum.png" alt="">
            <p>有料プランは 1,100円 / 30日 のサブスクリプションです。<br>
              有効期間中は<span>全コース全ページの学習が可能</span>です。<br>
              初回ご購入時に下記の質問フォームとオンライン授業の<br class="over420">
              <span>お試し利用券が１回ずつもらえます</span>！
            </p>
          </div>
          <div>
            <img src="../../../assets/img/banner_question.png" alt="">
            <p>有料プランでは 330円 / 1回 で質問フォームから<br class="over420">質問を送ることができ、最短で1時間以内に<br
                class="over420">現役エンジニアから回答をもらえます！<br>
              <span class="annotation">※ 平日11~19時以外は翌営業日回答となります</span>
            </p>
          </div>
          <div>
            <img src="../../../assets/img/banner_online_lesson.png" alt="">
            <p>有料プランでは 3,300円 / 1回で<span><br class="over420">
                現役エンジニアにオンラインでたっぷり1時間、<br class="over420">相談や質問</span>をすることができます！<br>
              <span class="annotation">※ 平日11~19時の間で予約が必要です（直前でもOK！）</span>
            </p>
          </div>
        </div>
        <div class="modal-text-block">
          <p><span>次の画面でメールアドレスをご登録いただき、</span><span>メンバーサイトへログインしてから、</span><br
              class="over420"><span>セキュリティが確保されたご購入画面にて</span>カード情報をご入力いただきます。</p>
        </div>
        <div class="modal-button-block">
          <button data-dismiss="modal" (click)="planCode === pc.none ? showSignupPanel() : navigate()"
            class="btn upgrade-button">有料プランにアップグレード</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="sharePanel" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-inner">
        <div class="share-button-container">
          <div class="share-button share-button-linkcopy">
            <span class="btn-linkcopy" id="btn-linkcopy" (click)="copy()"></span>
          </div>
          <div class="share-button share-button-twitter">
            <a id="btn-twitter" href="" target="_blank"
              rel="nofollow noopener noreferer"></a>
          </div>
          <div class="share-button share-button-facebook">
            <a id="btn-facebook" href="" target="_blank" rel="nofollow noopener noreferer"></a>
          </div>
          <div class="share-button share-button-line">
            <div class="inner"></div>
            <a id="btn-line" href="" target="_blank" rel="nofollow noopener noreferer"></a>
          </div>
          <div class="share-button share-button-hatena">
            <a id="btn-hatena" href="" target="_blank" rel="nofollow noopener noreferer"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-trial-signup></app-trial-signup>