import { environment } from '../../environments/environment';
import moment from 'moment';
import { AttendanceStatus } from '../models/class/Attendance';

export enum BookingCalenderControllMode {
  today,
  next,
  prev,
  reload,
  force,
  date
}

export class BookingConstants {

  static readonly ROOM = {
    URL: {
      GET: (roomId: string) =>
        BookingConstants.ROOM.URL.PARTS.PREFIX
        + roomId
        + BookingConstants.ROOM.URL.PARTS.POSTFIX,
      PARTS: {
        PREFIX: 'https://whereby.com/',
        POSTFIX: '/',
      },
    },
  };

  static readonly DATE = {
    PERIOD: 7,
    UNIT: 1,
    REGULAR_CLOSING: {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: true,
      7: true,
    },
  };

  static TIME = {
    START: environment.open,
    END: environment.close,
    UNIT: 60,
  };

  static readonly LIMIT = {
    TIME_SLOTS_PER_BLOCK_MIN: 1,
    TIME_SLOTS_PER_BLOCK_MAX: 1,
    BLOCKS_PER_DAY_MAX: 2,
    TIME_SLOTS_PER_DAY_MAX: 2,
  };

  static readonly TEXT = {
    BLANK_WEEK_DAY: '　　　',
    TIME_SLOT: '時間枠',
    TIME_SLOT_DELIM: '-',
    CLOSE: '休講',
    REGULAR_CLOSE: '定休日',
    BOOKABLE: '予約可',
    NOT_BOOKABLE: '予約不可',
    COUNT_NOT_REMAIN: '残回数無',
    LOCKED: '未開放',
    FULL: '満枠',
    BOOKED: '予約済',
    NONATTENDANCE: '未実施',
    ATTENDANCE: '実施',
    ABSENCE: '無連絡',
    CANCEL: '取消',
    OPEN_MODAL: '予約内容照会／変更／取消・授業開始',
  };

  static readonly CLASS = {
    CELL: {
      TH: {
        NORMAL: 'bt_th',
        TODAY: 'bt_th bt_today',
        SAT: 'bt_th bt_sat',
        SUN: 'bt_th bt_sun',
      },
      TD: {
        NORMAL: 'bt_td',
        CLOSE: ' bt_close',
        CLOSE_MUTED: ' bt_close text-muted',
        EXPIRED_MUTED: ' bt_expired text-muted',
        EMPTY: ' bt_empty',
        FULL: ' bt_full',
        BOOKED: ' bt_booked',
        ROWS_PREFIX: ' bt_rows_',
        ATTENDANCE: ' bt_attendance',
        ABSENCE: ' bt_absence',
        NOW: ' bt_now',
      },
    },
    COLUMN: {
      TIME_SLOT_PC: 'd-none d-md-block flex-grow-1',
      TIME_SLOT_SP: 'd-md-none flex-grow-0',
      DAY: 'flex-grow-1',
    },
  };

  static readonly MODAL = {
    TITLE: {
      INPUT: '新規授業予約',
      UPDATE: '授業予約内容照会・変更',
    },
    BUTTON: {
      INPUT: '新規予約',
      UPDATE: '予約内容変更',
    },
    MESSAGE: {
      CONFIRM: {
        SAVE: '<br>この日時で予約しますか？',
        UPDATE: '<br>この日時に変更しますか？',
        CANCEL: '<br>この予約を取り消しますか？',
      },
    },
  };

  static readonly CACHE = {
    EXPIRAION: {
      AMOUNT: 30,
      FORMAT: 's' as moment.unitOfTime.DurationConstructor,
    },
  };

  static readonly RELOAD = {
    INTERVAL: {
      AMOUNT: 2,
      FORMAT: 's' as moment.unitOfTime.DurationConstructor,
    },
  };

  static readonly UNLOCK = {
    DURATION: {
      AMOUNT: 35,
      FORMAT: 'd' as moment.unitOfTime.DurationConstructor,
    },
  };

  static readonly ATTENDANCE_STATUS = {
    [AttendanceStatus.none]: '未',
    [AttendanceStatus.start]: '中',
    [AttendanceStatus.wait]: '待',
    [AttendanceStatus.end]: '済',
  };

  static readonly TITLE = {
    SUCCESS: {
      SAVE: '新規予約が完了しました',
      UPDATE: '予約内容を変更しました',
      CANCEL: '予約を取り消しました',
    },
    FAIL: {
      GETDATA: 'データ取得に失敗しました',
      SAVE: '新規予約に失敗しました',
      UPDATE: '予約内容変更に失敗しました',
      CANCEL: '予約取消に失敗しました',
      DATE_VALIDATION: '予約日付を正しく入力してください',
    },
  };

  static readonly MESSAGE = {
    INFO: {
      UPDATE_BEFORE: '【変更前】<br>',
      UPDATE_AFTER: '<br>【変更後】<br>',
      BOOKABLE: 'ご予約が可能です。',
      OPEN_MODAL: 'ご予約内容の照会／変更／取消が可能です。',
      PLEASE_START: 'ご予約のお時間です。' +
        '<br>ダッシュボードのオンライン授業予約状況から' +
        '<br>授業を開始してください。'
    },
    ERROR: {
      PLEASE_RETRY: '再度実行してください。',
      IS_FULL: '満枠です。',
      IS_EXPIRED: 'オンライン授業可能なプランの有効期間外です。' +
        '<br>ご予約は可能ですが、授業を開始する時点において' +
        '<br>授業可能なプランの有効期間内である必要があります。'+
        '<br>プラン購入／管理画面から自動更新を再開してください。',
      IS_PAST: '過去の日時です。',
      IS_FUTURE:
        BookingConstants.UNLOCK.DURATION.AMOUNT
        + '日より先の日付ではご予約できません。',
      IS_ONLINE_COUNT_NOT_REMAIN: 'オンライン授業予約回数が残っていません。' +
        '<br>プラン購入／管理画面から追加の授業予約をご購入いただくか、' +
        '<br>無料プランの方は授業可能なプランをご購入ください。',
      IS_PLAN_NOT_BOOKABLE: '現在のプランではオンライン授業は利用できません。' +
        '<br>プラン購入／管理画面から授業可能なプランをご購入ください。',
      IS_CLOSED: 'ご予約日時に休講日／休講時間帯が含まれています。',
      IS_LIMIT_OVER:
        'ご予約は１時間ずつ、１日２件まで可能です。',
      DATE_INVALID:
        'ご予約は現在から'
        + BookingConstants.UNLOCK.DURATION.AMOUNT
        + '日後まで可能です。',
    },
  };
}
