import { DocumentData, DocumentSnapshot } from '@angular/fire/firestore';
import { CommonConstants as CC } from 'src/app/constants/common.constants';
import * as PC from 'src/app/constants/plan.constants';
import { FirestoreService } from 'src/app/utilities/injectable/firestore.service';
import { MomentService } from 'src/app/utilities/static/moment.service';
import { Attendance } from './Attendance';

export class User {
  uid?: string = CC.BLANK;
  email?: string = CC.BLANK;
  password?: string = CC.BLANK;
  birthdate?: string = CC.BLANK;
  name?: string = CC.BLANK;
  planCode?: PC.PLANCODE_EXT = PC.PLAN_EXT.none;
  courseListType?: string = CC.BLANK;
  courseListVersion?: string = CC.BLANK;
  admission?: Date = null;
  purchase?: Date = null;
  expiration?: Date = null;
  questionCount?: number = CC.ZERO;
  onlineCount?: number = CC.ZERO;
  memo?: string = CC.BLANK;
  isTemporaryPassword?: boolean = false;
  navigateTo?: string = CC.BLANK;
  a8?: string = CC.BLANK;
  fmcs?: string = CC.BLANK;
  gaw?: boolean = false;
  rentracksSend?: Date = null;

  stripeSubscription?: {
    customer?: string;
    subscription?: string;
    number?: string;
    status?: string;
    cancel_at_period_end?: boolean;
  };

  oldEmail?: string = CC.BLANK;
  oldPassword?: string = CC.BLANK;
  passwordConfirm?: string = CC.BLANK;

  key?: string = CC.BLANK;
  roomId?: string = CC.BLANK;
  attendance?: Attendance = null;

  isAdmin: boolean = false;
  isMasquerade: boolean = false;
  isEmailVerified?: boolean = false;

  rawdata?: DocumentData = null;

  constructor(partial?: Partial<User>) {
    Object.assign(this, partial);
  }

  getAdditionalUserInfoForRegister = () => ({
    name: this.name,
    email: this.email,
    birthdate: this.birthdate,
    planCode: this.planCode,
    courseListType: this.courseListType,
    courseListVersion: this.courseListVersion,
    admission: FirestoreService.getServerTimestamp(),
    purchase: this.purchase,
    expiration: this.expiration,
    questionCount: this.questionCount,
    onlineCount: this.onlineCount,
    memo: this.memo,
    isTemporaryPassword: this.isTemporaryPassword,
    navigateTo: this.navigateTo,
  })

  getAdditionalUserInfoForEdit = () => ({
    uid: this.uid,
    email: this.email,
    name: this.name,
    birthdate: this.birthdate,
    courseListType: this.courseListType,
    stripeSubscription: this.stripeSubscription,
  })

  getAdditionalUserInfoForUpdateInfo = () => ({
    name: this.name,
    birthdate: this.birthdate,
    courseListType: this.courseListType,
  })

  getAdditionalUserInfoForUpdateEmail = () => ({
    email: this.email
  })

  getAdditionalUserInfoForUpdatePassword = () => ({
    isTemporaryPassword: false
  })

  getAdditionalUserInfoForUpdateNavigateTo = () => ({
    navigateTo: CC.BLANK
  })

  setAdditionalUserInfo = (doc: DocumentSnapshot) => {
    this.uid = this.uid || doc.id;
    this.email = this.email || doc.get('email');
    this.name = doc.get('name');
    this.birthdate = doc.get('birthdate');
    this.planCode = doc.get('planCode');
    this.courseListType = doc.get('courseListType');
    this.courseListVersion = doc.get('courseListVersion');
    this.admission = doc.get('admission')
      ? doc.get('admission').toDate() : null;
    this.purchase = doc.get('purchase')
      ? doc.get('purchase').toDate() : null;
    this.expiration = doc.get('expiration')
      ? doc.get('expiration').toDate() : null;
    this.questionCount = doc.get('questionCount');
    this.onlineCount = doc.get('onlineCount');
    this.memo = doc.get('memo');
    this.isTemporaryPassword = doc.get('isTemporaryPassword');
    this.navigateTo = doc.get('navigateTo');
    this.a8 = doc.get('a8');
    this.fmcs = doc.get('fmcs');
    this.gaw = doc.get('gaw');
    this.rentracksSend = doc.get('rentracksSend')
      ? doc.get('rentracksSend').toDate() : null;
    this.stripeSubscription = doc.get('stripeSubscription');
    this.rawdata = doc.data();
  }

  getAdmissionDateString = ():
    string => MomentService.getDateFromDateObject(this.admission)

  getPurchaseDateString = ():
    string => MomentService.getDateFromDateObject(this.purchase)

  getExpirationDateString = ():
    string => MomentService.getDateFromDateObject(this.expiration)

  getAdmissionDateTimeString = ():
    string => MomentService.getDateTimeFromDateObject(this.admission)

  getPurchaseDateTimeString = ():
    string => MomentService.getDateTimeFromDateObject(this.purchase)

  getExpirationDateTimeString = ():
    string => MomentService.getDateTimeFromDateObject(this.expiration)

  getAttendance = (key: string):
    Attendance => new Attendance(this.rawdata[key])
}
