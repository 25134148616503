import { Injectable } from '@angular/core';
import { DocumentReference, DocumentSnapshot } from '@angular/fire/firestore';
import { QuerySnapshot } from 'firebase/firestore';
import { CommonConstants as CC } from 'src/app/constants/common.constants';
import { User } from 'src/app/models/class/User';
import { Course } from 'src/app/models/interface/Course';
import { CourseIndex, CourseIndexNested } from 'src/app/models/interface/CourseIndex';
import { ChapterStatus, CourseStatus } from 'src/app/models/interface/CourseStatus';
import { FirestoreService } from 'src/app/utilities/injectable/firestore.service';
import { LoadingService } from 'src/app/utilities/static/loading.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  constructor(
    private firestoreService: FirestoreService
  ) { }

  /**
   * コースリストを取得する
   *
   * @param user 取得対象のユーザー情報
   * @returns コースリストの配列
   */
  public getCourseList = async (user: User):
    Promise<Course[]> => {
    LoadingService.on();
    const courseList: Course[] = [];
    const courseListType: string = user.courseListType;
    const courseListVersion: string = user.courseListVersion;
    await this.firestoreService.getDocument(
      [CC.COLLECTION.KEY.COURSE_LIST, courseListType,
      CC.COLLECTION.KEY.VERSIONS, courseListVersion])
      .then(doc => {
        doc.get(CC.DOCUMENT.KEY.COURSE_LIST)
          .forEach((course: Course, index: any) =>
            courseList.push({ index, ...course }));
      });
    LoadingService.off();
    return courseList;
  }

  /**
   * コースリストを取得する
   *
   * @returns コースリストの配列
   */
  public getTrialCourseList = async ():
    Promise<Course[]> => {
    LoadingService.on();
    const courseList: Course[] = [];
    const courseListType: string = environment.trial.courseListType;
    const courseListVersion: string = environment.trial.courseListVersion;
    await this.firestoreService.getDocument(
      [CC.COLLECTION.KEY.COURSE_LIST, courseListType,
      CC.COLLECTION.KEY.VERSIONS, courseListVersion])
      .then(doc => {
        doc.get(CC.DOCUMENT.KEY.COURSE_LIST)
          .forEach((course: Course, index: any) =>
            courseList.push({ index, ...course }));
      });
    LoadingService.off();
    return courseList;
  }

  /**
   * コース目次を取得する
   *
   * @param course 取得対象のコース
   * @returns コース目次の配列
   */
  public getCourseIndex = async (course: string):
    Promise<CourseIndex[]> => {
    const courseIndex: CourseIndex[] = [];
    await this.firestoreService.getDocument(
      [CC.COLLECTION.KEY.COURSE_INDEX, course])
      .then(doc => {
        doc.get(CC.DOCUMENT.KEY.COURSE_INDEX)
          ?.forEach((index: CourseIndex) =>
            courseIndex.push(index));
      });
    return courseIndex;
  }

  /**
   * コース目次をCourseIndexNestedの配列に変換して返却する
   *
   * @param courseIndex コース目次の配列
   * @returns CourseIndexNestedの配列
   */
  public getCourseIndexNested = async (courseIndex: CourseIndex[]):
    Promise<CourseIndexNested[]> => {
    const courseIndexNested: CourseIndexNested[] =
      this.makeCourseIndexNested(courseIndex, 0, 1).list;
    return courseIndexNested;
  }

  private makeCourseIndexNested = (courseIndexArray: CourseIndex[],
    currentIndex: number, currentDepth: number)
    : { list: CourseIndexNested[], lastIndex: number } => {
    const list: CourseIndexNested[] = [];
    while (currentIndex < courseIndexArray.length) {
      const courseIndex: CourseIndex = courseIndexArray[currentIndex];
      if (courseIndex.depth !== currentDepth) {
        break;
      }
      const result: { list: CourseIndexNested[], lastIndex: number }
        = (courseIndex.url !== CC.BLANK)
          ? { list: [], lastIndex: currentIndex }
          : this.makeCourseIndexNested(
            courseIndexArray, currentIndex + 1, courseIndex.depth + 1);
      list[list.length] = { ...courseIndex, childs: result.list };
      currentIndex = result.lastIndex;
      currentIndex++;
    }
    return { list, lastIndex: currentIndex - 1 };
  }

  private firestoreGetCourseStatus = async (user: User, course: string):
    Promise<CourseStatus> => {
    let map: CourseStatus = {};
    const userdata: DocumentSnapshot =
      await this.firestoreService.getDocument(
        [CC.COLLECTION.KEY.COURSE_STATUS, user.uid]);
    if (userdata.exists() && userdata.get(course)) {
      map = userdata.get(course);
    }
    return map;
  }

  public firestoreSaveCourseStatus =
    async (user: User, course: string, key: string, status: ChapterStatus):
      Promise<CourseStatus> => {
      LoadingService.on();
      const map: CourseStatus =
        await this.firestoreGetCourseStatus(user, course);
      map[key] = status;
      await this.firestoreService.setDocument(
        [CC.COLLECTION.KEY.COURSE_STATUS, user.uid],
        { [course]: { ...map } }
      );
      LoadingService.off();
      return map;
    }

  public getCourseStatus = async (user: User, course: string):
    Promise<CourseStatus> => {
    const map: CourseStatus =
      await this.firestoreGetCourseStatus(user, course);
    return map;
  }

  public createDocId = async (): Promise<DocumentReference> => {
    const doc: DocumentReference = this.firestoreService.getNewDocumentReference('share');
    return doc;
  }

  public docIdCheck = async (docId: string): Promise<boolean> => {
    const ref: QuerySnapshot = await this.firestoreService.searchDocumentWithForwardMatches(
      [CC.COLLECTION.KEY.SHARE], [],
      [{ field: CC.DOCUMENT.KEY.UID, value: docId }]
    );
    return ref.empty;
  }

  public firestoreSaveShare =
    async (docId: string, userUid: string, title: string, description: string, imageLink: string, url: string):
      Promise<void> => {
      const data = {
        uid: docId,
        userUid: userUid || null,
        url: url,
        title: title,
        description: description,
        imageLink: imageLink,
        sharedAt: FirestoreService.getServerTimestamp(),
      }
      this.firestoreService.setDocument([CC.COLLECTION.KEY.SHARE, docId], data);
    }
}
