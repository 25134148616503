export class AdminConstants {

  static readonly TITLE = {
    SUCCESS: {
      SAVE_HOLIDAYS: '休日情報設定成功',
      SAVE_TEMPORARY_CLOSURES: '臨時休講情報設定成功',
      SAVE_COURSE_LIST: 'コースリスト設定成功',
      SAVE_COURSE_INDEX: 'コース目次設定成功',
      SAVE_ROOMS: 'シフト情報設定成功',
      SAVE_INFORMATION: 'お知らせ設定成功',
    },
    FAIL: {
      GETDATA: 'データ取得失敗',
      SAVE_HOLIDAYS: '休日情報設定失敗',
      SAVE_TEMPORARY_CLOSURES: '臨時休講情報設定失敗',
      SAVE_COURSE_LIST: 'コースリスト設定失敗',
      SAVE_COURSE_INDEX: 'コース目次設定失敗',
      SAVE_ROOMS: 'シフト情報設定失敗',
      SAVE_INFORMATION: 'お知らせ設定失敗',
    },
  };

  static readonly MESSAGE = {
    INFO: {
      COUNT: ' 件',
    },
    ERROR: {
      PLEASE_RETRY: '接続確認後に再施行すること。'
    },
  };

  static readonly MASTER = {
    MAINTENANCE: {
      START: '2019',
      END: '2030',
    },
  };
}
