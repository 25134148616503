import { Injectable } from '@angular/core';
import { getDownloadURL, ref, Storage, uploadBytes, UploadResult } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class FirestorageService {

  constructor(
    private firestorage: Storage
  ) { }

  public saveScreenShots = async (screenShots: File[], screenShotsFilePaths: string[]):
    Promise<string[]> => {
    const uploadPromises: Promise<UploadResult>[] = screenShots.map((screenShot, index) =>
      uploadBytes(ref(this.firestorage, screenShotsFilePaths[index]), screenShot));
    const uploadResults: UploadResult[] = await Promise.all(uploadPromises);
    const getDownloadURLPromises: Promise<string>[] =
      uploadResults.map(uploadResult => getDownloadURL(uploadResult.ref));
    const urls: string[] = await Promise.all(getDownloadURLPromises);
    return urls;
  }

}
