import { Injectable } from '@angular/core';
import { Functions, httpsCallable, HttpsCallableResult } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class FirefuncService {

  constructor(
    private firefunctions: Functions
  ) { }

  public call = async (name: string, data: any): Promise<HttpsCallableResult> =>
    await httpsCallable(this.firefunctions, name)(data);
}
