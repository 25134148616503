import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { JqueryService } from 'src/app/utilities/static/jquery.service';

import { CommonConstants as CC } from 'src/app/constants/common.constants';
import { AuthConstants as AC } from 'src/app/constants/auth.constants';
import { User } from 'src/app/models/class/User';
import { Option } from 'src/app/models/interface/Option';
import { LoadingService } from 'src/app/utilities/static/loading.service';

@Component({
    selector: 'app-trial-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.sass']
  })

  // 自動ログインの追加

  // authService の signup を参考に（改良？）ユーザーの新規登録後にログインを行い、navigateToに
  export class SignupComponent implements OnInit {
    public patternEmail: string = AC.PATTERN.EMAIL;
    public patternPassword: string = AC.PATTERN.PASSWORD;
    public courseListTypeList: Option[] = CC.COURSE_LIST_TYPE.PULLDOWN;
  
    public user: User;

    constructor(
        private authService: AuthService
    ) { }

    ngOnInit() {
    this.user = new User();
    this.user.courseListType = this.courseListTypeList[0].value + CC.BLANK;
    this.authService.setupPasswordConfirmValidation();
    JqueryService.setUpToolTip();
    // JqueryService.preventEnter();
    }

    showPanel() {
    JqueryService.showModal('trialSignupPanel');
    }

    signup() {
        if (this.validation()) {
            JqueryService.killModal();
            this.authService.trialSignup(this.user);
        }
    }

    validation(): boolean {
        return JqueryService.formValidation();
    }
  }  