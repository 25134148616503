<div class="dashboard container contents_wrap">
  <div class="row justify-content-center mb-4">
    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-9">
      <div class="row justify-content-center mb-4">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="card">
            <div class="card-header"><span class="card-toggler" data-toggle="collapse" data-target="#info"
                aria-controls="info" aria-expanded="false"><i
                  class="fas fa-caret-up nav white rt180 mr-1"></i>お知らせ</span>
            </div>
            <div class="card-body-wrap collapse" id="info">
              <div class="card-body">
                <div *ngFor="let item of infoListData" class="alert {{item.class}}" role="alert"
                  [innerHTML]="item.text | safe">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center mb-4">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between"><span class="card-toggler" data-toggle="collapse"
                data-target="#courseList" aria-controls="courseList" aria-expanded="false"><i
                  class="fas fa-caret-up nav white rt180 mr-1"></i>コース一覧</span>
            </div>
            <div class="card-body-wrap collapse" id="courseList">
              <div class="card-body row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4 row course-list">

                  <div class="course-list-wrapper col-4">
                    <input type="radio" class="btn-check mr-1" name="options" id="allCourseList" autocomplete="off" (click)="sort(allCourseList)" [checked]="cookieCourseList === allCourseList">
                     <label class="card clickable center-block" for="allCourseList">
                      <div class="course-list-img">
                        <img src="../../../assets/img/course_list_all.png" alt="">
                        <div class="overlay"><div>全て<span>のコースを表示する</span></div></div>
                      </div>
                    </label>
                  </div>
          
                  <div class="course-list-wrapper col-4">
                    <input type="radio" class="btn-check mr-1" name="options" id="phpCourseList" autocomplete="off" (click)="sort(phpCourseList)" [checked]="cookieCourseList === phpCourseList">
                    <label class="card clickable center-block" for="phpCourseList">
                      <div class="course-list-img">
                        <img src="../../../assets/img/course_list_php.png" alt="">
                        <div class="overlay"><div>PHP<span>のコースを表示する</span></div></div>
                      </div>
                    </label>
                  </div>
                  
                  <div class="course-list-wrapper col-4">
                    <input type="radio" class="btn-check mr-1" name="options" id="javaCourseList" autocomplete="off" (click)="sort(javaCourseList)" [checked]="cookieCourseList === javaCourseList">
                    <label class="card clickable center-block" for="javaCourseList">
                      <div class="course-list-img">
                        <img src="../../../assets/img/course_list_java.png" alt="">
                        <div class="overlay"><div>Java<span>のコースを表示する</span></div></div>
                      </div>
                    </label>
                  </div>
  
                </div>
                <ng-container *ngFor="let item of courseListData; let itemCount = count;index as index">
                  <div class="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 course" [class.mb-4]="item.index < itemCount - 1" [id]="item.key" [style.display]="initSort(item.key) ? 'block': 'none'" [attr.data-number]="initNumber(item.key)" [attr.data-number-show]="initNumberIsShow(item.key)">

                    <div
                      class="card clickable course-card" [routerLink]="item.contents" (click)="dummyLoading()">
                      <div class="card-img-top">
                        <img [src]="'/assets/img/course_' + item.key + '.png'" [alt]="item.text">
                        <div class="overlay"></div>
                      </div>
                      <div class="card-body">
                        <h5 class="card-title">{{item.text}}</h5>
                        <p class="card-text">{{item.description}}</p>
                      </div>
                    </div>

                    <!-- <div
                      *ngIf="!(user.isAdmin || (planCode === pc.trainee)  || (planCode === pc.free_20210901 && !isExpired && item.isFree) || (planStatus !== ps.canceled))"
                      class="card" title="現在のプランではご利用いただけません">
                      <div class="card-img-top">
                        <img [src]="'/assets/img/course_' + item.key + '.png'" [alt]="item.text">
                        <div class="overlay locked d-flex justify-content-center align-items-center">
                          <i class="fas fa-lock white"></i>
                        </div>
                      </div>
                      <div class="card-body">
                        <h5 class="card-title">{{item.text}}</h5>
                        <p class="card-text">{{item.description}}</p>
                        <span class="btn btn-sm btn-success mr-2 mb-2"
                          (click)="showCourseInfoPanel(item.text, item.key)" title="このコースの目次を参照できます">学習内容を見る</span>
                        <span class="btn btn-sm btn-warning mb-2" [routerLink]="urlPlan"
                          title="プラン購入／管理画面へ遷移します">プラン購入でアンロック！</span>
                      </div>
                    </div> -->

                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3">
      <div class="row justify-content-center">

        <div class="col-12 col-sm-12 col-md-6 col-lg-12 col-xl-12 mb-4">
          <div class="card">
            <div class="card-header"><span class="card-toggler" data-toggle="collapse" data-target="#menu"
                aria-controls="menu" aria-expanded="false"><i
                  class="fas fa-caret-up nav white rt180 mr-1"></i>メニュー</span>
            </div>
            <div class="card-body-wrap collapse" id="menu">
              <div class="card-body d-flex flex-wrap flex-column">

                <div class="row remain-number">
                  <div class="col-md-6 d-flex flex-column">
                    <div class="remain-number-text text-black-50">質問フォーム残回数</div>
                    <div class=" input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-xs fa-ticket-alt rt135 white"></i></span>
                      </div>
                      <div id="uid" name="uid" type="text" class="form-control text-center dimgray font-weight-bold">{{planCode === pc.light_20210901 || (planCode === pc.free_20210901 && !isExpired && user.expiration) ? user.questionCount : 'ー'}}</div>
                    </div>
                  </div>

                  <div class="col-md-6 d-flex flex-column">
                    <div class="remain-number-text text-black-50">オンライン授業残回数</div>
                    <div class=" input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-headset white"></i></span>
                      </div>
                      <div id="uid" name="uid" type="text" class="form-control text-center dimgray font-weight-bold">{{planCode === pc.light_20210901 || (planCode === pc.free_20210901 && !isExpired && user.expiration) ? user.onlineCount : 'ー'}}</div>
                    </div>
                  </div>
                </div>

                <hr>

                <!-- <span class="small text-monospace text-muted" title="PCの状態やログインからの時間経過により正確でない場合があります。">{{clock}}</span> -->

                <ng-container *ngIf="!(planCode === pc.trainee)">
                  <ng-container
                    *ngIf="showAllBadges || (planCode === pc.light_20210901 && planStatus !== ps.canceled) || (planCode === pc.free_20210901 && !isExpired && user.expiration)">
                    <span class="badge badge-success mb-1">{{planName}}</span>
                  </ng-container>

                  <ng-container *ngIf="showAllBadges || (planCode === pc.free_20210901 && !isExpired && user.expiration)">
                    <span class="badge badge-success mb-1">{{planExpiration}} まで</span>
                  </ng-container>

                  <ng-container
                    *ngIf="showAllBadges || (planStatus === ps.active && !isExpired && !cancel_at_period_end)">
                    <span class="badge badge-success mb-1">{{planExpiration}} 自動更新</span>
                  </ng-container>

                  <ng-container
                    *ngIf="showAllBadges || (planStatus === ps.active && !isExpired && cancel_at_period_end)">
                    <span class="badge badge-warning mb-1">{{planExpiration}} 終了予定</span>
                    <span class="btn btn-sm btn-warning flex-fill clickable mb-1"
                      [routerLink]="urlPlan">自動更新を再開する</span>
                  </ng-container>

                  <ng-container
                    *ngIf="showAllBadges || (planStatus === ps.active && isExpired && !cancel_at_period_end)">
                    <span class="badge badge-success mb-1">自動更新処理中</span>
                  </ng-container>

                  <ng-container
                    *ngIf="showAllBadges || (planStatus === ps.active && isExpired && cancel_at_period_end) || (planStatus === ps.canceled)">
                    <span class="btn btn-sm btn-primary flex-fill clickable mb-1" [routerLink]="urlPlan">プランを購入する</span>
                  </ng-container>

                  <ng-container *ngIf="showAllBadges || (planStatus === ps.pending)">
                    <span class="badge badge-danger mb-1">自動更新の決済に失敗しました</span>
                    <span class="btn btn-sm btn-danger flex-fill clickable mb-1" [routerLink]="urlPlan">カードを変更する</span>
                  </ng-container>

                  <ng-container *ngIf="planCode === pc.light_20210901 || (planCode === pc.free_20210901 && !isExpired && user.expiration); else elseLock">
                    <a class="btn btn-sm btn-outline-success flex-fill clickable mt-1" [routerLink]="urlQuestion">
                      質問フォーム
                    </a>

                    <a class="btn btn-sm btn-outline-success flex-fill mt-1" [routerLink]="urlBooking">
                      オンライン授業予約
                    </a>
                  </ng-container>

                  <ng-template #elseLock>
                    <div class="btn btn-sm btn-outline-secondary flex-fill clickable mt-1">
                      <i [class]="iconLock"></i>質問フォーム
                    </div>

                    <div class="btn btn-sm btn-outline-secondary flex-fill mt-1">
                      <i [class]="iconLock"></i>オンライン授業予約
                    </div>
                  </ng-template>

                  <hr>

                  <a class="btn btn-sm btn-outline-success flex-fill mt-1" [routerLink]="urlPlan">
                    プラン購入／管理
                  </a>

                  <a class="btn btn-sm btn-outline-success flex-fill mt-1" target="_blank" rel="noopener"
                    href="https://www.vv-e-nn.com/company.php">
                    運営会社
                  </a>
                </ng-container>

                <ng-container *ngIf="showAllBadges || (planCode === pc.trainee)">
                  <span class="badge badge-success mb-1">研修モード</span>
                </ng-container>

              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-6 col-lg-12 col-xl-12 mb-4">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <span class="card-toggler" data-toggle="collapse" data-target="#bookingList" aria-controls="bookingList"
                aria-expanded="false"><i class="fas fa-caret-up nav white rt180 mr-1"></i>オンライン授業予約状況</span>
            </div>
            <div class="card-body-wrap collapse" id="bookingList">
              <div class="card-body">
                <span class="card-toggler" data-toggle="collapse" data-target="#bookingListFuture"
                  aria-controls="bookingListFuture" aria-expanded="false"><i
                    class="fas fa-times nav dimgray rt45 mr-2"></i>予約済のオンライン授業</span>
                <ul class="list-group mb-3 collapse" id="bookingListFuture">
                  <ng-container *ngFor="let item of bookingListData">
                    <li *ngIf="item.mode !== mode.closed" class="list-group-item px-2 booking_{{item.class}}"
                      title="{{item.title}}" (click)="bookingListItemClick(item)">{{item.text}}
                    </li>
                  </ng-container>
                </ul>
                <hr>
                <span class="card-toggler" data-toggle="collapse" data-target="#bookingListPast"
                  aria-controls="bookingListPast" aria-expanded="false"><i
                    class="fas fa-times nav dimgray rt45 mr-2"></i>過去のオンライン授業</span>
                <ul class="list-group collapse" id="bookingListPast">
                  <ng-container *ngFor="let item of bookingListData">
                    <li *ngIf="item.mode === mode.closed" class="list-group-item px-2 booking_{{item.class}}"
                      title="{{item.title}}" (click)="bookingListItemClick(item)">{{item.text}}
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div id="attendancePanel" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">オンライン授業予約照会</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="input-group">
          <span class="input-group-text mr-3">予約日時</span>
          <label class="col-form-label text-left text-selectable">{{bookingDate}}</label>
        </div>
      </div>
      <div class="modal-footer">
        <div
          *ngIf="(planCode === pc.free_20210901 && !isExpired) || (planCode === pc.light_20210901 && planStatus !== ps.canceled)"
          class="small text-muted text-right col-12 mx-0 px-0">
          <span class="ilb text-primary">
            <span>授業開始ボタンは予約時刻になると</span>
            <span>有効になります</span>
          </span>
        </div>
        <div
          *ngIf="!((planCode === pc.free_20210901 && !isExpired) || (planCode === pc.light_20210901 && planStatus !== ps.canceled))"
          class="small text-muted text-right col-12 mx-0 px-0">
          <span class="ilb text-danger">
            <span>現在のプランではオンライン授業を</span>
            <span>開始できません</span>
          </span>
          <br>
          <span class="ilb">
            <span><a [routerLink]="urlPlan" data-dismiss="modal">プラン購入／管理画面</a>
              から授業可能なプランを</span>
            <span>ご購入ください</span>
          </span>
        </div>
        <button *ngIf="editEnabled" data-dismiss="modal" type="button" class="btn btn-outline-danger"
          (click)="editClick()">予約変更</button>
        <button data-dismiss="modal" type="button" class="btn btn-outline-secondary">閉じる</button>
        <button
          *ngIf="!roomKnockEnabled || !((planCode === pc.free_20210901 && !isExpired) || (planCode === pc.light_20210901 && planStatus !== ps.canceled))"
          type="button" class="btn btn-outline-secondary disabled">授業開始</button>
        <button
          *ngIf="roomKnockEnabled && ((planCode === pc.free_20210901 && !isExpired) || (planCode === pc.light_20210901 && planStatus !== ps.canceled))"
          data-dismiss="modal" type="button" class="btn btn-outline-success" (click)="roomKnockClick()">授業開始</button>
      </div>
    </div>
  </div>
</div>

<div id="courseInfoPanel" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">「{{courseInfoTitle}}」学習内容</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngFor="let item of courseInfoIndex">
          <div class="list-group list-group-horizontal mb-1">
            <div class="list-group-item depth_space depth_1" *ngIf="item.depth > 1"></div>
            <div class="list-group-item depth_space depth_2" *ngIf="item.depth > 2"></div>
            <div class="list-group-item depth_space depth_3" *ngIf="item.depth > 3"></div>
            <div class="list-group-item depth_space depth_4" *ngIf="item.depth > 4"></div>
            <div [attr.class]="'d-flex justify-content-between list-group-item courseInfoIndex depth_' + item.depth">
              {{item.title}}<i class="fas fa-book-open dimgray" *ngIf="item.url !== ''"></i></div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div id="script"></div>
