import { Injectable } from '@angular/core';
import {
  IdTokenResult, sendEmailVerification, updateEmail,
  updatePassword, User as FirebaseUser, UserCredential
} from '@angular/fire/auth';
import { DocumentSnapshot } from '@angular/fire/firestore';
import { IsActiveMatchOptions, Router } from '@angular/router';
import isNull from 'lodash/isnull';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import { AuthConstants as AC } from 'src/app/constants/auth.constants';
import { BookingConstants as BC } from 'src/app/constants/booking.constants';
import { CommonConstants as CC } from 'src/app/constants/common.constants';
import * as PC from 'src/app/constants/plan.constants';
import { User } from 'src/app/models/class/User';
import { FireauthService } from 'src/app/utilities/injectable/fireauth.service';
import { FirefuncService } from 'src/app/utilities/injectable/firefunc.service';
import { FirestoreService } from 'src/app/utilities/injectable/firestore.service';
import { ReportingService } from 'src/app/utilities/injectable/reporting.service';
import { StripeService } from 'src/app/utilities/injectable/stripe.service';
import { JqueryService } from 'src/app/utilities/static/jquery.service';
import { LoadingService } from 'src/app/utilities/static/loading.service';
import { MomentService } from 'src/app/utilities/static/moment.service';
import { environment } from 'src/environments/environment';

const isActiveNotExact: IsActiveMatchOptions = {
  paths: 'subset', queryParams: 'subset',
  fragment: 'ignored', matrixParams: 'ignored'
};
const isActiveExact: IsActiveMatchOptions = {
  paths: 'exact', queryParams: 'exact',
  fragment: 'ignored', matrixParams: 'ignored'
};

/**
 * 複数のメソッドから呼び出されるfireauth関連メソッドが
 * 処理状態を判定する為のMode
 */
enum Mode {
  none,
  signin,
  signout,
  signup,
  forget,
  resend,
  updateInfo,
  updateEmail,
  updatePassword
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private mode: Mode = Mode.none;
  private user: User = null;
  private oldUser: User = null;
  private masqueradeUser: User = null;

  /**
   * userSubjectにnext()でuserを流すことで
   * userStateをsubscribeしている箇所へ
   * signin状態の変更を通知する
   */
  private userSubject: Subject<User | null> = new Subject();
  public userState: Observable<User | null> = this.userSubject.asObservable();

  /**
   * コンストラクタ
   *
   * - ユーザー認証におけるsubscribe処理を設定
   */
  constructor(
    private router: Router,
    private firefuncService: FirefuncService,
    private fireauthService: FireauthService,
    private firestoreService: FirestoreService,
    private stripeService: StripeService,
    private cookieService: CookieService,
    private reportingService: ReportingService
  ) {
    this.subscribeAuthState();
    this.subscribeUserState();
  }

  /**
   * ログインユーザー情報を設定する
   *
   * - 管理者の場合の開始／終了時間を設定する
   * @param firebaseUser ログインユーザー
   * @param idTokenResult 認証情報
   */
  private setupUser = (firebaseUser: FirebaseUser,
    idTokenResult: IdTokenResult): void => {
    this.user = new User({
      uid: firebaseUser.uid,
      email: firebaseUser.email,
      isAdmin: !!idTokenResult.claims.admin,
      // isEmailVerified: firebaseUser.emailVerified,
    });
    if (this.user.isAdmin) {
      BC.TIME.START = environment.admin_open;
      BC.TIME.END = environment.admin_close;
    } else {
      BC.TIME.START = environment.open;
      BC.TIME.END = environment.close;
    }
  }

  /**
   * 自動再サインイン監視
   *
   * - fireauthによる自動再サインインを監視（購読は初回nextのみ）
   * - ForgetMeNotによる自動サインインの有効期限確認
   * - 自動再サインインされた場合のみ詳細情報を取得
   */
  private subscribeAuthState = (): void =>
    this.fireauthService.subscribeAuthStateOnce(
      async firebaseUser => {
        const idTokenResult: IdTokenResult =
          await firebaseUser.getIdTokenResult();
        if (MomentService.isBefore(
          MomentService.getMomentFromEpoch(+idTokenResult.claims.auth_time),
          MomentService.getMomentFromDate(null,
            - AC.FORGET_ME_NOT.EXPIRATION.AMOUNT,
            AC.FORGET_ME_NOT.EXPIRATION.FORMAT))) {
          await this.fireauthSignOut();
          this.userSubject.next(null);
          LoadingService.off();
        } else {
          this.mode = Mode.signin;
          this.setupUser(firebaseUser, idTokenResult);
          await this.firestoreGetAdditionalUserInfo();
        }
      },
      () => {
        this.userSubject.next(null);
        LoadingService.off();
      })


  /**
   * ユーザー状態変更監視
   *
   * - ユーザー情報の更新が発生したらdashboardへ飛ばす
   * - 状態に応じたメッセージ表示
   */
  private subscribeUserState = (): void => {
    this.userState.subscribe(user => {
      if (this.isAuthenticated()) {
        if (this.router.isActive(CC.URL.AUTH.ROOT, isActiveNotExact)) {
          if (!this.router.isActive(CC.URL.AUTH.PLAN, isActiveExact)) {
            if (user.navigateTo !== CC.BLANK
              && user.planCode === PC.PLAN_EXT.free_20210901) {
              this.router.navigate([user.navigateTo]);
              // this.firestoreUpdateNavigateTo(user);
            } else {
              this.messageSignIn();
              this.router.navigate([CC.URL.DASHBOARD]);
            }
          }
        }
      } else {
        this.messageSignOut();
      }
      LoadingService.off();
    });
  }

  /**
   * サインインメッセージ
   */
  private messageSignIn = (): void => {
    if (this.mode === Mode.signin) {
      this.reportingService.successToast(
        this.getUser(true).name +
        AC.MESSAGE.NAME_POSTFIX +
        CC.HTML.TAG.BR +
        this.getGreetMessage(),
        AC.TITLE.SUCCESS.SIGNIN
      );
    } else if (this.mode === Mode.updateInfo) {
      this.reportingService.successToast(
        this.getUser(true).name +
        AC.MESSAGE.NAME_POSTFIX +
        CC.HTML.TAG.BR +
        AC.MESSAGE.GREETING.CONTINUE,
        AC.TITLE.SUCCESS.UPDATE.INFO
      );
    }
  }

  /**
   * こんにちは、こんばんは、おはようございます！
   */
  private getGreetMessage = (now: number =
    MomentService.newDate().getHours()): string =>
    (now >= AC.HOURS.MORNING && now < AC.HOURS.AFTERNOON)
      ? AC.MESSAGE.GREETING.MORNING
      : (now >= AC.HOURS.AFTERNOON && now < AC.HOURS.EVENING)
        ? AC.MESSAGE.GREETING.AFTERNOON
        : AC.MESSAGE.GREETING.EVENING

  /**
   * サインアウトメッセージ
   */
  private messageSignOut = (): void => {
    if (this.mode === Mode.signout) {
      this.reportingService.successToast(
        this.oldUser.name +
        AC.MESSAGE.NAME_POSTFIX +
        CC.HTML.TAG.BR +
        AC.MESSAGE.GREETING.BYE,
        AC.TITLE.SUCCESS.SIGNOUT
      );
    }
  }

  /**
   * サインイン状態返却
   *
   * @returns サインイン状態
   */
  public isAuthenticated = (): boolean => !isNull(this.user);

  /**
   * なりすまし状態返却
   *
   * @returns なりすまし状態
   */
  public isMasquerade = (): boolean => !isNull(this.masqueradeUser);

  /**
   * ユーザ情報返却
   *
   * @returns ユーザー情報
   */
  public getUser = (allowMasquerade: boolean = false): User =>
    (allowMasquerade && this.user.isAdmin && this.isMasquerade())
      ? this.masqueradeUser : this.user

  /**
   * なりすまし開始
   *
   * - ユーザー名に「★代行★」を付ける
   * - userSubject.nextする
   * @param masqueradeUser なりすまし対象ユーザー
   */
  public startMasquerade = (masqueradeUser: User): void => {
    this.masqueradeUser = masqueradeUser;
    this.masqueradeUser.isMasquerade = true;
    this.masqueradeUser.isEmailVerified = true;
    this.masqueradeUser.name = `【代行】${this.masqueradeUser.name}`;
    this.userSubject.next(this.masqueradeUser);
  }

  /**
   * なりすまし終了（ダッシュボードならリログ）
   */
  public endMasquerade = (): void => {
    this.mode = Mode.none;
    // if (this.router.isActive(CC.URL.DASHBOARD, false)) {
    //   LoadingService.on();
    //   location.reload();
    // } else {
    this.masqueradeUser = null;
    this.userSubject.next(this.user);
    // }
  }

  /**
   * サインイン処理
   *
   * - メールとパスワードでfireauthサインイン
   * - メール認証実施済ならfirestoreからユーザー情報取得
   * - メール認証未実施ならメッセージ表示してサインアウト
   * @param user サインイン処理用の情報のみのユーザー情報
   */
  public signin = async (user: User): Promise<void> => {
    this.mode = Mode.signin;
    LoadingService.on();
    const credential: UserCredential =
      await this.fireauthSignInWithEmailAndPassword(user);
    if (!isNull(credential)) {
      const firebaseUser: FirebaseUser = credential.user;
      const idTokenResult: IdTokenResult =
        await firebaseUser.getIdTokenResult();
      this.setupUser(firebaseUser, idTokenResult);
      await this.firestoreGetAdditionalUserInfo();
      if (this.user.a8 !== undefined && this.user.a8 !== '') {
        this.firefuncA8Send(this.user)
          .then(result => console.log('a8 send ' +
            (result ? 'succeeded.' : 'failed.')));
      }
    } else {
      LoadingService.off();
    }
  }

  /**
   * a8に成果送信
   *
   * - エラーは無視
   * @param user ユーザー情報
   * @returns 送信成否
   */
  private firefuncA8Send = async (user: User):
    Promise<boolean> => await this.firefuncService
      .call(AC.FUNCTIONS.A8_SEND.NAME, { a8: user.a8, uid: user.uid })
      .then(result => result.data[AC.FUNCTIONS.A8_SEND.RESULT])
      .catch(error => console.error('Error:', error));

  /**
   * サインアウト処理
   *
   * - サインイン状態の場合のみ
   * - ユーザー情報を退避してfireauthサインアウト
   */
  public signout = async (): Promise<void> => {
    if (this.isAuthenticated()) {
      this.mode = Mode.signout;
      LoadingService.on();
      this.oldUser = this.user;
      if (await this.fireauthSignOut()) {
        this.user = null;
        this.masqueradeUser = null;
        this.userSubject.next(this.user);
      } else {
        LoadingService.off();
      }
    }
  }

  /**
   * ユーザー登録処理
   *
   * - メールとパスワードでfireauthサインアップ
   * - ユーザー情報登録
   * - メールアドレス認証メール送信
   * - サインアウトしてサインイン画面へ
   * @param user ユーザー登録処理用の情報のみのユーザー情報
   */
  public signup = async (user: User): Promise<void> => {
    this.mode = Mode.signup;
    LoadingService.on();
    const credential: UserCredential =
      await this.fireauthCreateUserWithEmailAndPassword(user);
    if (!isNull(credential)) {
      user.uid = credential.user.uid;
      // await Promise.all([
      //   this.firestoreRegisterAdditionalUserInfo(user),
      //   this.fireauthSendEmailVerification(credential)])
      //   .then(([registerAdditionalUserInfoResult, sendEmailVerificationResult]) => {
      //     if (registerAdditionalUserInfoResult && sendEmailVerificationResult) {
      await this.firestoreRegisterAdditionalUserInfo(user)
        .then(registerAdditionalUserInfoResult => {
          if (registerAdditionalUserInfoResult) {
            this.reportingService.successToast(
              user.name +
              AC.MESSAGE.NAME_POSTFIX +
              CC.HTML.TAG.BR +
              AC.MESSAGE.INFO.LETS_START,
              AC.TITLE.SUCCESS.SIGNUP
            );
            this.router.navigate([CC.URL.AUTH.SIGNIN]);
          }
        });
      await this.fireauthSignOut();
    }
    LoadingService.off();
  }

  public trialSignup = async (user: User): Promise<void> => {
    this.mode = Mode.signup;
    LoadingService.on();
    const credential: UserCredential =
      await this.fireauthCreateUserWithEmailAndPassword(user);
    if (!isNull(credential)) {
      user.uid = credential.user.uid;
      user.navigateTo = CC.URL.AUTH.PLAN;
      user.planCode = PC.PLAN_EXT.free_20210901;
      const registered: boolean = await this.firestoreRegisterAdditionalUserInfo(user);
      if (registered) {
        this.mode = Mode.signin;
        await this.router.navigate([CC.URL.AUTH.SIGNIN]);
        await this.signin(user);
        this.reportingService.successToast(
          user.name +
          AC.MESSAGE.NAME_POSTFIX +
          CC.HTML.TAG.BR +
          this.getGreetMessage(),
          AC.TITLE.SUCCESS.SIGNUP);
      }
    }
    LoadingService.off();
  }

  /**
   * パスワードリセットメール送信処理
   *
   * - メールと生年月日が一致するユーザーを検索
   * - 該当ユーザーが存在したらメール送信
   * @param user ユーザー登録処理用の情報のみのユーザー情報
   */
  public forget = async (user: User): Promise<void> => {
    this.mode = Mode.forget;
    LoadingService.on();
    if (await this.firefuncIsUserExistsByEmailAndBirthdate(user)) {
      if (await this.fireauthSendPasswordResetEmail(user)) {
        this.reportingService.successToast(
          user.email,
          AC.TITLE.SUCCESS.FORGET
        );
        this.router.navigate([CC.URL.AUTH.SIGNIN]);
      }
    } else {
      this.reportingService.errorToast(
        AC.MESSAGE.ERROR.USER_NOT_FOUND,
        AC.TITLE.FAIL.FORGET
      );
    }
    LoadingService.off();
  }

  /**
   * メールアドレス認証メール再送信処理
   *
   * - メールとパスワードでfireauthサインイン
   * - メールアドレス認証メール送信
   * - サインアウトしてサインイン画面へ
   * @param user ユーザー登録処理用の情報のみのユーザー情報
   */
  public resend = async (user: User): Promise<void> => {
    this.mode = Mode.resend;
    LoadingService.on();
    const credential: UserCredential =
      await this.fireauthSignInWithEmailAndPassword(user);
    if (!isNull(credential)) {
      if (credential.user.emailVerified) {
        this.reportingService.errorToast(
          AC.MESSAGE.ERROR.EMAIL_ALREADY_VERIFIED,
          AC.TITLE.FAIL.RESEND
        );
      } else {
        if (await this.fireauthSendEmailVerification(credential)) {
          this.reportingService.successToast(
            user.email,
            AC.TITLE.SUCCESS.RESEND
          );
          this.router.navigate([CC.URL.AUTH.SIGNIN]);
        }
      }
      await this.fireauthSignOut();
    }
    LoadingService.off();
  }

  /**
   * ユーザー情報変更処理
   *
   * - ユーザー情報変更
   * @param user ユーザー情報
   */
  public updateInfo = async (user: User): Promise<void> => {
    this.mode = Mode.updateInfo;
    LoadingService.on();
    if (await this.firestoreUpdateAdditionalUserInfo(user)) {
      await this.firestoreGetAdditionalUserInfo();
    }
    LoadingService.off();
  }

  /**
   * メールアドレス変更処理
   *
   * - 現在のメールとパスワードでreauth
   * - メールアドレス変更
   * - メールアドレス認証メール送信
   * - サインアウトしてサインイン画面へ
   * @param user ユーザー情報
   */
  public updateEmail = async (user: User): Promise<void> => {
    this.mode = Mode.updateEmail;
    LoadingService.on();
    const credential: UserCredential =
      await this.fireauthReauthenticateWithCredential(user);
    if (!isNull(credential)) {
      if (await this.fireauthUpdateEmail(credential, user)) {
        if (await this.firestoreUpdateEmail(user)) {
          if (await this.stripeUpdateEmail(user)) {
            if (await this.fireauthSendEmailVerification(credential)) {
              this.reportingService.successToast(
                user.name +
                AC.MESSAGE.NAME_POSTFIX +
                CC.HTML.TAG.BR +
                AC.MESSAGE.INFO.VERIFY_EMAIL_SENT,
                AC.TITLE.SUCCESS.UPDATE.EMAIL
              );
              await this.fireauthSignOut();
              this.user = null;
              this.userSubject.next(this.user);
              this.router.navigate([CC.URL.AUTH.SIGNIN]);
            }
          }
        }
      }
    }
    LoadingService.off();
  }

  /**
   * パスワード変更処理
   *
   * - メールと現在のパスワードでreauth
   * - パスワード変更
   * - 仮パスワードフラグ更新
   * - サインアウトしてサインイン画面へ
   * @param user ユーザー情報
   */
  public updatePassword = async (user: User): Promise<void> => {
    this.mode = Mode.updatePassword;
    LoadingService.on();
    const credential: UserCredential =
      await this.fireauthReauthenticateWithCredential(user);
    if (!isNull(credential)) {
      if (await this.fireauthUpdatePassword(credential, user)) {
        if (await this.firestoreUpdatePassword(user)) {
          this.reportingService.successToast(
            user.name +
            AC.MESSAGE.NAME_POSTFIX +
            CC.HTML.TAG.BR +
            AC.MESSAGE.INFO.PLEASE_SIGNIN,
            AC.TITLE.SUCCESS.UPDATE.PASSWORD
          );
          await this.fireauthSignOut();
          this.user = null;
          this.userSubject.next(this.user);
          this.router.navigate([CC.URL.AUTH.SIGNIN]);
        }
      }
    }
    LoadingService.off();
  }

  /**
   * fireauthサインイン処理（メール＆パスワード）
   *
   * - メール＆パスワードでサインインして認証情報を返却
   * - サインイン失敗時はエラーメッセージ表示してnullを返却
   * - forgetMeNotならばブラウザ閉じても認証状態永続
   * - forgetMeNotでなければブラウザ閉じたら再度サインイン
   * @param user メール＆パスワードを持ったユーザー情報
   * @returns 認証情報|null
   */
  private fireauthSignInWithEmailAndPassword = async (user: User):
    Promise<UserCredential> => {
    let credential: UserCredential = null;
    await this.fireauthService
      .setPersistence(this.getForgetMeNot());
    await this.fireauthService
      .signInWithEmailAndPassword(user.email, user.password)
      .then(userCredential => credential = userCredential)
      .catch(error => this.reportingService.errorReport(
        AC.MESSAGE.ERROR.PLEASE_RETRY,
        AC.TITLE.FAIL.SIGNIN,
        'fireauthSignInWithEmailAndPassword', error
      ));
    return credential;
  }

  /**
   * fireauths再認証処理（メール＆パスワード）
   *
   * @param user メール＆パスワードを持ったユーザー情報
   * @returns 認証情報|null
   */
  private fireauthReauthenticateWithCredential = async (user: User):
    Promise<UserCredential> => {
    let credential: UserCredential = null;
    await this.fireauthService.reauthenticateWithCredential(
      (this.mode === Mode.updateEmail) ? user.oldEmail : user.email,
      (this.mode === Mode.updatePassword) ? user.oldPassword : user.password)
      .then(userCredential => credential = userCredential)
      .catch(error => this.reportingService.errorReport(
        AC.MESSAGE.ERROR.PLEASE_RETRY,
        (this.mode === Mode.updatePassword)
          ? AC.TITLE.FAIL.UPDATE.PASSWORD
          : AC.TITLE.FAIL.UPDATE.EMAIL,
        'fireauthReauthenticateWithCredential', error
      ));
    return credential;
  }

  /**
   * firestoreユーザー情報取得
   *
   * - firestoreからユーザー情報取得
   * - 取得できたらユーザー情報をセットして購読者へ通知
   * - 取得できなかったらサインアウト
   * - 代行時はmasqueradeUserに対して処理
   * - メールアドレス変更キャンセル時の同期処理
   * @returns ユーザー情報取得成否
   */
  private firestoreGetAdditionalUserInfo = async ():
    Promise<boolean> => {
    let result: boolean = false;
    await this.firestoreService.getDocument(
      [CC.COLLECTION.KEY.USERS,
      this.isMasquerade() ? this.masqueradeUser.uid : this.user.uid])
      .then(doc => {
        result = true;
        if (this.isMasquerade()) {
          const masqueradeUser: User = new User();
          masqueradeUser.setAdditionalUserInfo(doc);
          this.startMasquerade(masqueradeUser);
        } else {
          this.user.setAdditionalUserInfo(doc);
          if (this.user.email !== doc.get(CC.DOCUMENT.KEY.EMAIL)) {
            this.firestoreService.setDocument(
              [CC.COLLECTION.KEY.USERS, this.user.uid],
              this.user.getAdditionalUserInfoForUpdateEmail());
          }
          this.userSubject.next(this.user);
        }
      })
      .catch(error => {
        this.reportingService.errorReport(
          AC.MESSAGE.ERROR.PLEASE_RETRY,
          AC.TITLE.FAIL.GET_USER_INFO,
          'firestoreGetAdditionalUserInfo', error
        );
        LoadingService.off();
        this.signout();
      });
    return result;
  }

  /**
   * firestoreユーザー情報再取得
   *
   * - firestoreからユーザー情報再取得
   * - 再取得できたらユーザー情報をセットして購読者へ通知
   * - 再取得できなかったらサインアウト
   */
  public regetAdditionalUserInfo = async (): Promise<boolean> => {
    this.mode = Mode.none;
    return await this.firestoreGetAdditionalUserInfo();
  }

  /**
   * functionsメールと生年月日が一致するユーザーの検索
   *
   * - エラーは無視してユーザー非存在扱いとする
   * @param user ユーザー情報
   * @returns 検索成否
   */
  private firefuncIsUserExistsByEmailAndBirthdate = async (user: User):
    Promise<boolean> => await this.firefuncService
      .call(AC.FUNCTIONS.IS_USER_EXISTS.NAME,
        { email: user.email, birthdate: user.birthdate })
      .then(result => result.data[AC.FUNCTIONS.IS_USER_EXISTS.RESULT])
      .catch(error => false);

  /**
   * fireauthサインアウト処理
   *
   * - サインイン失敗時はエラーメッセージ表示してnullを返却
   * @returns サインアウト成否
   */
  private fireauthSignOut = async ():
    Promise<boolean> => {
    let result: boolean = false;
    await this.fireauthService.signOut()
      .then(() => result = true)
      .catch(error => this.reportingService.errorReport(
        AC.MESSAGE.ERROR.PLEASE_RETRY,
        AC.TITLE.FAIL.SIGNOUT,
        'fireauthSignOut', error
      ));
    return result;
  }

  /**
   * fireauth登録処理（メール＆パスワード）
   *
   * - メール＆パスワードで登録して認証情報を返却
   * - 登録失敗時はエラーリポートしてnullを返却
   * @param user メール＆パスワードを持ったユーザー情報
   * @returns fireauth認証情報|null
   */
  private fireauthCreateUserWithEmailAndPassword = async (user: User):
    Promise<UserCredential> => {
    let credential: UserCredential = null;
    await this.fireauthService
      .createUserWithEmailAndPassword(user.email, user.password)
      .then(userCredential => credential = userCredential)
      .catch(error => this.reportingService.errorReport(
        AC.MESSAGE.ERROR.PLEASE_RETRY,
        AC.TITLE.FAIL.SIGNUP,
        'fireauthCreateUserWithEmailAndPassword', error
      ));
    return credential;
  }

  /**
   * firestoreユーザー情報登録
   *
   * - PC環境毎の基本コースリストの最新バージョンを取得
   * - firestoreへユーザー情報登録
   * - 登録できなかったらサインアウト
   * @param user ユーザー情報
   * @returns ユーザー情報登録成否
   */
  private firestoreRegisterAdditionalUserInfo = async (user: User):
    Promise<boolean> => {
    let result: boolean = false;
    user.courseListVersion = CC.BLANK;
    await this.firestoreService.getDocument(
      [CC.COLLECTION.KEY.COURSE_LIST, user.courseListType])
      .then(doc => user.courseListVersion =
        doc.get(CC.DOCUMENT.KEY.CURRENT_VERSION))
      .catch(error => this.reportingService.errorReport(
        AC.MESSAGE.ERROR.PLEASE_RETRY,
        AC.TITLE.FAIL.SIGNUP,
        'firestoreRegisterAdditionalUserInfo', error
      ));
    await this.firestoreService.setDocument(
      [CC.COLLECTION.KEY.USERS, user.uid],
      user.getAdditionalUserInfoForRegister())
      .then(() => result = true)
      .catch(error => this.reportingService.errorReport(
        AC.MESSAGE.ERROR.PLEASE_RETRY,
        AC.TITLE.FAIL.SIGNUP,
        'firestoreRegisterAdditionalUserInfo', error
      ));
    return result;
  }

  /**
   * fireauthメールアドレス認証メール送信処理
   *
   * @param credential fireauth認証情報
   * @returns メール送信成否
   */
  private fireauthSendEmailVerification =
    async (credential: UserCredential): Promise<boolean> => {
      let result: boolean = false;
      const title: string =
        (this.mode === Mode.resend) ? AC.TITLE.FAIL.RESEND
          : (this.mode === Mode.updateEmail) ? AC.TITLE.FAIL.UPDATE.EMAIL
            : AC.TITLE.FAIL.SIGNUP;
      await sendEmailVerification(credential.user)
        .then(() => result = true)
        .catch(error => this.reportingService.errorReport(
          AC.MESSAGE.ERROR.PLEASE_RETRY,
          title,
          'fireauthSendEmailVerification', error
        ));
      return result;
    }

  /**
   * fireauthパスワードリセットメール送信処理
   *
   * @param user メールを持ったユーザー情報
   * @returns メール送信成否
   */
  private fireauthSendPasswordResetEmail = async (user: User):
    Promise<boolean> => {
    let result: boolean = false;
    await this.fireauthService.sendPasswordResetEmail(user.email)
      .then(() => result = true)
      .catch(error => this.reportingService.errorReport(
        AC.MESSAGE.ERROR.PLEASE_RETRY,
        AC.TITLE.FAIL.FORGET,
        'fireauthSendPasswordResetEmail', error
      ));
    return result;
  }

  /**
   * firestoreユーザー情報更新
   *
   * - firestoreへユーザー情報更新
   * - 更新できたらユーザー情報をセットして購読者へ通知
   * @param user ユーザー情報
   * @returns ユーザー情報更新成否
   */
  private firestoreUpdateAdditionalUserInfo = async (user: User):
    Promise<boolean> => {
    let result: boolean = false;
    await this.firestoreService.setDocument(
      [CC.COLLECTION.KEY.USERS, user.uid],
      user.getAdditionalUserInfoForUpdateInfo())
      .then(() => result = true)
      .catch(error => this.reportingService.errorReport(
        AC.MESSAGE.ERROR.PLEASE_RETRY,
        AC.TITLE.FAIL.UPDATE.INFO,
        'firestoreUpdateAdditionalUserInfo', error
      ));
    return result;
  }

  /**
   * firestoreメールアドレス更新
   *
   * - firestoreへメールアドレス更新
   * - 更新できたらユーザー情報をセットして購読者へ通知
   * @param user ユーザー情報（メールアドレス）
   * @returns メールアドレス更新成否
   */
  private firestoreUpdateEmail = async (user: User):
    Promise<boolean> => {
    let result: boolean = false;
    await this.firestoreService.setDocument(
      [CC.COLLECTION.KEY.USERS, user.uid],
      user.getAdditionalUserInfoForUpdateEmail())
      .then(() => result = true)
      .catch(error => this.reportingService.errorReport(
        AC.MESSAGE.ERROR.PLEASE_RETRY,
        AC.TITLE.FAIL.UPDATE.EMAIL,
        'firestoreUpdateEmail', error
      ));
    return result;
  }

  /**
   * stripeメールアドレス更新
   *
   * @param user ユーザー情報
   * @returns メールアドレス更新成否
   */
  private stripeUpdateEmail = async (user: User):
    Promise<boolean> => {
    let result: boolean = false;
    if (user.stripeSubscription) {
      await this.stripeService.updateCustomerEmail(
        user.stripeSubscription.customer, user.email)
        .then(() => result = true)
        .catch(error => this.reportingService.errorReport(
          AC.MESSAGE.ERROR.PLEASE_RETRY,
          AC.TITLE.FAIL.UPDATE.EMAIL,
          'stripeUpdateEmail', error
        ));
    } else {
      result = true;
    }
    return result;
  }

  /**
   * fireauthメールアドレス更新
   *
   * @param credential fireauth認証情報
   * @param user ユーザー情報
   * @returns メールアドレス更新成否
   */
  private fireauthUpdateEmail =
    async (credential: UserCredential, user: User):
      Promise<boolean> => {
      let result: boolean = false;
      await updateEmail(credential.user, user.email)
        .then(() => result = true)
        .catch(error => this.reportingService.errorReport(
          AC.MESSAGE.ERROR.PLEASE_RETRY,
          AC.TITLE.FAIL.UPDATE.EMAIL,
          'fireauthUpdateEmail', error
        ));
      return result;
    }

  /**
   * fireauthパスワード更新
   *
   * @param credential fireauth認証情報
   * @param user ユーザー情報
   * @returns パスワード更新成否
   */
  private fireauthUpdatePassword =
    async (credential: UserCredential, user: User):
      Promise<boolean> => {
      let result: boolean = false;
      await updatePassword(credential.user, user.password)
        .then(() => result = true)
        .catch(error => this.reportingService.errorReport(
          AC.MESSAGE.ERROR.PLEASE_RETRY,
          AC.TITLE.FAIL.UPDATE.PASSWORD,
          'fireauthUpdatePassword', error
        ));
      return result;
    }

  /**
   * firestore仮パスワードフラグ更新
   *
   * - firestoreへ仮パスワードフラグ更新
   * - 更新できたらユーザー情報をセットして購読者へ通知
   * @param user ユーザー情報（仮パスワードフラグ）
   * @returns 仮パスワードフラグ更新成否
   */
  private firestoreUpdatePassword = async (user: User):
    Promise<boolean> => {
    let result: boolean = false;
    await this.firestoreService.setDocument(
      [CC.COLLECTION.KEY.USERS, user.uid],
      user.getAdditionalUserInfoForUpdatePassword())
      .then(() => result = true)
      .catch(error => this.reportingService.errorReport(
        AC.MESSAGE.ERROR.PLEASE_RETRY,
        AC.TITLE.FAIL.UPDATE.PASSWORD,
        'firestoreUpdatePassword', error
      ));
    return result;
  }

  /**
   * firestoreログイン後ナビゲーションパス更新
   *
   * - firestoreへログイン後ナビゲーションパス更新
   * - 更新できたらユーザー情報をセットして購読者へ通知
   * @param user ユーザー情報（ログイン後ナビゲーションパス）
   * @returns ログイン後ナビゲーションパス更新成否
   */
  private firestoreUpdateNavigateTo = async (user: User):
    Promise<boolean> => {
    let result: boolean = false;
    await this.firestoreService.setDocument(
      [CC.COLLECTION.KEY.USERS, user.uid],
      user.getAdditionalUserInfoForUpdateNavigateTo())
      .then(() => result = true)
      .catch(error => this.reportingService.errorReport(
        AC.MESSAGE.INFO.PLEASE_SIGNIN,
        AC.TITLE.FAIL.UNKNOWN,
        'firestoreUpdateNavigateTo', error
      ));
    return result;
  }

  /**
   * ログイン状態維持チェックボックス操作時のcookie処理
   *
   * @param isForgetMeNot ログイン状態維持要否
   */
  public setForgetMeNot = (isForgetMeNot: boolean): void => {
    if (isForgetMeNot) {
      this.reportingService.infoToast(
        AC.MESSAGE.INFO.FORGET_ME_NOT.ON_PRE +
        AC.FORGET_ME_NOT.EXPIRATION.AMOUNT +
        AC.MESSAGE.INFO.FORGET_ME_NOT.ON_POST,
        AC.TITLE.SUCCESS.FORGET_ME_NOT.ON);
      this.cookieService.set(
        AC.FORGET_ME_NOT.COOKIE.KEY,
        AC.FORGET_ME_NOT.COOKIE.VALUE,
        AC.FORGET_ME_NOT.COOKIE.EXPIRATION,
        AC.FORGET_ME_NOT.COOKIE.PATH);
    } else {
      this.reportingService.infoToast(
        AC.MESSAGE.INFO.FORGET_ME_NOT.OFF,
        AC.TITLE.SUCCESS.FORGET_ME_NOT.OFF);
      this.cookieService.delete(
        AC.FORGET_ME_NOT.COOKIE.KEY,
        AC.FORGET_ME_NOT.COOKIE.PATH);
    }
  }

  /**
   * cookieからのログイン状態維持要否を取得
   *
   * @returns ログイン状態維持要否
   */
  public getForgetMeNot = (): boolean =>
  (this.cookieService.get(AC.FORGET_ME_NOT.COOKIE.KEY) ===
    AC.FORGET_ME_NOT.COOKIE.VALUE)

  /**
   * HTML5によるvalidationへ確認用パスワードの一致チェックを追加
   */
  public setupPasswordConfirmValidation = (): void => {
    const password: any = JqueryService.getById(CC.ID.FORM.PASSWORD)[0];
    const passwordConfirm: any = JqueryService.getById(CC.ID.FORM.PASSWORD_CONFIRM)[0];
    const passwordConfirmValidation: any =
      event => passwordConfirm.setCustomValidity(
        (password.value !== passwordConfirm.value) ? false : CC.BLANK);
    password.addEventListener(CC.EVENT.INPUT, passwordConfirmValidation);
    passwordConfirm.addEventListener(CC.EVENT.INPUT, passwordConfirmValidation);
  }

  /**
   * HTML5によるvalidationへ生年月日の年齢制限チェックを追加
   */
  public setupBirthDateValidation = (): void => {
    const birthdate: any = JqueryService.getById(CC.ID.FORM.BIRTHDATE)[0];
    const birthdateValidation: any =
      event => birthdate.setCustomValidity(
        !MomentService.checkBirthDateRange(birthdate.value,
          AC.REQUIREMENTS.MIN_AGE, AC.REQUIREMENTS.MAX_AGE) ? false : CC.BLANK);
    birthdate.addEventListener(CC.EVENT.INPUT, birthdateValidation);
  }

  public cancelPurchase = (): void => {
    this.reportingService.infoToast(
      AC.MESSAGE.ERROR.PLEASE_RETRY,
      AC.TITLE.FAIL.PURCHASE);
  }

  public completePurchase = async ():
    Promise<void> => {
    this.reportingService.infoToast(
      AC.MESSAGE.INFO.PLEASE_SIGNIN,
      AC.TITLE.SUCCESS.PURCHASE);
    await this.fireauthSignOut();
    this.user = null;
    this.userSubject.next(this.user);
    this.router.navigate([CC.URL.AUTH.SIGNIN]);
  }

  public ping = async (memo: string = this.router.url): Promise<Date> => {
    if (environment.now !== null) {
      return MomentService.newDate();
    } else {
      await this.firestoreService.setDocument(['ping', this.user.uid], {
        timestamp: FirestoreService.getServerTimestamp(), memo
      });
      const serverTimestamp: DocumentSnapshot =
        await this.firestoreService.getDocument(['ping', this.user.uid]);
      const timestamp: Date = serverTimestamp.get('timestamp').toDate();
      const formatTimestamp: string = MomentService.getFormatDateTimeFromDateObject(timestamp);
      this.firestoreService.setDocument(['ping', this.user.uid], {
        history: FirestoreService.arrayUnion(
          formatTimestamp + '|' + serverTimestamp.get('memo')
        )
      });
      if (environment.offsetFrom !== null && environment.offsetTo !== null) {
        return MomentService.getOffset(timestamp);
      } else {
        const serverMillis: number = timestamp.getTime();
        const localMillis: number = Date.now(); // pingは実時間だからlocalもここだけは端末実時間
        const diffTime: number = Math.abs(serverMillis - localMillis);
        const diffConfigTime: number = 300000;

        if (diffTime >= diffConfigTime) {
          await this.fireauthSignOut();
          this.user = null;
          this.userSubject.next(this.user);
          this.router.navigate([CC.URL.AUTH.SIGNIN]);
          this.reportingService.errorToast(
            AC.MESSAGE.ERROR.CLOCK_IS_OFF,
            AC.TITLE.FAIL.CLOCK_IS_OFF
          );
        }
        return timestamp;
      }
    }
  }
}
