import { Injectable } from '@angular/core';
import { FirestoreService } from 'src/app/utilities/injectable/firestore.service';
import { MomentService } from 'src/app/utilities/static/moment.service';
import { CommonConstants as CC } from 'src/app/constants/common.constants';
import { BookingConstants as BC } from 'src/app/constants/booking.constants';
import { MomentConstants as MC } from 'src/app/constants/moment.constants';
import { RoomInfo } from 'src/app/models/interface/RoomInfo';
import { RoomAssign, Rooms, RoomAssignRequest, OpenRoom } from 'src/app/models/interface/RoomAssign';
import { BookingData } from 'src/app/models/class/BookingData';
import { BookingCell } from 'src/app/models/class/BookingCell';
// import { DebugService } from '../utilities/static/debug.service';

@Injectable({
  providedIn: 'root'
})
export class RoomsService {

  // [roomId]: { [date+time]: isOpen }
  private roomSchedule: Map<string, Map<string, boolean>>;

  constructor(
    private firestoreService: FirestoreService
  ) { }

  public prepareRooms = async (): Promise<void> => {
    const rooms: Map<string, RoomInfo[]> = new Map<string, RoomInfo[]>();
    await this.firestoreService.getCollection([CC.COLLECTION.KEY.ROOMS])
      .then(snapshot => snapshot.docs.forEach(
        doc => rooms.set(doc.id, doc.get(CC.DOCUMENT.KEY.ROOM_INFO))));
    this.roomSchedule = new Map<string, Map<string, boolean>>();
    rooms.forEach((roomInfos, roomId) => {
      const room: Map<string, boolean> = this.roomSchedule.set(roomId,
        new Map<string, boolean>()).get(roomId);
      roomInfos.forEach(roomInfo => {
        const date: string = MomentService.isValidDate(roomInfo.dateOrWeekday)
          ? roomInfo.dateOrWeekday : null;
        MomentService.forEachDateTimeRange(date,
          roomInfo.start || BC.TIME.START, roomInfo.end || BC.TIME.END,
          BC.TIME.UNIT, MC.TIME.FORMAT.MINUTES, timeMoment => {
            const key: string = roomInfo.dateOrWeekday + CC.SPACE +
              MomentService.getTimeFromMoment(timeMoment);
            const isOpen: boolean = !room.has(key) ? roomInfo.isOpen :
              roomInfo.isOpen && room.get(key);
            room.set(key, isOpen);
          });
      });
    });
  }

  public getAssignedRooms = async (date: string, startTime: string):
    Promise<Rooms> => {
    const roomAssign: RoomAssign = await this.firestoreService.getDocument(
      [CC.COLLECTION.KEY.BOOKINGS, date])
      .then(doc => doc.get(CC.DOCUMENT.KEY.ROOM_ASSIGN));
    return (roomAssign && roomAssign[startTime]) || {};
  }

  public getAssignedRoomId = (rooms: Rooms, uid: string, key: string):
    string => Object.entries(rooms).find(([_, bookingKey]) =>
      bookingKey === this.getBookingKey(uid, key))[0];

  public openAssignedRoom = async (bookingData: BookingCell):
    Promise<void> => {
    const rooms: Rooms = await this.getAssignedRooms(bookingData.date, bookingData.start);
    const roomId: string = this.getAssignedRoomId(rooms, bookingData.uid, bookingData.key);
    window.open(BC.ROOM.URL.GET(roomId));
  }

  public getCapacity = (date: string, startTime: string):
    number => this.getRoomIdsInDateTime(date, startTime).length

  public getRoomIdsInDateTime = (date: string, startTime: string):
    string[] => {
    const roomIds: string[] = [];
    const key: string = date + CC.SPACE + startTime;
    const keyDefault: string =
      MomentService.getDate(0, date, MC.DATE.FORMAT.d)
      + CC.SPACE + startTime;
    this.roomSchedule.forEach((schedule, roomId) => {
      const isOpen: boolean = schedule.has(key) ? schedule.get(key) :
        schedule.has(keyDefault) && schedule.get(keyDefault);
      if (isOpen) {
        roomIds.push(roomId);
      }
    });
    return roomIds;
  }

  private getOpenRooms = (date: string): OpenRoom[] => {
    const openRooms: OpenRoom[] = [];
    MomentService.forEachDateTimeRange(date,
      MC.TIME.MID_NIGHT, MC.TIME.MID_NIGHT_SYNONYM,
      BC.TIME.UNIT, MC.TIME.FORMAT.MINUTES, timeMoment => {
        const time: string = MomentService.getTimeFromMoment(timeMoment);
        this.getRoomIdsInDateTime(date, time)
          .map<OpenRoom>(roomId => ({ time, roomId }))
          .forEach(openRoom => openRooms.push(openRoom));
      });
    return openRooms;
  }

  public assignRooms = (bookingData: BookingData, oldRoomAssign: RoomAssign,
    key: string, isDelete: boolean): RoomAssign | boolean => {

    // 時間帯と空いているルームの情報を取得
    let openRooms: OpenRoom[] = this.getOpenRooms(bookingData.date);

    // 割当要求を整理
    const roomAssignRequests: RoomAssignRequest[] =
      this.getRoomAssignRequests(bookingData, oldRoomAssign,
        key, isDelete, openRooms);

    // console.groupCollapsed(bookingData.date + CC.SPACE + bookingData.start);
    // DebugService.table('roomAssignRequests', roomAssignRequests);
    // DebugService.table('openRooms', openRooms);

    // 割当要求を順に処理
    const roomAssign: RoomAssign = {};
    let failed: boolean = false;
    roomAssignRequests.forEach((roomAssignRequest, idx) => {

      // 失敗以降はスキップ
      if (failed) {
        return false;
      }

      // 開始～終了期間内の未割当ルームを一旦取得
      const end: string = MomentService.getTime(
        roomAssignRequest.start, BC.TIME.UNIT * roomAssignRequest.count);
      const emptyOpenRooms: OpenRoom[] = openRooms.filter(openRoom =>
        (openRoom.time >= roomAssignRequest.start) && (openRoom.time < end));

      // count分連続で取れる部屋を検索
      const assignableRoomIds: string[] = [];
      [... new Set(emptyOpenRooms.map(r => r.roomId))].forEach(roomId => {
        if (emptyOpenRooms.filter(openRoom => openRoom.roomId === roomId)
          .length === roomAssignRequest.count) {
          assignableRoomIds.push(roomId);
        }
      });

      // 割当可能なルームがひとつもないもしくは
      // 開始時刻が現在より前で希望ルームが無い場合はパズル失敗
      if ((assignableRoomIds.length === CC.ZERO)
        || (MomentService.isBefore(MomentService.getMomentFromDateTime(
          bookingData.date, roomAssignRequest.start))
          && (assignableRoomIds.find(roomId =>
            roomId === roomAssignRequest.roomId).length === CC.ZERO))) {
        failed = true;
        // console.groupCollapsed(roomAssignRequest.bookingKey + CC.SPACE + 'failed!');
        // DebugService.table('roomAssignRequest', roomAssignRequest);
        // DebugService.table('emptyOpenRooms', emptyOpenRooms);
        // DebugService.table('assignableRoomIds', assignableRoomIds);
        // DebugService.table('roomAssign', roomAssign);
        // DebugService.table('openRooms', openRooms);
        // console.groupEnd();
        return false;
      }

      // より長く空いているルームは遠慮して空けておいてあげる
      // 終了時刻以降の連続空きが最も短いルームのみ抽出
      const shortestAssignableRoomIds: string[] = [];
      // 予約時間帯の次の枠から１枠ずつずらしながら取得してみて、
      // 取れなくなったらその時間までしか連続で取れないルームのみ
      let extendedTime: string = end;
      while (shortestAssignableRoomIds.length === CC.ZERO) {
        assignableRoomIds.forEach(roomId => {
          if (!openRooms.find(openRoom => ((openRoom.roomId === roomId)
            && (openRoom.time === extendedTime)))) {
            shortestAssignableRoomIds.push(roomId);
          }
        });
        extendedTime = MomentService.getTime(extendedTime, BC.TIME.UNIT);
      }

      // 新規（希望ルーム空）もしくは希望ルームが無い場合は１個目
      // 有ればそのルームIDで確定
      const assignRoomId: string = shortestAssignableRoomIds
        .find(roomId => roomId === roomAssignRequest.roomId)
        || shortestAssignableRoomIds[0];

      // 同じユーザーの予約は希望ルームを合わせてしまう
      // 再ソート用にルーム変更ループ位置もセット
      roomAssignRequests.forEach((item, itemIdx) => {
        if (item.uid === roomAssignRequest.uid) {
          roomAssignRequests[itemIdx].roomId = assignRoomId;
          roomAssignRequests[itemIdx].roomIdChangedAt = idx;
        }
      });

      // そして以降を再ソート
      if (idx !== roomAssignRequests.length - 2) {
        const sorted = this.sortRoomAssignRequests(
          roomAssignRequests.slice(idx + 1));
        roomAssignRequests.splice(idx + 1,
          roomAssignRequests.length - idx, ...sorted);
      }

      // 確定したルームIDで改めてルームを取得
      const assignRooms = openRooms.filter(openRoom =>
        (openRoom.roomId === assignRoomId)
        && (openRoom.time >= roomAssignRequest.start)
        && (openRoom.time < end));
      // roomAssignに該当時間帯が有れば追加、無ければ新規キー作成
      assignRooms.forEach(assignRoom => {
        if (roomAssign[assignRoom.time]) {
          roomAssign[assignRoom.time][assignRoom.roomId] =
            roomAssignRequest.bookingKey;
        } else {
          roomAssign[assignRoom.time] = {
            [assignRoom.roomId]: roomAssignRequest.bookingKey
          };
        }
      });
      // 割当済みのルームを除去
      openRooms = openRooms.filter(openRoom =>
        !((openRoom.roomId === assignRoomId)
          && (openRoom.time >= roomAssignRequest.start)
          && (openRoom.time < end)));

      // console.groupCollapsed(roomAssignRequest.bookingKey);
      // DebugService.table('roomAssignRequest', roomAssignRequest);
      // DebugService.table('emptyOpenRooms', emptyOpenRooms);
      // DebugService.table('assignableRoomIds', assignableRoomIds);
      // DebugService.table('shortestAssignableRoomIds', shortestAssignableRoomIds);
      // DebugService.table('assignRooms', assignRooms);
      // DebugService.table('roomAssign', roomAssign);
      // DebugService.table('openRooms', openRooms);
      // console.groupEnd();
    });
    // DebugService.table('roomAssignRequests', roomAssignRequests);
    // console.groupEnd();
    return failed ? false : roomAssign;
  }

  private getRoomAssignRequests = (bookingData: BookingData,
    roomAssign: RoomAssign, key: string, isDelete: boolean,
    openRooms: OpenRoom[]):
    RoomAssignRequest[] => {

    const roomAssignRequestsMap: Map<string, RoomAssignRequest>
      = new Map<string, RoomAssignRequest>();

    // 割当要求を整理：処理対象の予約（削除時は削除対象のキー設定のみ）
    const newBookingKey: string =
      this.getBookingKey(bookingData.original.uid, key);
    if (!isDelete) {
      let count: number = CC.ZERO;
      let priority: number = CC.ONE;
      MomentService.forEachDateTimeRange(bookingData.date,
        bookingData.start, bookingData.end,
        BC.TIME.UNIT, MC.TIME.FORMAT.MINUTES, timeMoment => {
          const time: string = MomentService.getTimeFromMoment(timeMoment);
          const rooms: Rooms = (roomAssign && roomAssign[time]) || {};
          // 優先度は重みの積算
          priority *= this.getPriorityWeight(
            openRooms.filter(openRoom => openRoom.time === time).length,
            Object.entries(rooms).length);
          count++;
        });
      roomAssignRequestsMap.set(newBookingKey, {
        uid: bookingData.original.uid, key, bookingKey: newBookingKey,
        start: bookingData.start, count, roomId: CC.BLANK, priority,
        roomIdChangedAt: openRooms.length
      });
    }

    // 割当要求を整理：全ての予約
    MomentService.forEachDateTimeRange(bookingData.date,
      BC.TIME.START, BC.TIME.END, BC.TIME.UNIT, MC.TIME.FORMAT.MINUTES,
      timeMoment => {
        const time: string = MomentService.getTimeFromMoment(timeMoment);
        const rooms: Rooms = (roomAssign && roomAssign[time]) || {};
        // 優先度は重みの積算
        const priority: number = this.getPriorityWeight(
          openRooms.filter(openRoom => openRoom.time === time).length,
          Object.entries(rooms).length);
        Object.entries(rooms).forEach(([roomId, bookingKey]) => {
          // 処理対象の予約とkeyが同じ予約（変更前予約・削除対象予約）
          // ならば割当要求を追加しない
          if (bookingKey !== newBookingKey) {
            if (!roomAssignRequestsMap.has(bookingKey)) {
              roomAssignRequestsMap.set(bookingKey,
                {
                  uid: this.getUid(bookingKey), key: this.getKey(bookingKey),
                  bookingKey, start: time, count: 1, roomId, priority,
                  roomIdChangedAt: openRooms.length
                });
            } else {
              roomAssignRequestsMap.get(bookingKey).count++;
              roomAssignRequestsMap.get(bookingKey).priority *= priority;
            }
          }
          // 処理対象の予約とuidが同じ予約（変更前予約、もしくは自分の別枠予約）
          // ならばそれと同じルームを要求
          if (!isDelete) {
            if (this.getUid(bookingKey) === this.getUid(newBookingKey)) {
              roomAssignRequestsMap.get(newBookingKey).roomId = roomId;
            }
          }
        });
      });

    // 配列化してソート
    const result: RoomAssignRequest[] =
      this.sortRoomAssignRequests([...roomAssignRequestsMap]
        .map<RoomAssignRequest>(([_, roomAssignRequest]) => roomAssignRequest));
    return result;
  }

  // 優先度が高い順、開始時刻が早い順、予約時間が長い順、
  // ルーム変更ループ位置が早い順、希望ルーム(Unicode注意！)順、予約日時(key)が早い順
  private sortRoomAssignRequests = (roomAssignRequests: RoomAssignRequest[]):
    RoomAssignRequest[] => roomAssignRequests
      .sort((a, b) => this.numerize(a.key) - this.numerize(b.key))
      .sort((a, b) => a.roomId < b.roomId ? -1 : a.roomId > b.roomId ? 1 : 0)
      .sort((a, b) => a.roomIdChangedAt - b.roomIdChangedAt)
      .sort((a, b) => b.count - a.count)
      .sort((a, b) => this.numerize(a.start) - this.numerize(b.start))
      .sort((a, b) => a.priority - b.priority)

  // 優先度はその時間帯の「((ルーム数*2)/予約数)*ルーム数」の積算
  // 予約数0は0.5とする
  private getPriorityWeight = (rooms: number, requests: number) =>
    ((rooms * 2) / (requests === CC.ZERO ? 0.5 : requests)) * rooms

  private numerize = (value: string): number =>
    +value.replace(CC.COLLON, CC.BLANK)

  private getBookingKey = (uid: string, key: string): string =>
    uid + CC.COLLON + key

  private getUid = (bookingKey: string): string =>
    bookingKey.split(CC.COLLON)[0]

  private getKey = (bookingKey: string): string =>
    bookingKey.split(CC.COLLON)[1]
}
