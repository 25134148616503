import { Injectable } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { CommonConstants as CC } from 'src/app/constants/common.constants';
import * as PC from 'src/app/constants/plan.constants';
import { QuestionConstants as QC } from 'src/app/constants/question.constants';
import { QuestionError, QuestionErrorCode as QEC } from 'src/app/models/class/QuestionError';
import { QuestionSearchCriteria } from 'src/app/models/class/QuestionSearchCriteria';
import { Question } from 'src/app/models/interface/Question';
import { Where } from 'src/app/models/interface/Where';
import { FirestorageService } from 'src/app/utilities/injectable/firestorage.service';
import { FirestoreService } from 'src/app/utilities/injectable/firestore.service';
import { ReportingService } from 'src/app/utilities/injectable/reporting.service';
import { LoadingService } from 'src/app/utilities/static/loading.service';
import { MomentService } from 'src/app/utilities/static/moment.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  private lastCriteria: QuestionSearchCriteria = null;
  private lastQuestionList: Question[] = null;

  constructor(
    private firestorageService: FirestorageService,
    private firestoreService: FirestoreService,
    private reportingService: ReportingService
  ) { }

  public isExistsReplyFlag = async (postedUid: string): Promise<boolean> =>
    (await this.getQuestionList(postedUid)).some(question => question.replyFlag);

  public getQuestionList = async (postedUid: string): Promise<Question[]> =>
    await this.searchQuestionList(new QuestionSearchCriteria({ postedUid }));

  public searchQuestionList = async (criteria: QuestionSearchCriteria):
    Promise<Question[]> => {
    LoadingService.on();
    const where: Where[] = this.makeWhere(criteria);
    const questionList: Question[] = await this.firestoreService.searchDocument(
      [QC.ID.COLLECTION.QUESTIONS], where, criteria.limit
    ).then(snapshot => snapshot.docs.map(doc => doc.data() as unknown as Question)
    ).catch(error => {
      this.reportingService.errorReport(
        CC.MESSAGE.ERROR.PLEASE_RETRY,
        CC.TITLE.FAIL.GETDATA,
        'searchQuestionList', error);
      return [];
    });
    LoadingService.off();
    return questionList;
  }

  public postQuestion = async (postedUid: string, title: string,
    content: string, screenShots: File[]): Promise<void> => {
    LoadingService.on();
    const now: Date = MomentService.newDate();
    const uid: string = this.makePostUid(now, postedUid);
    const screenShotsFilePaths: string[] = screenShots.map((_, index) =>
      QC.ID.STORAGE.QA + CC.SLASH + QC.ID.STORAGE.QUESTION + CC.SLASH +
      uid + CC.UNDERSCORE + QC.ID.STORAGE.Q + CC.UNDERSCORE + index);
    const screenShotUrls: string[] = await this.firestorageService
      .saveScreenShots(screenShots, screenShotsFilePaths);
    const postedAt = FirestoreService.getServerTimestamp();
    const replyContent = null;
    const replyScreenShotUrls = null;
    const replyAt = null;
    const replyUid = null;
    const replyFlag = false;
    const question: Question = {
      uid, title, content, screenShotUrls, postedAt, postedUid,
      replyContent, replyScreenShotUrls, replyAt, replyUid, replyFlag
    }

    const userDocRef: DocumentReference =
      this.firestoreService.getDocumentReference([CC.COLLECTION.KEY.USERS, postedUid]);
    const questionDocRef: DocumentReference =
      this.firestoreService.getDocumentReference([QC.ID.COLLECTION.QUESTIONS, uid]);
    // トランザクション
    await this.firestoreService.runTransaction(transaction => {
      return Promise.all([
        transaction.get(userDocRef), transaction.get(questionDocRef)
      ]).then(([userDoc, _]) => {

        // 質問可能プランじゃなければエラーリジェクト
        // 質問可能プランでも質問回数が0ならばエラーリジェクト
        // いずれも該当しなければトランザクション内で質問データを保存
        const planCode: PC.PLANCODE = userDoc.get(CC.DOCUMENT.KEY.PLAN_CODE);
        let questionCount = userDoc.get(CC.DOCUMENT.KEY.QUESTION_COUNT);
        const isPlanNotMatch = !((planCode === PC.PLAN.free_20210901)
          || (planCode === PC.PLAN.light_20210901));
        const isCountNotRemain = (((planCode === PC.PLAN.free_20210901)
          || (planCode === PC.PLAN.light_20210901)) && (questionCount < CC.ONE));
        if (isPlanNotMatch) {
          return Promise.reject(new QuestionError(QEC.plan_not_match));
        } else if (isCountNotRemain) {
          return Promise.reject(new QuestionError(QEC.count_not_remain));
        } else {
          if ((planCode === PC.PLAN.free_20210901) || (planCode === PC.PLAN.light_20210901)) {
            questionCount--;
          }
          transaction.set(userDocRef, { questionCount }, { merge: true });
          transaction.set(questionDocRef, question, { merge: true });
          return Promise.resolve(true);
        }
      })
    })
      .then(() => this.reportingService.successToast(
        QC.MESSAGE.INFO.PLEASE_WAIT_FOR_ANSWER,
        QC.TITLE.SUCCESS.QUESTION_POSTED))
      .catch(error => this.reportingService.errorReport(
        QC.MESSAGE.ERROR.PLEASE_RETRY,
        QC.TITLE.FAIL.QUESTION_POST_FAILED,
        'postQuestion', error));
    LoadingService.off();
  }

  public replyQuestion = async (replyUid: string, replyQuestion: Question, replyContent: string,
    screenShots: File[]): Promise<void> => {
    LoadingService.on();
    const uid = replyQuestion.uid;
    const title = replyQuestion.title;
    const content = replyQuestion.content;
    const screenShotUrls = replyQuestion.screenShotUrls;
    const postedAt = replyQuestion.postedAt;
    const postedUid = replyQuestion.postedUid;
    const screenShotsFilePaths: string[] = screenShots.map((_, index) =>
      QC.ID.STORAGE.QA + CC.SLASH + QC.ID.STORAGE.ANSWER + CC.SLASH +
      replyQuestion.uid + CC.UNDERSCORE + QC.ID.STORAGE.A + CC.UNDERSCORE + index);
    const replyScreenShotUrls = await this.firestorageService
      .saveScreenShots(screenShots, screenShotsFilePaths);
    const replyAt = FirestoreService.getServerTimestamp();
    const replyFlag = true;
    const question: Question = {
      uid, title, content, screenShotUrls, postedAt, postedUid,
      replyContent, replyScreenShotUrls, replyAt, replyUid, replyFlag
    }

    await this.firestoreService.setDocument([QC.ID.COLLECTION.QUESTIONS, question.uid], question)
      .then(() => this.reportingService.successToast(
        CC.BLANK,
        QC.TITLE.SUCCESS.REPLY_POSTED))
      .catch(error => this.reportingService.errorReport(
        QC.MESSAGE.ERROR.PLEASE_RETRY,
        QC.TITLE.FAIL.REPLY_POST_FAILED,
        'replyQuestion', error));
    LoadingService.off();
  }

  public updateReplyFlag = async (uid: string): Promise<void> => {
    const replyFlag = false;
    await this.firestoreService.setDocument([QC.ID.COLLECTION.QUESTIONS, uid], { replyFlag }, true);
  }

  private makePostUid = (date: Date, uid: string): string => {
    let postUid: string = '';
    postUid += (9999 - date.getFullYear()).toString();
    postUid += (99 - date.getMonth()).toString();
    postUid += (99 - date.getDate()).toString();
    postUid += (99 - date.getHours()).toString();
    postUid += (99 - date.getMinutes()).toString();
    postUid += (99 - date.getSeconds()).toString();
    postUid += (9999 - date.getMilliseconds()).toString();
    postUid += '_' + uid;
    return postUid;
  }

  private makeWhere = (criteria: QuestionSearchCriteria): Where[] => {
    const where: Where[] = [];
    if (criteria.postedUid !== CC.BLANK) {
      where.push({ field: 'postedUid', value: criteria.postedUid });
    }
    if (criteria.replyUid !== CC.BLANK) {
      where.push({ field: 'replyUid', value: criteria.replyUid });
    }
    if (criteria.postedAtMin !== CC.BLANK) {
      where.push({
        field: 'uid', op: CC.DOCUMENT.OP.LTEQ,
        value: this.makePostUid(MomentService.newDate(MomentService
          .getDayStart(criteria.postedAtMin)), CC.BLANK)
      });
    }
    if (criteria.postedAtMax !== CC.BLANK) {
      where.push({
        field: 'uid', op: CC.DOCUMENT.OP.GTEQ,
        value: this.makePostUid(MomentService.newDate(MomentService
          .getDayEnd(criteria.postedAtMax)), CC.BLANK)
      });
    }
    if (criteria.replyAtMin !== CC.BLANK) {
      where.push({
        field: 'replyAt', op: CC.DOCUMENT.OP.GTEQ,
        value: MomentService.getDayStart(criteria.replyAtMin), isDate: true
      });
    }
    if (criteria.replyAtMax !== CC.BLANK) {
      where.push({
        field: 'replyAt', op: CC.DOCUMENT.OP.LTEQ,
        value: MomentService.getDayEnd(criteria.replyAtMax), isDate: true
      });
    }
    if (criteria.onlyNoReply) {
      where.push({ field: 'replyAt', value: null });
    }
    return where;
  }

  public setLastCriteria = (criteria: QuestionSearchCriteria):
    QuestionSearchCriteria => this.lastCriteria = criteria;

  public setLastQuestionList = (questionList: Question[]):
    Question[] => this.lastQuestionList = questionList;

  public getLastCriteria = (): QuestionSearchCriteria =>
    this.lastCriteria || this.getQalistInitialSearchCriteria();

  public getLastQuestionList = (): Question[] =>
    this.lastQuestionList || [];

  public getQalistInitialSearchCriteria = (): QuestionSearchCriteria =>
    new QuestionSearchCriteria({ onlyNoReply: true, limit: 10 });
}
