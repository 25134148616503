import { AttendanceStatus } from "./Attendance";

export enum BookingCellMode {
  open,
  close,
  empty,
  full,
  booked,
  attendance,
  absence,
  other
}

export interface BookingCell {
  mode?: BookingCellMode;
  text?: string;
  class?: string;
  date?: string;
  start?: string;
  end?: string;
  uid?: string;
  key?: string;
  title?: string;
  attendanceStatus?: AttendanceStatus;
}

export class BookingCellFlags {
  isAdminMode: boolean = false;
  isMasqueradeMode: boolean = false;
  isHoliday: boolean = false;
  isRegularClosing: boolean = false;
  isTemporaryClosing: boolean = false;
  isToday: boolean = false;
  isStarted: boolean = false;
  isEnded: boolean = false;
  isLocked: boolean = false;
  isExpired: boolean = false;
  isEmpty: boolean = false;
  isFull: boolean = false;
  isMyBook: boolean = false;
  isAttendance: boolean = false;
  isBookablePlan: boolean = false;
  isOnlineCountRemain: boolean = false;
  isPlanCanceled: boolean = false;
  isPlanCancelAtPeriodEnd: boolean = false;
}
