import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import isEqual from 'lodash/isequal';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { AuthConstants as AC } from 'src/app/constants/auth.constants';
import { BookingConstants as BC } from 'src/app/constants/booking.constants';
import { CommonConstants as CC } from 'src/app/constants/common.constants';
import { MomentConstants as MC } from 'src/app/constants/moment.constants';
import * as PC from 'src/app/constants/plan.constants';
import { BookingCell } from 'src/app/models/class/BookingCell';
import { User } from 'src/app/models/class/User';
import { Course } from 'src/app/models/interface/Course';
import { CourseIndex } from 'src/app/models/interface/CourseIndex';
import { Info } from 'src/app/models/interface/Info';
import { ListCell, ListCellMode } from 'src/app/models/interface/ListCell';
import { AuthService } from 'src/app/services/auth.service';
import { BookingService } from 'src/app/services/booking.service';
import { CourseService } from 'src/app/services/course.service';
import { InfoService } from 'src/app/services/info.service';
import { UserService } from 'src/app/services/user.service';
import { JqueryService } from 'src/app/utilities/static/jquery.service';
import { LoadingService } from 'src/app/utilities/static/loading.service';
import { MomentService } from 'src/app/utilities/static/moment.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {

  private userStateSubscription: Subscription = new Subscription();
  private clockSubscription: Subscription = new Subscription();
  private refreshInterval: number = 1800;
  private refreshCountDown: number = this.refreshInterval;
  public isExpired: boolean = true;
  public clock: string = CC.BLANK;

  public showAllBadges: boolean = false;

  public urlBooking: string = CC.URL.BOOKING;
  public urlQuestion: string = CC.URL.QUESTION;
  public urlCourse: string = CC.URL.COURSE;
  public urlSiteManual: string = CC.URL.STORAGE.PDF.MEMBER_SITE_MANUAL;
  public urlProhibition: string = CC.URL.STORAGE.PDF.PROHIBITION;
  public urlPlan: string = CC.URL.AUTH.PLAN;
  public mode = ListCellMode;

  public bookingListData: ListCell[] = [];
  public courseListData: Course[] = [];
  public infoListData: Info[] = [];
  public bookingDate: string = null;
  public activeBookingData: BookingCell = null;
  public editEnabled: boolean = false;
  public roomKnockEnabled: boolean = false;

  public user: User = null;
  private bookingCell: BookingCell = null;
  private isMasquerade = false;

  public pc = PC.PLAN_EXT;
  public ps = PC.STATUS_EXT;
  public planCode: PC.PLANCODE_EXT = PC.PLAN_EXT.none;
  public planName: string = '';
  public planStatus: PC.PLANSTATUS_EXT = PC.STATUS_EXT.canceled;
  public planExpiration: string = CC.BLANK;
  public cancel_at_period_end: boolean = false;

  public courseInfoTitle: string = CC.BLANK;
  public courseInfoIndex: CourseIndex[] = [];

  public allCourseList: string = CC.COURSE_SORT_TYPE.KEY.ALL;
  public javaCourseList: string = CC.COURSE_SORT_TYPE.KEY.JAVA;
  public phpCourseList: string = CC.COURSE_SORT_TYPE.KEY.PHP;
  public cookieCourseList: string = CC.COURSE_SORT_TYPE.KEY.ALL;

  public iconLock: string = 'fas fa-lock';

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private bookingService: BookingService,
    private infoService: InfoService,
    private courseService: CourseService,
    private cookieService: CookieService
  ) { }

  ngOnDestroy() {
    this.userStateSubscription.unsubscribe();
    this.clockSubscription.unsubscribe();
  }

  ngOnInit() {
    // 自動更新・なりすましからの復帰時
    this.userStateSubscription.unsubscribe();
    this.userStateSubscription = this.authService.userState
      .subscribe(user => {
        if (this.authService.isAuthenticated()) {
          this.init();
        }
      });
    // 通常
    this.init();
  }

  ngAfterViewInit() {
    if (this.user.fmcs !== '' && this.user.fmcs !== undefined) {
      this.userService.updateFmcs(this.user);
      const script = document.createElement('script');
      script.src = 'https://js.felmat.net/fmcv.js?adid=L6508D&uqid=' + this.user.uid;

      const div = document.getElementById('script');
      div.insertAdjacentElement('afterend', script);
    }

    if (this.user.gaw) {
      this.userService.updateGaw(this.user);
      const cookie = this.cookieService.get(AC.GTAG.LIGHT);
      if (!cookie) {
        // Event snippet for 有料プラン購入_全カリキュラム解放 conversion page
        gtag('event', 'conversion', { 'send_to': 'AW-10944845857/lQJSCPmmhswDEKGw9OIo', 'value': 1000.0, 'currency': 'JPY', 'transaction_id': '' });
        this.cookieService.set(AC.GTAG.LIGHT, AC.GTAG.LIGHT, 3650);
      }
    }

    if (this.user.rentracksSend === null && this.user.planCode === this.pc.light_20210901
      && this.user.admission > new Date('2023-07-20')) {
      this.userService.updateRentracksSend(this.user);
      this.user.rentracksSend = new Date();
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.text = `
(function () {
  function loadScriptRTCV(callback) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://www.rentracks.jp/js/itp/rt.track.js?t=' + (new Date()).getTime();
    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          callback();
        };
      };
    } else {
      script.onload = function () {
        callback();
      };
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  loadScriptRTCV(function () {
    _rt.sid = 8382;
    _rt.pid = 11877;
    _rt.price = 0;
    _rt.reward = -1;
    _rt.cname = '';
    _rt.ctel = '';
    _rt.cemail = '';
    _rt.cinfo = '${this.user.uid}';
    rt_tracktag();
  });
}(function () { }));
`;
      const div = document.getElementById('script');
      div.insertAdjacentElement('afterend', script);
    }
  }

  init() {
    this.pingAndSetClock();
    this.user = this.authService.getUser(true);
    this.isMasquerade = this.authService.isMasquerade();
    this.setPlanInfo();
    this.setCookie();
    this.initCourseList();
    Promise.all([
      this.infoService.getInfoListData(this.user),
      this.bookingService.firestoreGetBookingListData(this.user),
      this.courseService.getCourseList(this.user)
    ]).then(([infoListData, bookingListData, courseListData]) => {
      if (!isEqual(this.infoListData, infoListData)) {
        this.infoListData = infoListData;
      }
      if (!isEqual(this.courseListData, courseListData)) {
        this.courseListData = courseListData;
      }
      if (!isEqual(this.bookingListData, bookingListData)) {
        this.bookingListData = bookingListData;
        this.setActiveBooking();
        // 自動更新時のレアケース（２枠連続、１枠目のダイアログ開きっぱなしで自動更新）
        if (this.bookingListData.length) {
          const listCell: ListCell = this.bookingListData
            .find(cell => cell.mode === ListCellMode.active);
          if (listCell !== undefined) {
            this.bookingCell = listCell.data;
            this.bookingDate = listCell.text;
          }
        }
      }
      setTimeout(() => {
        JqueryService.onCollapseShown(CC.ID.BOOKING_LIST_ID, () => {
          JqueryService.showCollapse(CC.ID.BOOKING_LIST_FUTURE_ID);
          JqueryService.offCollapseShown(CC.ID.BOOKING_LIST_ID);
        });
        JqueryService.showCollapse(CC.ID.INFO_ID);
        JqueryService.showCollapse(CC.ID.MENU_ID);
        JqueryService.showCollapse(CC.ID.COURSE_LIST_ID);
        JqueryService.showCollapse(CC.ID.BOOKING_LIST_ID);
        JqueryService.hideCollapse(CC.ID.BOOKING_LIST_PAST_ID);
      }, 100);
    });
  }

  setCookie() {
    if (this.user.fmcs !== '' && this.user.fmcs !== undefined) {
      this.cookieService.set('_fmcs', this.user.fmcs);
      this.cookieService.set('_fmrecs', this.user.fmcs);
    }
  }

  setPlanInfo() {
    this.planCode = this.user.planCode as PC.PLANCODE;
    this.planName = PC.getPlanTitle(this.planCode);
    this.planExpiration = this.user.getExpirationDateTimeString();
    if (this.user.stripeSubscription) {
      this.planStatus = this.user.stripeSubscription.status as PC.PLANSTATUS_EXT;
      this.cancel_at_period_end = this.user.stripeSubscription.cancel_at_period_end;
    }
  }

  showCourseInfoPanel(title: string, course: string) {
    this.courseInfoTitle = title;
    this.courseService.getCourseIndex(course)
      .then(couseIndex => {
        this.courseInfoIndex = couseIndex;
        JqueryService.showModal(CC.ID.COURSE_INFO_PANEL);
      })
  }

  bookingListItemClick(listCell: ListCell) {
    this.pingAndSetClock();
    if ((listCell.mode === ListCellMode.active)
      || ((listCell.mode === ListCellMode.closed)
        && (this.isMasquerade))) {
      this.bookingCell = listCell.data;
      this.bookingDate = listCell.text;
      JqueryService.showModal(CC.ID.ATTENDANCE_PANEL);
    } else if (listCell.mode === ListCellMode.inactive) {
      this.bookingService.bookingListItemClick(listCell.data);
    }
  }

  roomKnockClick() {
    this.bookingService.roomKnock(this.bookingCell);
  }

  editClick() {
    JqueryService.onHiddenModal(CC.ID.ATTENDANCE_PANEL,
      () => this.bookingService.bookingListItemClick(this.bookingCell));
    JqueryService.hideModal(CC.ID.ATTENDANCE_PANEL);
  }

  dummyLoading() {
    LoadingService.dummy(0.6);
  }

  sort(courseList: string) {
    this.dummyLoading();
    this.courseListData.filter(course => {
      return Object.keys(CC.COURSE_SORT_TYPE.LIST[courseList]).includes(course.key);
    }).forEach(course => {
      JqueryService.setDisplay(course.key, 'block');
      if (courseList !== CC.COURSE_SORT_TYPE.KEY.ALL && CC.COURSE_SORT_TYPE.LIST[courseList][course.key] !== 0) {
        JqueryService.addData(course.key, 'number', CC.COURSE_SORT_TYPE.LIST[courseList][course.key]);
        JqueryService.addData(course.key, 'number-show', 'true');
      } else {
        JqueryService.removeData(course.key, 'number');
        JqueryService.addData(course.key, 'number-show', 'false');
      }

    });

    this.courseListData.filter(course => {
      return !Object.keys(CC.COURSE_SORT_TYPE.LIST[courseList]).includes(course.key);
    }).forEach(course => {
      JqueryService.setDisplay(course.key, 'none');
    })

    this.userService.setCourseSortType(courseList);
  }

  initSort(course: string): boolean {
    return Object.keys(CC.COURSE_SORT_TYPE.LIST[this.cookieCourseList]).includes(course);
  }

  initNumber(course: string): number | null {
    if (this.cookieCourseList !== CC.COURSE_SORT_TYPE.KEY.ALL) {
      return CC.COURSE_SORT_TYPE.LIST[this.cookieCourseList][course] !== 0 ? CC.COURSE_SORT_TYPE.LIST[this.cookieCourseList][course] : null;
    }
    return null;
  }

  initNumberIsShow(course: string): boolean {
    return ((this.cookieCourseList !== CC.COURSE_SORT_TYPE.KEY.ALL) && (CC.COURSE_SORT_TYPE.LIST[this.cookieCourseList][course] !== 0));
  }

  private setActiveBooking = () => {
    const activeBooking: ListCell = this.bookingListData
      .find(listCell => listCell.mode === this.mode.active);
    if (activeBooking) {
      this.activeBookingData = activeBooking.data;
    }
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    if (!document.hidden) {
      this.authService.regetAdditionalUserInfo();
    }
  }

  private initCourseList() {
    this.cookieCourseList = this.cookieService.get(CC.COURSE_SORT_TYPE.COOKIE.KEY) || CC.COURSE_SORT_TYPE.KEY.ALL;
  }

  private pingAndSetClock = async () => {
    const authTime: Date = await this.authService.ping();
    const expiration: Date = this.user.expiration;
    this.isExpired = !MomentService.isSameOrBeforeWithDateObject(authTime, expiration);
    this.clock = MomentService.getDateFromDateObject(authTime, MC.DATE.FORMAT.Y_s_M_s_D_r_d_r_b_H_c_m_c_s);
    this.clockSubscription.unsubscribe();
    MomentService.setClock(authTime);
    this.clockSubscription = MomentService.clock
      .subscribe(current => {
        this.isExpired = !MomentService.isSameOrBeforeWithDateObject(current, expiration);
        this.clock = MomentService.getDateFromDateObject(current, MC.DATE.FORMAT.Y_s_M_s_D_r_d_r_b_H_c_m_c_s);
        if (this.activeBookingData !== null) {
          this.editEnabled = MomentService.isAfter(
            MomentService.getMomentFromDateTime(
              this.activeBookingData.date, this.activeBookingData.start));
          this.roomKnockEnabled = MomentService.isContains(
            MomentService.getDateTimeRange(this.activeBookingData.date,
              this.activeBookingData.start, this.activeBookingData.end),
            MomentService.getMomentFromDateObject(current));
        }
        this.refreshCountDown--;
        if (this.refreshCountDown <= 0 || ((current.getSeconds() === 0) &&
          (current.getMinutes() === 0 || current.getMinutes() === BC.TIME.UNIT))) {
          this.refreshCountDown = this.refreshInterval;
          this.authService.regetAdditionalUserInfo();
        }
      });
  }
}
