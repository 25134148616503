export class CommonConstants {
  static readonly ZERO = 0;
  static readonly ONE = 1;
  static readonly TWO = 2;
  static readonly TRUE = 'true';
  static readonly FALSE = 'false';
  static readonly MINUS_ONE = -1;
  static readonly HUNDRED = 100;
  static readonly MILLE = 1000;
  static readonly LINE_BREAK = '\n';
  static readonly BLANK = '';
  static readonly SPACE = ' ';
  static readonly PERIOD = '.';
  static readonly COMMA = ',';
  static readonly COLLON = ':';
  static readonly HYPHEN = '-';
  static readonly UNDERSCORE = '_';
  static readonly HASH = '#';
  static readonly SLASH = '/';
  static readonly PERCENT = '%';
  static readonly BRACKET_L = '[';
  static readonly BRACKET_R = ']';
  static readonly PX = 'px';
  static readonly VH = 'vh';
  static readonly VW = 'vw';
  static readonly SHOW = 'show';
  static readonly HIDE = 'hide';
  static readonly TOGGLE = 'toggle';
  static readonly ASC = 'asc';
  static readonly DESC = 'desc';
  static readonly NEW = 'new';
  static readonly NEW_WINDOW = '_blank';
  static readonly TEXT_WINDOW_FEATURES = 'width=1000, height=700';
  static readonly ENTER = 'Enter';
  static readonly TEXTAREA = 'TEXTAREA';
  static readonly ALPHABETS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  static readonly MAC = 'mac';
  static readonly WIN = 'win';
  static readonly LOADING_DUMMY_WAIT = 0.4;
  static readonly FULL_WIDTH_TILDA = '～';

  static readonly URL = {
    ROOT: '/',
    TRIAL: '/trial',
    DASHBOARD: '/dashboard',
    BOOKING: '/booking',
    QUESTION: '/question',
    COURSE: '/course',
    AUTH: {
      ROOT: '/auth',
      SIGNUP: '/auth/signup',
      SIGNIN: '/auth/signin',
      SIGNOUT: '/auth/signout',
      FORGET: '/auth/forget',
      RESEND: '/auth/resend',
      UPDATE: {
        INFO: '/auth/update/info',
        EMAIL: '/auth/update/email',
        PASSWORD: '/auth/update/password',
      },
      PLAN: '/auth/plan',
    },
    ADMIN: {
      ROOT: '/admin',
      BOOK: '/admin/book',
      QALIST: '/admin/qalist',
      MASTER: {
        HOLIDAYS: '/admin/master/holidays',
        TEMPORARYCLOSURES: '/admin/master/temporaryclosures',
        COURSELIST: '/admin/master/courselist',
        COURSEINDEX: '/admin/master/courseindex',
        INFORMATION: '/admin/master/information',
        ROOMS: '/admin/master/rooms',
      },
      USER: {
        SEARCH: '/admin/user/search',
        DETAIL: '/admin/user/detail',
      },
    },
    STORAGE: {
      PDF: {
        MEMBER_SITE_MANUAL: 'https://firebasestorage.googleapis.com/v0/b/digskill-member.appspot.com/o/document%2F%E3%83%A1%E3%83%B3%E3%83%90%E3%83%BC%E3%82%B5%E3%82%A4%E3%83%88%E5%88%A9%E7%94%A8%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB.pdf?alt=media&token=7a8af09d-1709-4f94-b25d-b03ce1926b2f',
        PROHIBITION: 'https://firebasestorage.googleapis.com/v0/b/digskill-member.appspot.com/o/document%2F%E7%A6%81%E6%AD%A2%E4%BA%8B%E9%A0%85.pdf?alt=media&token=70bbb7cc-acef-4678-8352-e3a13642e54f',
      },
    },
    OTHER: {
      LP: 'https://lp.digskill.net/',
    }
  };

  static readonly TEXT = {
    DASHBOARD: {
      STATUS_CLICK: 'クリックするとテキストが表示されます。',
    },
  };

  static readonly TITLE = {
    SUCCESS: {
      SETDATA: 'データ保存に成功しました',
    },
    FAIL: {
      GETDATA: 'データ取得に失敗しました',
      SETDATA: 'データ保存に失敗しました',
    },
  };

  static readonly MESSAGE = {
    ERROR: {
      PLEASE_RETRY: '再度実行してください。',
    },
  };

  static readonly ID = {
    MASK_ID: 'mask',
    NOW_LOADING_ID: 'now_loading',
    DUMMY_BUTTON_ID: 'dummy_button',
    MAIN_CARD_ID: 'mainCard',
    SIGNUP_CARD_ID: 'signupCard',
    INFO_CARD_ID: 'infoCard',
    MASTER_MAINTENANCE_ID: 'masterMaintenance',
    USER_LIST: 'userList',
    USER_OVERVIEW_PANEL: 'userOverviewPanel',
    USER_INFO: 'userInfo',
    USER_INFO_DETAIL: 'userInfoDetail',
    USER_STRIPE_SUBSCRIPTION: 'userStripeSubscription',
    USER_INFO_ACTION: 'userInfoAction',
    NAV_COLLAPSE: 'navbarSupportedContent',
    ATTENDANCE_PANEL: 'attendancePanel',
    CHAT_INFO_PANEL: 'chatInfoPanel',
    MAIL_INFO_PANEL: 'mailInfoPanel',
    SCHOOL_INFO_PANEL: 'schoolInfoPanel',
    COURSE_INFO_PANEL: 'courseInfoPanel',
    BILLING_GUIDE_PANEL: 'billingGuidePanel',
    SHARE_PANEL: 'sharePanel',
    BOOKING_LIST_ID: 'bookingList',
    BOOKING_LIST_PAST_ID: 'bookingListPast',
    BOOKING_LIST_FUTURE_ID: 'bookingListFuture',
    QUESTION_LIST_ID: 'questionList',
    REPLY_FORM_ID: 'replyForm',
    QUESTION_CARD_ID: 'questionCard',
    QUESTION_ID: 'question',
    COURSE_LIST_ID: 'courseList',
    INFO_ID: 'info',
    ONLINE_MENU_ID: 'onlineMenu',
    MENU_ID: 'menu',
    MODAL_FORM_ID: 'bookingModal',
    MODAL_FORM_BOOKING_START_ID: 'bookingStart',
    MODAL_FORM_BOOKING_END_ID: 'bookingEnd',
    MODAL_CONFIRM_SAVE_ID: 'confirmSave',
    MODAL_CONFIRM_CANCEL_ID: 'confirmCancel',
    COURSE: 'course',
    COURSE_MENU: 'course_menu',
    COURSE_DISPLAY: 'course_display',
    DISPLAY_IFRAME: 'display_iframe',
    STATUS_BAR_ONGOING: 'statusBarOngoing',
    STATUS_BAR_COMPLETED: 'statusBarCompleted',
    STATUS_BAR_NONE: 'statusBarNone',
    FORM: {
      VALIDATION_WRAP: 'validationWrap',
      UID: 'uid',
      EMAIL: 'email',
      PASSWORD: 'password',
      PASSWORD_CONFIRM: 'passwordConfirm',
      OLD_PASSWORD: 'oldPassword',
      NAME: 'name',
      COURSE_LIST_TYPE: 'courseListType',
      PLAN_CODE: 'planCode',
      CANCEL_AT_PERIOD_END: 'cancelAtPeriodEnd',
      STATUS: 'status',
      CURRENT_EMPLOYMENT_TYPE: 'currentEmploymentType',
      PREFECTURE: 'prefecture',
      BIRTHDATE: 'birthdate',
      ADMISSION: 'admission',
      ADMISSION_MIN: 'admissionMin',
      ADMISSION_MAX: 'admissionMax',
      PURCHASE: 'purchase',
      PURCHASE_MIN: 'purchaseMin',
      PURCHASE_MAX: 'purchaseMax',
      EXPIRATION: 'expiration',
      EXPIRATION_MIN: 'expirationMin',
      EXPIRATION_MAX: 'expirationMax',
      CONTENT: 'content',
      SELECTED_QUESTION_CONTENT: 'selectedQuestionContent',
      POSTED_AT_MIN: 'postedAtMin',
      POSTED_AT_MAX: 'postedAtMax',
      REPLY_AT_MIN: 'replyAtMin',
      REPLY_AT_MAX: 'replyAtMax',
      ONLY_NO_REPLY: 'onlyNoReply',
    },
    CRITERIA: {
      USE_UID: 'useUid',
      USE_NAME: 'useName',
      USE_EMAIL: 'useEmail',
      USE_COURSE_LIST_TYPE: 'useCourseListType',
      USE_PLAN_CODE: 'usePlanCode',
      USE_CANCEL_AT_PERIOD_END: 'useCancelAtPeriodEnd',
      USE_STATUS: 'useStatus',
      USE_ADMISSION: 'useAdmission',
      USE_PURCHASE: 'usePurchase',
      USE_EXPIRATION: 'useExpiration',
    },
  };

  static readonly EVENT = {
    LOAD: 'load',
    MESSAGE: 'message',
    INPUT: 'input',
    CLICK: 'click',
    SHOWN_BS_COLLAPSE: 'shown.bs.collapse',
    HIDDEN_BS_COLLAPSE: 'hidden.bs.collapse',
    SHOWN_BS_MODAL: 'shown.bs.modal',
    HIDDEN_BS_MODAL: 'hidden.bs.modal',
    HIDDEN_BS_CONFIRMATION: 'hidden.bs.confirmation',
    WINDOW_RESIZE: 'window:resize',
    EVENT: '$event',
  };

  static readonly HTML = {
    DIV: 'div',
    SPAN: 'span',
    UL: 'ul',
    LI: 'li',
    I: 'i',
    TAG: {
      BR: '<br>',
    }
  };

  static readonly CLASS = {
    ROOT: 'root',
    PARENT: 'parent',
    CURRENT: 'current',
    OPEN: 'open',
    COLLAPSE: 'collapse',
    WAS_VALIDATED: ' was-validated',
    CLICKABLE: ' clickable',
    TEXT_LEFT: ' text-left',
    SPLIT_HANDLE: 'split_handle',
    IGNORE_LIST_CLICK: 'ignoreListClick',
    DASHBOARD: {
      ACTIVE: 'active_item',
      INACTIVE: 'inactive_item',
      CLOSED: 'closed_item',
      INFO: {
        SUCCESS: 'alert-success',
        INFO: 'alert-info',
        WARNING: 'alert-warning',
        DANGER: 'alert-danger',
        PRIMARY: 'alert-primary',
        SECONDARY: 'alert-secondary',
      },
    },
  };

  static readonly COLORS = {
    SELECT_SELECTED: '#111111',
    PLACEHOLDER: '#6c757d',
  };

  static readonly STYLES = {
    DISPLAY: {
      NONE: 'none',
      FLEX: 'flex',
      BLOCK: 'block',
    },
  };

  static readonly CSS = {
    DISPLAY: 'display',
    COLOR: 'color',
    WIDTH: 'width',
    HEIGHT: 'height',
    TRANSFORM: 'transform',
    ROTATE_DEG_S: 'rotate(',
    ROTATE_DEG_E: 'deg)',
  };

  static readonly COLLECTION = {
    KEY: {
      USERS: 'users',
      COURSE_STATUS: 'courseStatus',
      BOOKINGS: 'bookings',
      TIMES: 'times',
      HOLIDAYS: 'holidays',
      TEMPORARY_CLOSURE: 'temporaryClosures',
      COURSE_LIST: 'courseList',
      VERSIONS: 'versions',
      COURSE_INDEX: 'courseIndex',
      ROOMS: 'rooms',
      INFORMATION: 'information',
      SHARE: 'share',
    },
    BOOKING_KEY_FORMAT: 'YYYYMMDDHHmmssSSS',
  };

  static readonly DOCUMENT = {
    KEY: {
      DATE: 'date',
      TIME: 'time',
      USERS: 'users',
      UID: 'uid',
      NAME: 'name',
      EMAIL: 'email',
      COURSE_LIST_TYPE: 'courseListType',
      PLAN_CODE: 'planCode',
      CANCEL_AT_PERIOD_END: 'stripeSubscription.cancel_at_period_end',
      STATUS: 'stripeSubscription.status',
      BIRTHDATE: 'birthdate',
      ADMISSION: 'admission',
      PURCHASE: 'purchase',
      EXPIRATION: 'expiration',
      CURRENT_VERSION: 'currentVersion',
      COURSE_LIST: 'courseList',
      COURSE_INDEX: 'courseIndex',
      ROOM_ID: 'roomId',
      ROOM_INFO: 'roomInfo',
      ROOM_ASSIGN: 'roomAssign',
      ATTENDANCE: 'attendance',
      ATTENDANCE_STATUS: 'status',
      ATTENDANCE_STATUS_ENTERED_AT: 'statusEnteredAt',
      ATTENDANCE_STATUS_ENTERED_USER: 'statusEnteredUser',
      ONLINE_COUNT: 'onlineCount',
      QUESTION_COUNT: 'questionCount',
      CSV: 'csv',
    },
    OP: {
      AC: 'array-contains',
      GTEQ: '>=',
      GT: '>',
      LTEQ: '<=',
      LT: '<',
      EQ: '==',
      F8FF: '\uf8ff',
    },
  };

  static readonly READ_MORE = {
    MARK: '[readmore]',
    CLASS: 'read_more',
    OPEN_CLASS: 'open',
    START: '<span onclick="$(this).toggleClass(\'open\')" class="read_more clickable btn btn-sm btn-outline-danger"></span><div>',
    END: '<div>',
  };

  static readonly CONFIRM_DEFAULTS = {
    sanitize: false, sanitizeFn: (content => content),
    placement: 'bottom', html: true, trigger: 'manual',
    btnOkLabel: 'はい', btnOkClass: 'btn btn-danger',
    btnCancelLabel: 'いいえ', btnCancelClass: 'btn btn-success'
  };

  static readonly COURSE_LIST_TYPE = {
    PULLDOWN: [
      { value: 'dev_eng', text: '開発エンジニア', },
      { value: 'infra_eng', text: 'インフラエンジニア', },
    ],
  };

  static readonly SAMPLE_STATUS = {
    ALL: 100,
    ONGOING: 60,
    COMPLETED: 30
  }

  static readonly COURSE_SORT_TYPE = {
    KEY: {
      ALL: 'all',
      JAVA: 'java',
      PHP: 'php'
    },
    LIST: {
      'all': { 'introduction': 0, 'php-basic': 0, 'java-basic': 0, 'html-css': 0, 'php-web-beginner': 0, 'java-jsp': 0, 'sql': 0, 'php-web-middle': 0, 'java-servlet': 0, 'git': 0, 'php-laravel': 0, 'java-springboot': 0, 'setup': 0, 'python': 0, 'react': 0, 'python-ml-basic': 0 },
      'java': { 'introduction': 0, 'java-basic': 1, 'html-css': 2, 'java-jsp': 3, 'sql': 4, 'java-servlet': 5, 'git': 6, 'java-springboot': 7, 'setup': 0 },
      'php': { 'introduction': 0, 'php-basic': 1, 'html-css': 2, 'php-web-beginner': 3, 'sql': 4, 'php-web-middle': 5, 'git': 6, 'php-laravel': 7, 'setup': 0 },
    },
    COOKIE: {
      KEY: 'cst',
      TRIAL_KEY: 'tcst',
      EXPIRATION: 3650,
      PATH: '/',
    }
  }

  static readonly CUSTOM_LIGHT_BOX = {
    ZOOM_COUNT: 3
  }

  static readonly IS_BLANK = (test: string): boolean => test === CommonConstants.BLANK;
}
