import { Injectable } from '@angular/core';
import { DocumentReference, DocumentSnapshot } from '@angular/fire/firestore';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { AdminConstants as AC } from 'src/app/constants/admin.constants';
import { CommonConstants as CC } from 'src/app/constants/common.constants';
import { MomentConstants as MC } from 'src/app/constants/moment.constants';
import * as PC from 'src/app/constants/plan.constants';
import { AttendanceStatus } from 'src/app/models/class/Attendance';
import { BookingCell } from 'src/app/models/class/BookingCell';
import { User } from 'src/app/models/class/User';
import { UserSearchCriteria } from 'src/app/models/class/UserSearchCriteria';
import { Rooms } from 'src/app/models/interface/RoomAssign';
import { Where } from 'src/app/models/interface/Where';
import { AuthService } from 'src/app/services/auth.service';
import { RoomsService } from 'src/app/services/rooms.service';
import { FirestoreService } from 'src/app/utilities/injectable/firestore.service';
import { ReportingService } from 'src/app/utilities/injectable/reporting.service';
import { LoadingService } from 'src/app/utilities/static/loading.service';
import { MomentService } from 'src/app/utilities/static/moment.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private lastCriteria: UserSearchCriteria = null;
  private lastUserList: User[] = null;
  private lastSortKey: string = null;

  private lastBookingCell: BookingCell = null;
  private lastBookingUserList: User[] = null;
  private lastBookingSortKey: string = null;

  constructor(
    private firestoreService: FirestoreService,
    private cookieService: CookieService,
    private reportingService: ReportingService,
    private authService: AuthService,
    private roomService: RoomsService,
    private sanitizer: DomSanitizer
  ) { }

  /**
   * firestoreからユーザーを検索する
   *
   * @param criteria 入力された検索条件
   * @returns 検索されたUserの配列
   */
  public firestoreSearchUser = async (criteria: UserSearchCriteria):
    Promise<User[]> => {
    LoadingService.on();
    const userList: User[] = [];
    if (criteria.useUid && !CC.IS_BLANK(criteria.uid)) {
      await this.firestoreService.getDocument(
        [CC.COLLECTION.KEY.USERS, criteria.uid])
        .then(doc => {
          if (doc.exists()) {
            const user: User = new User();
            user.setAdditionalUserInfo(doc);
            userList.push(user);
          }
        })
        .catch(error => this.reportingService.errorReport(
          AC.MESSAGE.ERROR.PLEASE_RETRY,
          AC.TITLE.FAIL.GETDATA,
          'firestoreSearchUser', error));
    } else {
      const whereClauses: Where[] = [].concat(
        this.getWhereClauseByCourseListType(criteria),
        this.getWhereClauseByPlanCode(criteria),
        this.getWhereClauseByCancelAtPeriodEnd(criteria),
        this.getWhereClauseByStatus(criteria),
        this.getWhereClauseByAdmission(criteria),
        this.getWhereClauseByPurchase(criteria),
        this.getWhereClauseByExpiration(criteria),
      );
      const forwardMatches: Where[] = [].concat(
        this.getForwardMatchByName(criteria),
        this.getForwardMatchByEmail(criteria)
      );
      await this.firestoreService.searchDocumentWithForwardMatches(
        [CC.COLLECTION.KEY.USERS], whereClauses, forwardMatches)
        .then(snapshot => snapshot.docs.forEach(doc => {
          const user: User = new User();
          user.setAdditionalUserInfo(doc);
          userList.push(user);
        }))
        .catch(error => this.reportingService.errorReport(
          AC.MESSAGE.ERROR.PLEASE_RETRY,
          AC.TITLE.FAIL.GETDATA,
          'firestoreSearchUser', error));
    }
    LoadingService.off();
    return userList;
  };

  public getUserByUid = async (uid: string): Promise<User | null> =>
    await this.firestoreService
      .getDocument([CC.COLLECTION.KEY.USERS, uid])
      .then(doc => {
        const user: User = new User();
        user.setAdditionalUserInfo(doc);
        return user;
      })
      .catch(error => {
        this.reportingService.errorReport(
          AC.MESSAGE.ERROR.PLEASE_RETRY,
          AC.TITLE.FAIL.GETDATA,
          'getUserByUid', error);
        return null
      });

  /**
   * コースリストのソートボタン操作時のcookie処理
   * 
   * @param courseSortType コースリストのソートタイプ
   * @param isTrial トライアル画面か否か
   */
  public setCourseSortType =
    (courseSortType: string = CC.COURSE_SORT_TYPE.KEY.ALL, isTrial: boolean = false): void => {
      if (isTrial) {
        this.cookieService.set(
          CC.COURSE_SORT_TYPE.COOKIE.TRIAL_KEY,
          courseSortType,
          CC.COURSE_SORT_TYPE.COOKIE.EXPIRATION,
          CC.COURSE_SORT_TYPE.COOKIE.PATH);
      } else {
        this.cookieService.set(
          CC.COURSE_SORT_TYPE.COOKIE.KEY,
          courseSortType,
          CC.COURSE_SORT_TYPE.COOKIE.EXPIRATION,
          CC.COURSE_SORT_TYPE.COOKIE.PATH);
      }
    };

  /**
   * cookieに保存されたコースリストのソートタイプを取得
   * 
   * @param isTrial トライアル画面か否か
   * @returns ソートタイプ
   */
  public getCourseSortType = (isTrial: boolean = false): string => {
    if (isTrial) {
      return this.cookieService.get(CC.COURSE_SORT_TYPE.COOKIE.TRIAL_KEY);
    } else {
      return this.cookieService.get(CC.COURSE_SORT_TYPE.COOKIE.KEY);
    }
  };

  /**
   * 氏名（前方一致）で絞り込む検索条件を作成する
   *
   * @param criteria 入力された検索条件
   * @returns 検索条件の配列
   */
  private getForwardMatchByName = (criteria: UserSearchCriteria):
    Where[] => (criteria.useName && !CC.IS_BLANK(criteria.name)) ?
      [{ field: CC.DOCUMENT.KEY.NAME, value: criteria.name }] : [];

  /**
   * メールアドレス（前方一致）で絞り込む検索条件を作成する
   *
   * @param criteria 入力された検索条件
   * @returns 検索条件の配列
   */
  private getForwardMatchByEmail = (criteria: UserSearchCriteria):
    Where[] => (criteria.useEmail && !CC.IS_BLANK(criteria.email)) ?
      [{ field: CC.DOCUMENT.KEY.EMAIL, value: criteria.email }] : [];

  /**
   * コースリストタイプ（完全一致）で絞り込む検索条件を作成する
   *
   * @param criteria 入力された検索条件
   * @returns 検索条件の配列
   */
  private getWhereClauseByCourseListType = (criteria: UserSearchCriteria):
    Where[] => (criteria.useCourseListType && !CC.IS_BLANK(criteria.courseListType)) ?
      [{ field: CC.DOCUMENT.KEY.COURSE_LIST_TYPE, value: criteria.courseListType }] : [];

  /**
   * プランコード（完全一致）で絞り込む検索条件を作成する
   *
   * @param criteria 入力された検索条件
   * @returns 検索条件の配列
   */
  private getWhereClauseByPlanCode = (criteria: UserSearchCriteria):
    Where[] => (criteria.usePlanCode && !CC.IS_BLANK(criteria.planCode)) ?
      [{ field: CC.DOCUMENT.KEY.PLAN_CODE, value: criteria.planCode }] : [];

  /**
   * 期末解約（完全一致）で絞り込む検索条件を作成する
   *
   * @param criteria 入力された検索条件
   * @returns 検索条件の配列
   */
  private getWhereClauseByCancelAtPeriodEnd = (criteria: UserSearchCriteria):
    Where[] => (criteria.useCancelAtPeriodEnd && !CC.IS_BLANK(criteria.cancelAtPeriodEnd)) ?
      [{ field: CC.DOCUMENT.KEY.CANCEL_AT_PERIOD_END, value: criteria.cancelAtPeriodEnd, isBoolean: true }] : [];

  /**
   * プラン状態（完全一致）で絞り込む検索条件を作成する
   *
   * @param criteria 入力された検索条件
   * @returns 検索条件の配列
   */
  private getWhereClauseByStatus = (criteria: UserSearchCriteria):
    Where[] => (criteria.useStatus && !CC.IS_BLANK(criteria.status)) ?
      [{ field: CC.DOCUMENT.KEY.STATUS, value: criteria.status }] : [];

  /**
   * 入学日時（範囲）で絞り込む検索条件を作成する
   *
   * @param criteria 入力された検索条件
   * @returns 検索条件の配列
   */
  private getWhereClauseByAdmission = (criteria: UserSearchCriteria):
    Where[] => (criteria.useAdmission &&
      !CC.IS_BLANK(criteria.admissionMin + criteria.admissionMax)) ?
      this.firestoreService.getRangeWhereClause(
        CC.DOCUMENT.KEY.ADMISSION,
        CC.IS_BLANK(criteria.admissionMin) ? CC.BLANK
          : MomentService.getDayStart(criteria.admissionMin),
        CC.IS_BLANK(criteria.admissionMax) ? CC.BLANK
          : MomentService.getDayEnd(criteria.admissionMax))
        .map(where => Object.assign(where, { isDate: true })) : [];

  /**
   * プラン購入日時（範囲）で絞り込む検索条件を作成する
   *
   * @param criteria 入力された検索条件
   * @returns 検索条件の配列
   */
  private getWhereClauseByPurchase = (criteria: UserSearchCriteria):
    Where[] => (criteria.usePurchase &&
      !CC.IS_BLANK(criteria.purchaseMin + criteria.purchaseMax)) ?
      this.firestoreService.getRangeWhereClause(
        CC.DOCUMENT.KEY.PURCHASE,
        CC.IS_BLANK(criteria.purchaseMin) ? CC.BLANK
          : MomentService.getDayStart(criteria.purchaseMin),
        CC.IS_BLANK(criteria.purchaseMax) ? CC.BLANK
          : MomentService.getDayEnd(criteria.purchaseMax))
        .map(where => Object.assign(where, { isDate: true })) : [];

  /**
   * プラン終了日時（範囲）で絞り込む検索条件を作成する
   *
   * @param criteria 入力された検索条件
   * @returns 検索条件の配列
   */
  private getWhereClauseByExpiration = (criteria: UserSearchCriteria):
    Where[] => (criteria.useExpiration &&
      !CC.IS_BLANK(criteria.expirationMin + criteria.expirationMax)) ?
      this.firestoreService.getRangeWhereClause(
        CC.DOCUMENT.KEY.EXPIRATION,
        CC.IS_BLANK(criteria.expirationMin) ? CC.BLANK
          : MomentService.getDayStart(criteria.expirationMin),
        CC.IS_BLANK(criteria.expirationMax) ? CC.BLANK
          : MomentService.getDayEnd(criteria.expirationMax))
        .map(where => Object.assign(where, { isDate: true })) : [];

  /**
   * firestoreから予約者リストを取得する
   *
   * @param cell 予約情報セル
   * @returns 予約しているユーザー情報の配列
   */
  public firestoreGetBookingUserList = async (cell: BookingCell):
    Promise<User[]> => {
    LoadingService.on();
    const userList: User[] = [];
    let rooms: Rooms = {};
    let uids: string[] = [];
    const keys: Map<string, string> = new Map<string, string>();
    await Promise.all([
      this.roomService.getAssignedRooms(cell.date, cell.start),
      this.firestoreService.getDocument(
        [CC.COLLECTION.KEY.BOOKINGS, cell.date, CC.COLLECTION.KEY.TIMES, cell.start]),
    ])
      .then(([rooms_, timeDoc]) => {
        rooms = rooms_;
        uids = timeDoc.get(CC.COLLECTION.KEY.USERS);
        const docRefs: DocumentReference[] =
          uids.map(uid => this.firestoreService
            .getDocumentReference([CC.COLLECTION.KEY.USERS, uid]));
        uids.forEach(uid => keys.set(uid, timeDoc.get(uid)));

        const promises: Promise<DocumentSnapshot>[] = [];
        docRefs.forEach(docRef => promises.push(
          this.firestoreService.getDocumentFromReference(docRef)));
        return Promise.all(promises);
      })
      .then(docs => {
        docs.forEach(doc => {
          const user: User = new User();
          user.setAdditionalUserInfo(doc);
          user.key = keys.get(user.uid);
          user.roomId = this.roomService.getAssignedRoomId(rooms, user.uid, user.key);
          user.attendance = user.getAttendance(user.key);
          userList.push(user);
        });
      })
      .catch(error => this.reportingService.errorReport(
        AC.MESSAGE.ERROR.PLEASE_RETRY,
        AC.TITLE.FAIL.GETDATA,
        'firestoreSearchUser', error));
    LoadingService.off();
    return userList;
  };

  public updateOnlineCount = async (user: User, amount: number):
    Promise<number> => this.updateCounts(user, CC.DOCUMENT.KEY.ONLINE_COUNT, amount);

  public updateQuestionCount = async (user: User, amount: number):
    Promise<number> => this.updateCounts(user, CC.DOCUMENT.KEY.QUESTION_COUNT, amount);

  private updateCounts = async (user: User, key: string, amount: number): Promise<number> => {
    let count: number = 0;
    const docRef: DocumentReference = this.firestoreService
      .getDocumentReference([CC.COLLECTION.KEY.USERS, user.uid]);
    await this.firestoreService.runTransaction(transaction => {
      return transaction.get(docRef).then(doc => {
        count = (doc.get(key) - 0) + amount;
        transaction.set(docRef, { [key]: count }, { merge: true });
        return Promise.resolve(true);
      })
    })
      .then(() => this.reportingService.successToast(
        CC.BLANK, CC.TITLE.SUCCESS.SETDATA))
      .catch(error => this.reportingService.errorReport(
        CC.MESSAGE.ERROR.PLEASE_RETRY,
        CC.TITLE.FAIL.SETDATA,
        'updateCounts', error));
    return count;
  };

  public toTrainee = async (user: User):
    Promise<void> =>
    await this.firestoreService.setDocument(
      [CC.COLLECTION.KEY.USERS, user.uid],
      { planCode: PC.PLAN_EXT.trainee })
      .then(() => this.reportingService.successToast(
        CC.BLANK, CC.TITLE.SUCCESS.SETDATA))
      .catch(error => this.reportingService.errorReport(
        CC.MESSAGE.ERROR.PLEASE_RETRY,
        CC.TITLE.FAIL.SETDATA,
        'toTrainee', error));

  public updateMemo = (user: User):
    Promise<void> =>
    this.firestoreService.setDocument(
      [CC.COLLECTION.KEY.USERS, user.uid],
      { memo: user.memo })
      .then(() => this.reportingService.successToast(
        CC.BLANK, CC.TITLE.SUCCESS.SETDATA))
      .catch(error => this.reportingService.errorReport(
        CC.MESSAGE.ERROR.PLEASE_RETRY,
        CC.TITLE.FAIL.SETDATA,
        'updateMemo', error));

  public updateCourseListVersion = (user: User):
    Promise<void> =>
    this.firestoreService.setDocument(
      [CC.COLLECTION.KEY.USERS, user.uid],
      { courseListVersion: user.courseListVersion })
      .then(() => this.reportingService.successToast(
        CC.BLANK, CC.TITLE.SUCCESS.SETDATA))
      .catch(error => this.reportingService.errorReport(
        CC.MESSAGE.ERROR.PLEASE_RETRY,
        CC.TITLE.FAIL.SETDATA,
        'updateCourseListVersion', error));

  public updateAttendanceStatus = (user: User):
    Promise<void> =>
    this.firestoreService.setDocument(
      [CC.COLLECTION.KEY.USERS, user.uid],
      {
        [user.key]:
          (user.attendance.status === AttendanceStatus.none)
            ? {
              [CC.DOCUMENT.KEY.ATTENDANCE_STATUS]: FirestoreService.getDeleteField(),
              [CC.DOCUMENT.KEY.ATTENDANCE_STATUS_ENTERED_AT]: FirestoreService.getDeleteField(),
              [CC.DOCUMENT.KEY.ATTENDANCE_STATUS_ENTERED_USER]: FirestoreService.getDeleteField()
            }
            : {
              [CC.DOCUMENT.KEY.ATTENDANCE_STATUS]: user.attendance.status,
              [CC.DOCUMENT.KEY.ATTENDANCE_STATUS_ENTERED_AT]: FirestoreService.getServerTimestamp(),
              [CC.DOCUMENT.KEY.ATTENDANCE_STATUS_ENTERED_USER]: this.authService.getUser().name,
            }
      })
      .then(() => this.reportingService.successToast(
        CC.BLANK, CC.TITLE.SUCCESS.SETDATA))
      .catch(error => this.reportingService.errorReport(
        CC.MESSAGE.ERROR.PLEASE_RETRY,
        CC.TITLE.FAIL.SETDATA,
        'updateAttendanceStatus', error));

  public updateFmcs = (user: User):
    Promise<void> =>
    this.firestoreService.setDocument(
      [CC.COLLECTION.KEY.USERS, user.uid],
      { fmcs: '' })
      .then()
      .catch();

  public updateGaw = (user: User):
    Promise<void> =>
    this.firestoreService.setDocument(
      [CC.COLLECTION.KEY.USERS, user.uid],
      { gaw: false })
      .then()
      .catch();

  public updateRentracksSend = (user: User):
    Promise<void> =>
    this.firestoreService.setDocument(
      [CC.COLLECTION.KEY.USERS, user.uid],
      { rentracksSend: FirestoreService.getServerTimestamp() })
      .then()
      .catch();

  /**
   * 入力された検索条件を保存する
   *
   * - ユーザー検索画面用
   * @param lastCriteria 入力された検索条件
   */
  public setLastCriteria = (lastCriteria: UserSearchCriteria):
    UserSearchCriteria => this.lastCriteria = lastCriteria;

  /**
   * 前回画面表示時の入力された検索条件を取得する
   *
   * - ユーザー検索画面用
   * @returns 初回取得時は空のインスタンス
   */
  public getLastCriteria = ():
    UserSearchCriteria => this.lastCriteria || new UserSearchCriteria();

  /**
   * ユーザー検索結果を保存する
   *
   * - ユーザー検索画面用
   * @param lastUserList ユーザー情報の配列
   */
  public setLastUserList = (lastUserList: User[]):
    User[] => this.lastUserList = lastUserList;

  /**
   * 前回画面表示時のユーザー検索結果を取得する
   *
   * - ユーザー検索画面用
   * @returns 初回取得時は空の配列
   */
  public getLastUserList = ():
    User[] => this.lastUserList || [];

  /**
   * ソートキーを保存する
   *
   * - ユーザー検索画面用
   * @param sortKey ソートキー
   */
  public setLastSortKey = (sortKey: string):
    string => this.lastSortKey = sortKey;

  /**
   * 前回画面表示時のソートキーを取得する
   *
   * - ユーザー検索画面用
   * @returns 初回取得時は入学日時降順
   */
  public getLastSortKey = ():
    string => this.lastSortKey
    || CC.DOCUMENT.KEY.ADMISSION + CC.UNDERSCORE + CC.DESC;

  public setLastBookingCell = (lastBookingCell: BookingCell):
    BookingCell => this.lastBookingCell = lastBookingCell;

  public getLastBookingCell = ():
    BookingCell => this.lastBookingCell || {};

  public setLastBookingUserList = (lastBookingUserList: User[]):
    User[] => this.lastBookingUserList = lastBookingUserList;

  public getLastBookingUserList = ():
    User[] => this.lastBookingUserList || [];

  public setLastBookingSortKey = (sortKey: string):
    string => this.lastBookingSortKey = sortKey;

  public getLastBookingSortKey = ():
    string => this.lastBookingSortKey
    || CC.DOCUMENT.KEY.ROOM_ID + CC.UNDERSCORE + CC.ASC;

  public getExportCSV = (userList: User[]): SafeResourceUrl => {
    if (userList.length === 0) {
      return '#';
    }
    const keys: string[] = [
      'uid', 'email', 'name', 'planCode',
      'courseListType', 'courseListVersion',
      'admission', 'purchase', 'expiration',
      'questionCount', 'onlineCount',
      'isTemporaryPassword', 'navigateTo',
      'a8', 'fmcs', 'gaw'
    ];
    const stripeKeys: string[] = [
      'customer', 'subscription', 'number',
      'status', 'cancel_at_period_end'
    ];
    const content = [
      keys.concat(stripeKeys).join(','),
      userList.map(user => {
        const items: string[] = [];
        keys.forEach(key => {
          let item = user[key];
          if (item instanceof Date) {
            item = MomentService.getDateFromDateObject(item,
              MC.DATE.FORMAT.Y_s_M_s_D_r_d_r_b_H_c_m_c_s);
          }
          items.push(item);
        });
        if (user['stripeSubscription']) {
          stripeKeys.forEach(key => items.push(user['stripeSubscription'][key]));
        }
        return items.join(',');
      }).join('\n')
    ].join('\n');
    const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
    const blob = new Blob([bom, content], { 'type': 'text/csv' });
    return this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }
}
