import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import isNull from 'lodash/isnull';
import { Subscription } from 'rxjs';
import { ModalComponent } from 'src/app/components/booking/modal/modal.component';
import { BookingCalenderControllMode, BookingConstants as BC } from 'src/app/constants/booking.constants';
import { CommonConstants as CC } from 'src/app/constants/common.constants';
import { MomentConstants as MC } from 'src/app/constants/moment.constants';
import * as PC from 'src/app/constants/plan.constants';
import { BookingCell } from 'src/app/models/class/BookingCell';
import { User } from 'src/app/models/class/User';
import { Column } from 'src/app/models/interface/Column';
import { AuthService } from 'src/app/services/auth.service';
import { BookingService } from 'src/app/services/booking.service';
import { JqueryService } from 'src/app/utilities/static/jquery.service';
import { MomentService } from 'src/app/utilities/static/moment.service';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.sass']
})
export class BookingComponent implements OnInit, OnDestroy {

  private clockSubscription: Subscription = new Subscription();
  private refreshInterval: number = 1800;
  private refreshCountDown: number = this.refreshInterval;
  public isExpired: boolean = true;
  public clock: string = CC.BLANK;

  @ViewChild(ModalComponent, { static: true })
  private modalComponent: ModalComponent;

  public urlDashboard: string = CC.URL.DASHBOARD;
  public urlPlan: string = CC.URL.AUTH.PLAN;
  public mode = BookingCalenderControllMode;

  public displayDate: string = CC.BLANK;
  public dateMin: string = CC.BLANK;
  public dateMax: string = CC.BLANK;
  public data: Column[][] = null;

  public planName: string = CC.BLANK;
  public planExpiration: string = CC.BLANK;
  public isFreePlan: boolean = false;
  public isOnlineCountNotRemain: boolean = false;
  public isPlanNotMatch: boolean = false;
  public isPlanCancelAtPeriodEnd: boolean = false;

  private currentMonday: string = CC.BLANK;
  private user: User = null;

  constructor(
    private authService: AuthService,
    private bookingService: BookingService
  ) { }

  ngOnDestroy() {
    this.clockSubscription.unsubscribe();
  }

  ngOnInit() {
    this.user = this.authService.getUser(true);
    this.pingAndSetClock();
    this.setPlanInfo();
    this.modalComponent.modalState.subscribe(
      () => this.displayDateChanged(BookingCalenderControllMode.force));
    const modalOpenData: BookingCell = this.bookingService.getModalOpenData();
    this.displayDate = isNull(modalOpenData)
      ? this.bookingService.getLastDisplayDate() : modalOpenData.date;
    this.dateMin = this.bookingService.getDate(-60);
    this.dateMax = this.bookingService.getDate(60);
    this.currentMonday = this.bookingService.getMonday(this.displayDate);
    this.bookingService.getData(this.user, this.currentMonday)
      .then(data => {
        this.data = data;
        setTimeout(() => {
          JqueryService.showCollapse(CC.ID.MAIN_CARD_ID);
        }, 100);
        if (!isNull(modalOpenData)) {
          this.bookingService.setLastDisplayDate(modalOpenData.date);
          this.cellClick(modalOpenData);
        }
      });
  }

  setPlanInfo() {
    const planStatus: PC.PLANSTATUS = this.user.stripeSubscription ?
      this.user.stripeSubscription.status as PC.PLANSTATUS : PC.STATUS.active;
    this.planName = PC.getPlanTitle(this.user.planCode as PC.PLANCODE);
    this.planExpiration = this.user.getExpirationDateTimeString();
    this.isFreePlan = this.user.planCode === PC.PLAN.free_20210901;
    this.isOnlineCountNotRemain = ((this.user.onlineCount < CC.ONE)
      && (planStatus !== PC.STATUS.canceled)
      && (this.user.planCode === PC.PLAN.free_20210901
        || this.user.planCode === PC.PLAN.light_20210901));
    this.isPlanNotMatch = ((planStatus === PC.STATUS.canceled)
      || !(this.user.planCode === PC.PLAN.free_20210901
        || this.user.planCode === PC.PLAN.light_20210901));
    this.isPlanCancelAtPeriodEnd = this.user.stripeSubscription ?
      this.user.stripeSubscription.cancel_at_period_end : false;
  }

  displayDateChanged(mode: BookingCalenderControllMode): void {
    this.authService.regetAdditionalUserInfo()
      .then(result => {
        this.setPlanInfo();
        let isReload: boolean = false;
        let isForce: boolean = false;
        switch (mode) {
          case BookingCalenderControllMode.today:
            this.displayDate = this.bookingService.getDate();
            break;
          case BookingCalenderControllMode.next:
            this.displayDate = this.bookingService.getDate(7, this.displayDate);
            break;
          case BookingCalenderControllMode.prev:
            this.displayDate = this.bookingService.getDate(-7, this.displayDate);
            break;
          case BookingCalenderControllMode.reload:
            isReload = true;
            break;
          case BookingCalenderControllMode.force:
            isForce = true;
            break;
          default:
            // BookingCalenderControllMode.date
            break;
        }
        if (this.bookingService.isDate(this.displayDate)) {
          const newMonday: string = this.bookingService.getMonday(this.displayDate);
          if (isForce || isReload || (this.currentMonday !== newMonday)) {
            this.currentMonday = newMonday;
            this.bookingService.getData(this.user, this.currentMonday, isReload, isForce)
              .then(data => this.data = data);
          }
          this.bookingService.setLastDisplayDate(this.displayDate);
        }
      });
  }

  cellClick(cell: BookingCell): void {
    this.modalComponent.cellClick(cell);
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    if (!document.hidden) {
      this.pingAndSetClock();
      this.displayDateChanged(BookingCalenderControllMode.reload);
    }
  }

  private pingAndSetClock = async () => {
    const authTime: Date = await this.authService.ping();
    const expiration: Date = this.user.expiration;
    this.isExpired = !MomentService.isSameOrBeforeWithDateObject(authTime, expiration);
    this.clock = MomentService.getDateFromDateObject(authTime, MC.DATE.FORMAT.Y_s_M_s_D_r_d_r_b_H_c_m_c_s);
    this.clockSubscription.unsubscribe();
    MomentService.setClock(authTime);
    this.clockSubscription = MomentService.clock
      .subscribe(current => {
        this.isExpired = !MomentService.isSameOrBeforeWithDateObject(current, expiration);
        this.clock = MomentService.getDateFromDateObject(current, MC.DATE.FORMAT.Y_s_M_s_D_r_d_r_b_H_c_m_c_s);
        this.refreshCountDown--;
        if (this.refreshCountDown <= 0 || ((current.getSeconds() === 0) &&
          (current.getMinutes() === 0 || current.getMinutes() === BC.TIME.UNIT))) {
          this.refreshCountDown = this.refreshInterval;
          this.pingAndSetClock();
          this.displayDateChanged(BookingCalenderControllMode.reload);
        }
      });
  }

}
