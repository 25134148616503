import { ChapterStatus } from 'src/app/models/interface/CourseStatus';

export interface WebMessage {
  messageType: MessageType;
  messageBody: MessageBody;
}

export enum MessageType {
  onLoad = 'onLoad',
  locationChange = 'locationChange',
  statusUpdate = 'statusUpdate',
  upgradeBannerOnClick = 'upgradeBannerOnClick',
  shareButtonOnClick = 'shareButtonOnClick',
  currentStatus = 'currentStatus',
  setNavLinks = 'setNavLinks',
  purchased = 'purchased',
}

export type MessageBody =
  OnLoadMessage
  | LocationChangeMessage
  | StatusUpdateMessage
  | NavLinkMessage
  | PurchasedMessage;

export type OnLoadMessage = {
  location: string;
}

export type LocationChangeMessage = {
  location: string;
}

export type StatusUpdateMessage = {
  status: ChapterStatus;
}

export type CurrentStatusMessage = {
  status: ChapterStatus;
}

export type NavLinkMessage = {
  prev: {
    title: string,
    url: string,
  };
  next: {
    title: string,
    url: string,
  };
  isBlank: boolean;
}

export type PurchasedMessage = {
  purchased: boolean;
}
