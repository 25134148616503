import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';

import { CommonConstants as CC } from 'src/app/constants/common.constants';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Promise<boolean | UrlTree> {
    return this.authService.isAuthenticated() || new Promise(
      resolve => this.authService.userState.subscribe(user => (!!user) ?
        resolve(true) : resolve(this.router.parseUrl(CC.URL.AUTH.SIGNIN))));
  }
}
