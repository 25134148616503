import { CommonConstants as CC } from '../../constants/common.constants';
export class UserSearchCriteria {
    uid?: string = CC.BLANK;
    name?: string = CC.BLANK;
    email?: string = CC.BLANK;
    courseListType?: string = CC.BLANK;
    planCode?: string = CC.BLANK;
    cancelAtPeriodEnd?: string = CC.BLANK;
    status?: string = CC.BLANK;
    admissionMin?: string = CC.BLANK;
    admissionMax?: string = CC.BLANK;
    purchaseMin?: string = CC.BLANK;
    purchaseMax?: string = CC.BLANK;
    expirationMin?: string = CC.BLANK;
    expirationMax?: string = CC.BLANK;
    useUid: boolean = true;
    useName: boolean = true;
    useEmail: boolean = true;
    useCourseListType: boolean = true;
    usePlanCode: boolean = true;
    useCancelAtPeriodEnd: boolean = true;
    useStatus: boolean = true;
    useAdmission: boolean = true;
    usePurchase: boolean = true;
    useExpiration: boolean = true;

    constructor(partial?: Partial<UserSearchCriteria>) {
        Object.assign(this, partial);
    }
}
