import { Injectable } from '@angular/core';
import {
  Auth, authState, browserLocalPersistence, browserSessionPersistence,
  createUserWithEmailAndPassword, EmailAuthProvider,
  reauthenticateWithCredential, sendPasswordResetEmail,
  signInWithEmailAndPassword, User as FirebaseUser, UserCredential
} from '@angular/fire/auth';
import isNull from 'lodash/isnull';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FireauthService {

  constructor(
    private fireauth: Auth
  ) { }

  /**
   * fireauthによる自動再サインインを監視します
   * （購読は初回nextのみ）
   *
   * @param whenUserIsNotNull サインイン状態の処理
   * @param whenUserIsNull サインアウト状態の処理
   */
  public subscribeAuthStateOnce = (
    whenUserIsNotNull: (firebaseUser: FirebaseUser) => void,
    whenUserIsNull: () => void
  ): void => {
    const subscriber: Subscription = authState(this.fireauth)
      .subscribe(firebaseUser => {
        subscriber.unsubscribe();
        isNull(firebaseUser)
          ? whenUserIsNull()
          : whenUserIsNotNull(firebaseUser);
      });
  }

  /**
   * fireauthの認証情報の保存先を変更します
   *
   * @param isLocal trueならばサインイン状態維持、
   *        falseならばサインイン状態はセッション有効内のみ
   */
  public setPersistence = (isLocal: boolean):
    Promise<void> => this.fireauth.setPersistence(isLocal
      ? browserLocalPersistence : browserSessionPersistence);

  /**
   * fireauth登録処理（メール＆パスワード）
   *
   * @param email
   * @param password
   * @returns 認証情報
   */
  public createUserWithEmailAndPassword = (email: string, password: string):
    Promise<UserCredential> =>
    createUserWithEmailAndPassword(this.fireauth, email, password);

  /**
   * fireauthサインイン処理（メール＆パスワード）
   *
   * @param email
   * @param password
   * @returns 認証情報
   */
  public signInWithEmailAndPassword = (email: string, password: string):
    Promise<UserCredential> =>
    signInWithEmailAndPassword(this.fireauth, email, password);

  /**
   * fireauths再認証処理（メール＆パスワード）
   *
   * @param email
   * @param password
   * @returns 認証情報
   */
  public reauthenticateWithCredential = async (email: string, password: string):
    Promise<UserCredential> => reauthenticateWithCredential(
      this.fireauth.currentUser, EmailAuthProvider.credential(email, password));

  /**
   * fireauthサインアウト処理
   */
  public signOut = (): Promise<void> => this.fireauth.signOut();

  /**
   * fireauthパスワードリセットメール送信処理
   *
   * @param email
   */
  public sendPasswordResetEmail = (email: string): Promise<void> =>
    sendPasswordResetEmail(this.fireauth, email);
}
