import { PLAN, STATUS } from '../../../functions/src/plan';
export {
  PLAN, PLANCODE,
  STATUS, PLANSTATUS,
  getPlanTitle,
  getPlanQuestionCount,
  getPlanOnlineCount,
  StripeSubscription
} from '../../../functions/src/plan';

export const STATUS_EXT = {
  ...STATUS,
  available: 'available',
  unavailable: 'unavailable',
} as const;
export type PLANSTATUS_EXT = keyof typeof STATUS_EXT;

export const PLAN_EXT = {
  ...PLAN,
  none: 'none',
  trainee: 'trainee',
} as const;
export type PLANCODE_EXT = keyof typeof PLAN_EXT;

export const NO_PLAN = 'なし';