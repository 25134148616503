export enum ChapterStatus {
  none = 'none',
  ongoing = 'ongoing',
  completed = 'completed',
}

export interface CourseStatus {
  [url: string]: ChapterStatus;
}

