import { Timestamp } from '@angular/fire/firestore';
import { CommonConstants as CC } from 'src/app/constants/common.constants';
import { MomentConstants as MC } from 'src/app/constants/moment.constants';
import { MomentService } from 'src/app/utilities/static/moment.service';

export enum AttendanceStatus {
  none,
  start,
  wait,
  end
}

export class Attendance {
  bookUpdatedAt?: string = CC.BLANK;
  status?: AttendanceStatus = AttendanceStatus.none;
  statusEnteredUser?: string = CC.BLANK;
  statusEnteredAt?: string = CC.BLANK;
  rawdata?: any = null;

  constructor(rawdata) {
    if (rawdata) {
      this.rawdata = rawdata;
      const bookUpdatedAts: string[] = rawdata.bookUpdatedAt ?
        (rawdata.bookUpdatedAt as Timestamp[])
          .map(ts => MomentService.getDateFromDateObject(ts.toDate(),
            MC.DATE.FORMAT.Y_h_M_h_D_b_H_c_m_c_s)) : [];
      this.bookUpdatedAt = bookUpdatedAts.length ?
        bookUpdatedAts[bookUpdatedAts.length - 1] : CC.BLANK;
      this.status = rawdata.status || CC.ZERO;
      this.statusEnteredUser = rawdata.statusEnteredUser || CC.BLANK;
      this.statusEnteredAt = rawdata.statusEnteredAt ?
        MomentService.getDateFromDateObject(rawdata.statusEnteredAt.toDate(),
          MC.DATE.FORMAT.Y_h_M_h_D_b_H_c_m_c_s) : CC.BLANK;
    }
  }
}
