<nav class="navbar navbar-expand-md navbar-light fixed-top">
  <div class="container">
    <a class="navbar-brand" [routerLink]="user ? (user.planCode === pc.free_20210901 && (isExpired || !user.expiration) ? urlPlan : urlRoot) : urlTrial"></a>
    <ng-container *ngIf="isNavShow">
      <ng-container *ngIf="user">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <li *ngIf="!user" class="nav-item">
              <a class="nav-link" [routerLink]="urlSignin" (click)="hideDropdownMenu()">
                <i class="fas fa-sign-in-alt white nav mr-1"></i>ログイン</a>
            </li>
            <li *ngIf="user" class="nav-item dropdown">
              <a id="navbarDropdown" class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-user-cog white nav mr-1"></i>{{user.name}}<span class="caret"></span>
              </a>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" [routerLink]="urlSignout" (click)="hideDropdownMenu()">
                  <i class="fas fa-sign-out-alt dimgray nav mr-1"></i>ログアウト</a>
                <a class="dropdown-item" [routerLink]="urlUpdateInfo" (click)="hideDropdownMenu()">
                  <i class="fas fa-file-signature dimgray nav mr-1"></i>登録情報変更</a>
                <a *ngIf="user.isMasquerade" class="dropdown-item clickable" (click)="endMasquerade()">
                  <i class="fas fa-theater-masks dimgray nav mr-1"></i>なりすまし終了</a>
                <a *ngIf="user.isAdmin || user.isMasquerade" class="dropdown-item" [routerLink]="urlAdmin"
                  (click)="hideDropdownMenu()">
                  <i class="fas fa-tools dimgray nav mr-1"></i>管理者モード</a>
              </div>
            </li>
          </ul>
        </div>
      </ng-container>
    </ng-container>
  </div>
</nav>

<div id="mask" style="display: none;">
  <div id="now_loading" style="display: none;">
    <div class="spinner-border"></div><br>
    <button id="dummy_button"></button>
  </div>
</div>

<router-outlet></router-outlet>
