export class QuestionConstants {

    static readonly ID = {
        STORAGE: {
            QA: 'qa',
            QUESTION: 'question',
            Q: 'q',
            ANSWER: 'answer',
            A: 'a',
        },
        COLLECTION: {
            QUESTIONS: 'questions',
        }
    }

    static readonly TITLE = {
        SUCCESS: {
            QUESTION_POSTED: '質問を送信しました',
            REPLY_POSTED: '回答を送信しました',
        },
        FAIL: {
            QUESTION_POST_FAILED: '質問の送信に失敗しました',
            REPLY_POST_FAILED: '回答の送信に失敗しました',
        },
    };

    static readonly MESSAGE = {
        INFO: {
            PLEASE_WAIT_FOR_ANSWER: '最短１時間～翌営業日中以内に回答を差し上げます。<br>いましばらくお待ちください。',
        },
        ERROR: {
            PLEASE_RETRY: '大変申し訳ありませんが、少し時間をおいて再度送信してください。',
            ADD_SCREEN_SHOT_FAILED: '以下の画像の追加に失敗しました。',
        },
    };

    static readonly REG_EXP_IMG = /\.(jpe?g|png|gif)$/i;

    static readonly PPC = {
        PPC: 'progress-pie-chart',
        PPC_PF: 'ppc-progress-fill',
        PPC_PS: 'ppc-percents span',
        GT50: 'gt-50',
        NO_Q: 'no-question',
        TEXT: '回答済み',
    }
}
