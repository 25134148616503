import { Injectable } from '@angular/core';
import { DocumentReference, DocumentSnapshot, FieldValue } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import isNull from 'lodash/isnull';
import isUndefined from 'lodash/isundefined';
import moment from 'moment';
import { BookingConstants as BC } from 'src/app/constants/booking.constants';
import { CommonConstants as CC } from 'src/app/constants/common.constants';
import { MomentConstants as MC } from 'src/app/constants/moment.constants';
import * as PC from 'src/app/constants/plan.constants';
import { AttendanceStatus } from 'src/app/models/class/Attendance';
import { BookingCell, BookingCellFlags, BookingCellMode } from 'src/app/models/class/BookingCell';
import { BookingData } from 'src/app/models/class/BookingData';
import { BookingError, BookingErrorCode as BEC } from 'src/app/models/class/BookingError';
import { User } from 'src/app/models/class/User';
import { Booking } from 'src/app/models/interface/Booking';
import { CachedData } from 'src/app/models/interface/CachedData';
import { Column } from 'src/app/models/interface/Column';
import { DateInfo } from 'src/app/models/interface/DateInfo';
import { Holiday } from 'src/app/models/interface/Holiday';
import { ListCell, ListCellMode } from 'src/app/models/interface/ListCell';
import { RoomAssign } from 'src/app/models/interface/RoomAssign';
import { TemporaryClosure } from 'src/app/models/interface/TemporaryClosure';
import { AuthService } from 'src/app/services/auth.service';
import { RoomsService } from 'src/app/services/rooms.service';
import { FirestoreService } from 'src/app/utilities/injectable/firestore.service';
import { ReportingService } from 'src/app/utilities/injectable/reporting.service';
import { LoadingService } from 'src/app/utilities/static/loading.service';
import { MomentService } from 'src/app/utilities/static/moment.service';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  private cachedData: Map<string, CachedData> = new Map<string, CachedData>();
  private dateInfo: DateInfo = null;

  private modalOpenData: BookingCell = null;
  private lastDisplayDate: string = null;

  private user: User = null;

  /**
   * コンストラクタ
   */
  constructor(
    private router: Router,
    private firestoreService: FirestoreService,
    private reportingService: ReportingService,
    private authService: AuthService,
    private roomsService: RoomsService,
  ) {
    this.authService.userState.subscribe(user => {
      if (isNull(user) || (!isNull(user) && user.isMasquerade)) {
        this.cachedData = new Map<string, CachedData>();
        this.lastDisplayDate = null;
      }
    });
  }

  /**
   * 日付と開始／終了時刻の文字列からmoment-rangeを生成、
   * 引数で渡された関数にmomentを渡しながら順次実行する
   *
   * - 書式：MomentConstants.DATE.FORMAT.DATE_TIME
   * - イテレーション間隔：BookingConstants.TIME.UNIT
   * @param date 日付文字列、nullならば当日
   * @param start 開始時刻文字列
   * @param end 終了時刻文字列
   * @param func 実行する関数
   */
  private forEachTimeSlotRange = (date: string, start: string, end: string,
    func: (time: moment.Moment) => void): void =>
    MomentService.forEachDateTimeRange(date,
      start || MC.TIME.MID_NIGHT,
      end || MC.TIME.MID_NIGHT_SYNONYM,
      BC.TIME.UNIT, MC.TIME.FORMAT.MINUTES, func)

  /**
   * 再読込許容周期の期間を遡ったmomentを生成する
   */
  private getReloadIntervalMoment = (): moment.Moment =>
    MomentService.getMomentFromDate(
      null, - BC.RELOAD.INTERVAL.AMOUNT, BC.RELOAD.INTERVAL.FORMAT)

  /**
   * キャッシュ有効期限の期間を遡ったmomentを生成する
   */
  private getCacheExpirationMoment = (): moment.Moment =>
    MomentService.getMomentFromDate(
      null, - BC.CACHE.EXPIRAION.AMOUNT, BC.CACHE.EXPIRAION.FORMAT)

  /**
   * 予約解禁の期間を進んだmomentを生成する
   */
  private getUnlockDurationMoment = (): moment.Moment =>
    MomentService.getMomentFromDate(
      null, BC.UNLOCK.DURATION.AMOUNT, BC.UNLOCK.DURATION.FORMAT)

  /**
   * 有効期限のmomentを生成する
   */
  private getExpirationMoment = (): moment.Moment =>
    MomentService.getMomentFromDateObject(this.user.expiration)

  /**
   * 日付文字列生成
   *
   * @param offset 取得したい日付からの差分日数、省略時は0
   * @param date 取得したい日付、省略時は空文字（当日）
   */
  public getDate = (offset: number = CC.ZERO, date: string = null):
    string => MomentService.getDate(offset, date)

  /**
   * 予約解禁期間最終日の文字列を生成
   */
  public getMaxDate = (): string =>
    MomentService.getDateFromMoment(this.getUnlockDurationMoment())
  // MomentService.getDateFromMoment(
  //   isNull(this.user.expiration) ? this.getUnlockDurationMoment()
  //     : (this.getExpirationMoment().isBefore(this.getUnlockDurationMoment()))
  //       ? this.getExpirationMoment() : this.getUnlockDurationMoment())

  /**
   * 指定日付を含む週の月曜日の日付文字列生成
   *
   * - 書式：MomentConstants.DATE.FORMAT.DATE
   * @param date 月曜日を取得したい週の日付
   */
  public getMonday = (date: string): string => MomentService.getMonday(date);

  /**
   * 日付判定
   *
   * @param date 判定したい日付
   */
  public isDate = (date: string): boolean => MomentService.isValidDate(date);

  /**
   * 時刻文字列生成、時間枠の数を指定してオフセット
   *
   * @param time 時刻文字列
   * @param offsetMultiple オフセットする時間枠数（マイナス指定可）
   */
  public getOffsetTime = (time: string, offsetMultiple: number): string =>
    MomentService.getTime(time, BC.TIME.UNIT * offsetMultiple)

  /**
   * 画面表示用の日時文字列を生成
   *
   * @param data 予約情報
   */
  public getDisplayInfo = (data: BookingData): string =>
    MomentService.getDate(0, data.date, MC.DATE.FORMAT.Y_s_M_s_D_r_d_r)
    + CC.SPACE + data.start + BC.TEXT.TIME_SLOT_DELIM + data.end

  /**
   * 曜日文字列生成
   *
   * - 書式：MC.DATE.FORMAT.WEEK_DAY_FULL
   * @param date 曜日を取得したい日付、省略時はBC.TEXT.BLANK_WEEK_DAY
   */
  public getWeekDay = (date: string): string =>
    MomentService.isValidDate(date)
      ? MomentService.getDate(0, date, MC.DATE.FORMAT.d4)
      : BC.TEXT.BLANK_WEEK_DAY

  /**
   * 時間枠の数を算出
   *
   * @param start 開始時間枠
   * @param end 終了時間枠
   */
  private getTimeSlotsCount = (start: string, end: string): number => {
    let count: number = CC.ZERO;
    this.forEachTimeSlotRange(null, start, end, () => count++);
    return count;
  }

  /**
   * 時間帯列を生成
   *
   * - 開始/終了および枠の大きさは定数クラスに依存
   */
  private getTimeSlot = (): BookingCell[] => {
    const timeSlot: BookingCell[] = [{
      mode: BookingCellMode.other,
      text: BC.TEXT.TIME_SLOT,
      class: BC.CLASS.CELL.TH.NORMAL
    }];
    this.forEachTimeSlotRange(null, BC.TIME.START, BC.TIME.END, time =>
      timeSlot.push({
        mode: BookingCellMode.other,
        text: MomentService.getTimeFromMoment(time)
          + BC.TEXT.TIME_SLOT_DELIM
          + MomentService.getTimeFromMoment(time, BC.TIME.UNIT),
        class: BC.CLASS.CELL.TH.NORMAL
      }));
    return timeSlot;
  }

  /**
   * 時刻プルダウンを生成
   *
   * - 開始/終了は定数クラスに依存
   * @param isEnd 時間枠の終了時刻用かどうか
   */
  public getTimeSlotPullDownData = (isEnd: boolean = false): string[] => {
    const timeSlot: string[] = [];
    this.forEachTimeSlotRange(null, BC.TIME.START, BC.TIME.END, time =>
      timeSlot.push(MomentService
        .getTimeFromMoment(time, isEnd ? BC.TIME.UNIT : CC.ZERO)));
    return timeSlot;
  }

  /**
   * 基準日から始まる週の表示用データを生成
   *
   * - キャッシュデータが存在する場合はキャッシュから取得
   * - 再読込およびキャッシュ保存期間超過後はサーバーから取得
   * - 再読込は抑止期間あり
   * @param user 対象ユーザー
   * @param startDate 基準日（その週の月曜日付）
   * @param isReload 再読込（キャッシュあり）
   * @param isForce 強制再取得（キャッシュ無視）
   */
  public getData = async (user: User, startDate: string,
    isReload: boolean = false, isForce: boolean = false):
    Promise<Column[][]> => {
    let data: Column[][] = [];
    LoadingService.on();

    this.user = user;

    // 賞味期限切れキャッシュを削除
    this.checkCacheExpiration();

    // キャッシュデータが存在していて、かつ
    // 再読込抑止期間内ならば、再読込取消
    if (isReload && (this.cachedData.has(this.router.url + startDate))) {
      const interval: moment.Moment = this.getReloadIntervalMoment();
      if (MomentService.isAfter(
        this.cachedData.get(this.router.url + startDate).timeStamp, interval)) {
        isReload = false;
      }
    }

    // 強制再読込／再読込でなく、かつキャッシュデータが
    // 存在している場合はキャッシュデータを返却、
    // それ以外はサーバーから取得
    if (!isForce && !isReload && (this.cachedData.has(this.router.url + startDate))) {
      data = this.cachedData.get(this.router.url + startDate).data;
    } else {
      data = await this.makeData(startDate,
        MomentService.getDate(BC.DATE.PERIOD, startDate));
      this.cachedData.set(this.router.url + startDate,
        { data, timeStamp: MomentService.getMomentFromDate() });
    }
    LoadingService.off();
    return data;
  }

  /**
   * 表示データキャッシュの賞味期限チェック
   *
   * - 賞味期限は定数クラスに依存
   */
  private checkCacheExpiration = () => {
    const expiration: moment.Moment = this.getCacheExpirationMoment();
    this.cachedData.forEach((data, key, cachedData) => {
      if (MomentService.isBefore(data.timeStamp, expiration)) {
        cachedData.delete(key);
      }
    });
  }

  /**
   * firestoreより休校情報を取得
   *
   * @param <T> 取得するデータのinterface
   * @param startDate 取得期間開始日
   * @param endDate 取得期間終了日
   * @param collectionKey コレクション名
   */
  private firestoreGetClosureInfo =
    async <T>(startDate: string, endDate: string, collectionKey: string):
      Promise<Map<string, T>> => {
      const data: Map<string, T> = new Map<string, T>();
      await this.firestoreService.searchDocumentByRange(
        [collectionKey], CC.DOCUMENT.KEY.DATE, startDate, endDate)
        .then(snapshot => snapshot.docs.forEach(
          doc => data.set(doc.get(CC.DOCUMENT.KEY.DATE), doc.data() as T)))
        .catch(error => this.reportingService.errorReport(
          BC.MESSAGE.ERROR.PLEASE_RETRY,
          BC.TITLE.FAIL.GETDATA,
          'firestoreGetClosureInfo', error));
      return data;
    }

  /**
   * firestoreより予約情報を取得
   *
   * @param <T> 取得するデータのinterface
   * @param startDate 取得期間開始日
   * @param endDate 取得期間終了日
   * @param collectionGroupKey コレクショングループ名
   */
  private firestoreGetBookingInfo =
    async <T>(startDate: string, endDate: string, collectionGroupKey: string):
      Promise<Map<string, Map<string, T>>> => {
      const data: Map<string, Map<string, T>> = new Map<string, Map<string, T>>();
      await this.firestoreService.searchDocumentByRangeFromCollectionGroup(
        collectionGroupKey, CC.DOCUMENT.KEY.DATE, startDate, endDate)
        .then(snapshot => snapshot.docs.forEach(doc => {
          const date: string = doc.get(CC.DOCUMENT.KEY.DATE);
          const time: string = doc.get(CC.DOCUMENT.KEY.TIME);
          if (!data.has(date)) {
            data.set(date, new Map<string, T>());
          }
          data.get(date).set(time, doc.data() as T);
        }))
        .catch(error => this.reportingService.errorReport(
          BC.MESSAGE.ERROR.PLEASE_RETRY,
          BC.TITLE.FAIL.GETDATA,
          'firestoreGetBookingInfo', error));
      return data;
    }

  /**
   * 予約情報と休校情報を取得
   *
   * @param startDate 取得期間開始日
   * @param endDate 取得期間終了日
   */
  private getDateInfo = async (startDate: string, endDate: string):
    Promise<DateInfo> => {
    return await Promise.all([
      this.firestoreGetBookingInfo<Booking>
        (startDate, endDate, CC.COLLECTION.KEY.TIMES),
      this.firestoreGetClosureInfo<Holiday>
        (startDate, endDate, CC.COLLECTION.KEY.HOLIDAYS),
      this.firestoreGetClosureInfo<TemporaryClosure>
        (startDate, endDate, CC.COLLECTION.KEY.TEMPORARY_CLOSURE)
    ])
      .then(([bookings, holidays, temporaryClosures]) =>
        ({ bookings, holidays, temporaryClosures }));
  }

  /**
   * 予約情報と休校情報を取得して表示用データを生成する
   *
   * @param startDate 取得期間開始日
   * @param endDate 取得期間終了日
   */
  private makeData = async (startDate: string, endDate: string):
    Promise<Column[][]> => {
    // 予約情報と祝日情報と臨時休校情報を取得
    const dateInfo: DateInfo = await this.getDateInfo(startDate, endDate);
    // modalでのチェック用にキープ
    this.dateInfo = dateInfo;
    // 時間帯列
    const timeSlot: BookingCell[] = this.getTimeSlot();
    // 予約解禁期間終了日のmoment
    const unlockDurationEnd: moment.Moment = this.getUnlockDurationMoment();

    // Capacity判定用にルーム情報をセット
    await this.roomsService.prepareRooms();

    // PCサイズ用の時間帯列追加
    const data: Column[][] = [[{ class: BC.CLASS.COLUMN.TIME_SLOT_PC, cells: timeSlot }]];

    // 日付ごとのループ
    MomentService.forEachDateRange(startDate, endDate, dateMoment => {
      const date: string = MomentService.getDateFromMoment(dateMoment);
      const weekDay: number = dateMoment.isoWeekday();
      const holidayTitle: string = dateInfo.holidays.has(date) ?
        dateInfo.holidays.get(date).title : CC.BLANK;

      // 臨時休講を時間ごとに展開
      const temporaryClosure: Map<string, string> = new Map<string, string>();
      if (dateInfo.temporaryClosures.has(date)) {
        dateInfo.temporaryClosures.get(date).times.forEach(time =>
          this.forEachTimeSlotRange(null, time.start, time.end, momentArg =>
            temporaryClosure.set(MomentService.getTimeFromMoment(momentArg), time.reason)));
      }

      // フラグセット
      const flags: BookingCellFlags = new BookingCellFlags();
      flags.isAdminMode = isNull(this.user);
      flags.isMasqueradeMode = !flags.isAdminMode && this.user.isMasquerade;
      flags.isToday = (date === MomentService.getDate());
      flags.isHoliday = !CC.IS_BLANK(holidayTitle);
      flags.isRegularClosing = BC.DATE.REGULAR_CLOSING[weekDay];
      flags.isLocked = MomentService.isAfter(dateMoment, unlockDurationEnd);
      flags.isBookablePlan = (!flags.isAdminMode
        && (this.user.planCode === PC.PLAN.free_20210901
          || this.user.planCode === PC.PLAN.light_20210901));
      flags.isOnlineCountRemain = (!flags.isAdminMode && (this.user.onlineCount > 0));
      flags.isPlanCanceled = (!flags.isAdminMode
        && !isUndefined(this.user.stripeSubscription)
        && this.user.stripeSubscription.status === PC.STATUS.canceled);
      flags.isPlanCancelAtPeriodEnd = (!flags.isAdminMode
        && (isUndefined(this.user.stripeSubscription)
          || (!isUndefined(this.user.stripeSubscription)
            && this.user.stripeSubscription.cancel_at_period_end)));

      // スマホサイズ用の時間帯列追加
      const dayData: Column[] = [{ class: BC.CLASS.COLUMN.TIME_SLOT_SP, cells: timeSlot }];

      // 日付表示セル追加
      const cellsData: BookingCell[] = [{
        mode: BookingCellMode.other,
        text: MomentService.getDateFromMoment(dateMoment, MC.DATE.FORMAT.M_s_D_r_d_r),
        class: this.getThClass(flags, weekDay)
      }];

      // 時間帯ごとのループ（自分の予約枠を連結中はスキップ）
      let userBookingCount: number = CC.ZERO;
      this.forEachTimeSlotRange(date, BC.TIME.START, BC.TIME.END, timeMoment => {
        if (userBookingCount === CC.ZERO) {
          const startTime: string = MomentService.getTimeFromMoment(timeMoment);
          const closeTitle: string = temporaryClosure.has(startTime) ?
            temporaryClosure.get(startTime) : CC.BLANK;

          // 予約状況
          let bookingCount: number = CC.ZERO;
          let bookingTimeStamp: string = CC.BLANK;
          if (dateInfo.bookings.has(date)) {
            const bookingDataInDate: Map<string, Booking> = dateInfo.bookings.get(date);
            if (bookingDataInDate.has(startTime)) {
              const bookingDataInTime: Booking = bookingDataInDate.get(startTime);
              // 枠内の予約件数
              bookingCount = (bookingDataInTime.users as string[]).length;

              if (!flags.isAdminMode) {
                // 自分の予約データの予約key（CommonConstants.COLLECTION.BOOKING_KEY_FORMAT）を検索
                if (bookingDataInTime[this.user.uid]) {
                  bookingTimeStamp = bookingDataInTime[this.user.uid];
                }
                // あれば予約枠の数をカウント
                if (!CC.IS_BLANK(bookingTimeStamp)) {
                  bookingDataInDate.forEach(booking => {
                    if ((booking[this.user.uid])
                      && (booking[this.user.uid] === bookingTimeStamp)) {
                      userBookingCount++;
                    }
                  });
                }
              }
            }
          }

          const endTime: string = MomentService.getTime(startTime,
            (userBookingCount === CC.ZERO ? BC.TIME.UNIT : userBookingCount * BC.TIME.UNIT));
          const endTimeMoment: moment.Moment = MomentService.getMomentFromDateTime(date, endTime);

          // 出席（座席番号）
          const attendanceStatus: AttendanceStatus = flags.isAdminMode ? AttendanceStatus.none
            : this.user.getAttendance(bookingTimeStamp).status;

          const roomIds: string[] = this.roomsService.getRoomIdsInDateTime(date, startTime);

          // フラグセット
          flags.isTemporaryClosing = !CC.IS_BLANK(closeTitle);
          flags.isStarted = MomentService.isSameOrBefore(timeMoment);
          flags.isEnded = MomentService.isSameOrBefore(endTimeMoment);
          flags.isEmpty = (bookingCount === CC.ZERO);
          flags.isFull = (bookingCount === roomIds.length);
          flags.isExpired = (!flags.isAdminMode && (isNull(this.user.expiration)
            || MomentService.isAfter(timeMoment, this.getExpirationMoment())));
          flags.isMyBook = (userBookingCount > CC.ZERO);
          flags.isAttendance = (attendanceStatus === AttendanceStatus.start
            || attendanceStatus === AttendanceStatus.end);

          // 予約枠セル追加
          const cell: BookingCell = {
            date, start: startTime, end: endTime,
            uid: !flags.isAdminMode ? this.user.uid : CC.BLANK,
            key: bookingTimeStamp,
          }
          cellsData.push(this.setBookingCell(cell, flags,
            roomIds, bookingCount, userBookingCount,
            holidayTitle, closeTitle));
        }
        if (userBookingCount > CC.ZERO) {
          userBookingCount--;
        }
      });

      // 日付データ追加
      dayData.push({
        class: BC.CLASS.COLUMN.DAY,
        cells: cellsData
      });

      // 列データ追加
      data.push(dayData);
    });
    return data;
  }

  private setBookingCell = (cell: BookingCell, flags: BookingCellFlags,
    roomIds: string[], bookingCount: number, userBookingCount: number,
    holidayTitle: string, closeTitle: string
  ): BookingCell => {

    // 予約可：有効期間内、もしくは有効期間外でも自動更新継続
    if (flags.isBookablePlan && !flags.isPlanCanceled && flags.isOnlineCountRemain
      && (!flags.isExpired || (flags.isExpired && !flags.isPlanCancelAtPeriodEnd))) {
      cell.mode = BookingCellMode.open;
      cell.class = CC.CLASS.CLICKABLE;
      cell.text = BC.TEXT.BOOKABLE;
      cell.title = BC.MESSAGE.INFO.BOOKABLE;
    }
    // 予約可：有効期間外、かつ自動更新停止
    if (flags.isBookablePlan && !flags.isPlanCanceled && flags.isOnlineCountRemain
      && flags.isExpired && flags.isPlanCancelAtPeriodEnd) {
      cell.mode = BookingCellMode.open;
      cell.class = BC.CLASS.CELL.TD.EXPIRED_MUTED + CC.CLASS.CLICKABLE;
      cell.text = BC.TEXT.BOOKABLE;
      cell.title = BC.MESSAGE.ERROR.IS_EXPIRED;
    }

    // 予約不可：予約残回数無し
    if (flags.isBookablePlan && !flags.isOnlineCountRemain) {
      cell.mode = BookingCellMode.close;
      cell.class = BC.CLASS.CELL.TD.CLOSE_MUTED;
      cell.text = BC.TEXT.COUNT_NOT_REMAIN;
      cell.title = BC.MESSAGE.ERROR.IS_ONLINE_COUNT_NOT_REMAIN;
    }
    // 予約不可：予約不可プラン、もしくはプラン失効
    if (!flags.isBookablePlan || flags.isPlanCanceled) {
      cell.mode = BookingCellMode.close;
      cell.class = BC.CLASS.CELL.TD.CLOSE_MUTED;
      cell.text = BC.TEXT.NOT_BOOKABLE;
      cell.title = BC.MESSAGE.ERROR.IS_PLAN_NOT_BOOKABLE;
    }

    // 予約不可：過去
    if (flags.isStarted) {
      cell.mode = BookingCellMode.close;
      cell.class = BC.CLASS.CELL.TD.CLOSE_MUTED;
      cell.text = CC.HYPHEN;
      cell.title = BC.MESSAGE.ERROR.IS_PAST;
    }
    // 予約不可：満席
    if (flags.isFull) {
      cell.mode = BookingCellMode.full;
      cell.class = BC.CLASS.CELL.TD.FULL;
      cell.text = BC.TEXT.FULL;
      cell.title = BC.MESSAGE.ERROR.IS_FULL;
    }
    // 予約不可：予約可能期間外
    if (flags.isLocked) {
      cell.mode = BookingCellMode.close;
      cell.class = BC.CLASS.CELL.TD.CLOSE_MUTED;
      cell.text = BC.TEXT.LOCKED;
      cell.title = BC.MESSAGE.ERROR.IS_FUTURE;
    }

    // 予約状況一覧向け設定
    if (flags.isAdminMode) {
      cell.mode = BookingCellMode.open;
      cell.class = CC.CLASS.CLICKABLE;
      cell.text = String(bookingCount) + CC.SLASH + String(roomIds.length);
      cell.title = roomIds.join(CC.LINE_BREAK);
      if (flags.isEmpty) {
        cell.mode = BookingCellMode.empty;
        cell.class = BC.CLASS.CELL.TD.EMPTY;
      }
      if (flags.isStarted && !flags.isEnded) {
        cell.class += BC.CLASS.CELL.TD.NOW;
      }
    }

    // 祝日休講・定休日休講・臨時休講
    if (flags.isHoliday || flags.isRegularClosing || flags.isTemporaryClosing) {
      cell.mode = BookingCellMode.close;
      cell.class = BC.CLASS.CELL.TD.CLOSE;
      cell.text = BC.TEXT.CLOSE;
      cell.title = flags.isHoliday ? holidayTitle :
        flags.isTemporaryClosing ? closeTitle : BC.TEXT.REGULAR_CLOSE;
    }

    if (!flags.isAdminMode) {
      const myBookClass: string = BC.CLASS.CELL.TD.ROWS_PREFIX + userBookingCount;
      // 予約済
      if (flags.isMyBook && !flags.isStarted) {
        cell.mode = BookingCellMode.booked;
        cell.class = myBookClass + BC.CLASS.CELL.TD.BOOKED + CC.CLASS.CLICKABLE;
        cell.text = BC.TEXT.BOOKED;
        cell.title = BC.MESSAGE.INFO.OPEN_MODAL;
      }
      // 実施
      if (flags.isMyBook && flags.isStarted && flags.isAttendance) {
        cell.mode = BookingCellMode.attendance;
        cell.class = myBookClass + BC.CLASS.CELL.TD.ATTENDANCE;
        cell.text = BC.TEXT.ATTENDANCE;
        cell.title = BC.TEXT.ATTENDANCE;
      }
      // 未実施
      if (flags.isMyBook && flags.isStarted && !flags.isAttendance) {
        cell.mode = BookingCellMode.absence;
        cell.class = myBookClass + BC.CLASS.CELL.TD.ABSENCE;
        cell.text = BC.TEXT.NONATTENDANCE;
        cell.title = BC.MESSAGE.INFO.PLEASE_START;
      }
      // 無連絡
      if (flags.isMyBook && flags.isStarted && !flags.isAttendance && flags.isEnded) {
        cell.mode = BookingCellMode.absence;
        cell.class = myBookClass + BC.CLASS.CELL.TD.ABSENCE;
        cell.text = BC.TEXT.ABSENCE;
        cell.title = BC.TEXT.ABSENCE;
      }

      // なりすまし向け設定
      if (flags.isMasqueradeMode) {
        // 実施中や過去の予約でも取消操作可能
        if (flags.isMyBook && flags.isStarted) {
          cell.mode = BookingCellMode.booked;
        }
      }
    }

    // td基本class追加
    cell.class = BC.CLASS.CELL.TD.NORMAL + cell.class;

    // 日時情報追加、toast向け改行タグ置き換え
    cell.title = MomentService.getDate(0, cell.date, MC.DATE.FORMAT.M_s_D_r_d_r)
      + CC.SPACE + cell.start + CC.FULL_WIDTH_TILDA + cell.end + CC.LINE_BREAK + cell.title;
    cell.title = cell.title.split(CC.HTML.TAG.BR).join(CC.LINE_BREAK);

    return cell;
  }

  /**
   * 日付表示セル：class
   * 1. 通常：BC.CLASS.CELL.TH.NORMAL
   * 1. 今日（平日のみ）：BC.CLASS.CELL.TH.TODAY
   * 1. 土曜日：MC.DATE.WEEK_DAY.SAT
   * 1. 日曜日・休日：BC.CLASS.CELL.TH.SUN
   * @param flags 予約状態フラグ
   * @param weekDay 曜日
   */
  private getThClass = (flags: BookingCellFlags,
    weekDay: number): string => {
    let thClass = BC.CLASS.CELL.TH.NORMAL;
    if (flags.isToday) {
      thClass = BC.CLASS.CELL.TH.TODAY;
    }
    if (weekDay === MC.DATE.WEEK_DAY.SAT) {
      thClass = BC.CLASS.CELL.TH.SAT;
    }
    if ((weekDay === MC.DATE.WEEK_DAY.SUN) || flags.isHoliday) {
      thClass = BC.CLASS.CELL.TH.SUN;
    }
    return thClass;
  }

  /**
   * 新規予約・予約内容変更処理
   *
   * @param data 予約情報クラスインスタンス
   */
  public bookingSave = async (data: BookingData):
    Promise<boolean> => {
    LoadingService.on();
    let preCheck = true;
    const toastTitle: string = (!isNull(data.original.key))
      ? BC.TITLE.FAIL.UPDATE : BC.TITLE.FAIL.SAVE;
    const dateMoment: moment.Moment =
      MomentService.getMomentFromDate(data.date);
    const timeMoment: moment.Moment =
      MomentService.getMomentFromDateTime(data.date, data.start);

    // 過去と予約可能期間より先は予約不可
    if (MomentService.isBefore(timeMoment)) {
      this.reportingService.errorToast(
        BC.MESSAGE.ERROR.IS_PAST,
        toastTitle);
      preCheck = false;
    } else if (MomentService.isAfter(
      dateMoment, this.getUnlockDurationMoment())) {
      this.reportingService.errorToast(
        BC.MESSAGE.ERROR.IS_FUTURE,
        toastTitle);
      preCheck = false;
    }

    // 休日か臨時休校時間帯は予約不可
    if (preCheck) {
      let isTemporaryClosure = false;
      if (this.dateInfo.temporaryClosures.has(data.date)) {
        this.dateInfo.temporaryClosures.get(data.date).times.forEach(time =>
          isTemporaryClosure = isTemporaryClosure || MomentService.isOverlaps(
            MomentService.getDateTimeRange(data.date, data.start, data.end),
            MomentService.getDateTimeRange(data.date, time.start, time.end)));
      }
      const weekDay: number = dateMoment.isoWeekday();
      const isRegularClosing: boolean = BC.DATE.REGULAR_CLOSING[weekDay];
      if (this.dateInfo.holidays.has(data.date)
        || isRegularClosing || isTemporaryClosure) {
        this.reportingService.errorToast(
          BC.MESSAGE.ERROR.IS_CLOSED,
          toastTitle);
        preCheck = false;
      }
    }

    // 予約数上限チェック
    if (preCheck) {
      if (await this.firestoreIsLimitOver(data)) {
        this.reportingService.errorToast(
          BC.MESSAGE.ERROR.IS_LIMIT_OVER,
          toastTitle);
        preCheck = false;
      }
    }

    // 事前チェックＯＫならばfirestoreの処理へ
    if (preCheck) {
      if (!isNull(data.original.key)) {
        if (await this.firestoreUpdateBookingDocument(data)) {
          this.reportingService.successToast(
            BC.MESSAGE.INFO.UPDATE_BEFORE + data.original.display +
            BC.MESSAGE.INFO.UPDATE_AFTER + data.display,
            BC.TITLE.SUCCESS.UPDATE);
        }
      } else {
        if (await this.firestoreCreateBookingDocument(data)) {
          this.reportingService.successToast(
            data.display,
            BC.TITLE.SUCCESS.SAVE);
        }
      }
    } else {
      LoadingService.off();
    }
    return preCheck;
  }

  /**
   * 予約上限到達判定（firestore接続あり）
   *
   * - 予約しようとしているブロック内のコマ数下限・上限チェック（firestore非接続）
   * - １日のブロック数上限チェック
   * - １日のコマ数上限チェック
   * @param data 予約情報
   */
  private firestoreIsLimitOver = async (data: BookingData):
    Promise<boolean> => {
    let result = false;
    const counter: number[] = [];
    const bookingId: string = data.original.uid +
      (data.original.key || CC.NEW);
    const timeSlotCount: number = this.getTimeSlotsCount(data.start, data.end);
    counter[bookingId] = timeSlotCount;
    // 予約しようとしているブロック内のコマ数下限・上限チェック
    result = result || counter[bookingId] > BC.LIMIT.TIME_SLOTS_PER_BLOCK_MAX;
    result = result || counter[bookingId] < BC.LIMIT.TIME_SLOTS_PER_BLOCK_MIN;
    // １日通算用
    counter[data.original.uid] = timeSlotCount;
    if (!result) {
      await this.firestoreService.getCollection(
        [CC.COLLECTION.KEY.BOOKINGS, data.date, CC.COLLECTION.KEY.TIMES])
        .then(snapshot => snapshot.docs.forEach(doc => {
          if (doc.get(data.original.uid)) {
            const id: string = data.original.uid
              + doc.get(data.original.uid);
            if (id !== bookingId) {
              counter[id] ? counter[id]++ : counter[id] = CC.ONE;
              counter[data.original.uid]++;
            }
          }
        }))
        .catch(error => this.reportingService.errorReport(
          BC.MESSAGE.ERROR.PLEASE_RETRY,
          BC.TITLE.FAIL.GETDATA,
          'firestoreIsLimitOver', error));
    }
    // １日のブロック数上限チェック
    result = result || Object.keys(counter).length - CC.ONE > BC.LIMIT.BLOCKS_PER_DAY_MAX;
    // １日のコマ数上限チェック
    result = result || counter[data.original.uid] > BC.LIMIT.TIME_SLOTS_PER_DAY_MAX;
    return result;
  }

  /**
   * 予約取消処理
   *
   * @param data 予約情報
   */
  public bookingCancel = async (data: BookingData):
    Promise<boolean> => {
    LoadingService.on();
    const preCheck: boolean = true;

    // 事前チェックＯＫならばfirestoreの処理へ
    if (preCheck) {
      if (await this.firestoreDeleteBookingDocument(data)) {
        this.reportingService.successToast(
          data.original.display,
          BC.TITLE.SUCCESS.CANCEL);
      }
    } else {
      LoadingService.off();
    }
    return preCheck;
  }

  /**
   * firestoreトランザクション処理準備
   *
   * - 予約情報から時間枠の数に応じたDocumentReferenceとBookingを生成
   * - 予約削除の場合はBooking.originalから生成
   * @param docRef トランザクション内で取得、チェック、更新／削除を行う対象のDocumentの配列
   * @param items docRefに対応する更新／削除に用いる予約Document情報の配列
   * @param data 予約情報
   * @param isDelete 予約削除ならばtrue
   */
  private prepareFirestoreTransaction = (
    docRefs: DocumentReference[], items: Booking[],
    data: BookingData, isDelete: boolean = false): void => {
    const date: string = isDelete ? data.original.date : data.date;
    const start: string = isDelete ? data.original.start : data.start;
    const end: string = isDelete ? data.original.end : data.end;
    const key: string | FieldValue = isDelete
      ? FirestoreService.getDeleteField()
      : !isNull(data.original.key) ? data.original.key
        : MomentService.getDate(0, null, CC.COLLECTION.BOOKING_KEY_FORMAT);
    const users: FieldValue = isDelete
      ? FirestoreService.arrayRemove(data.original.uid)
      : FirestoreService.arrayUnion(data.original.uid);

    this.forEachTimeSlotRange(date, start, end, timeMoment => {
      const time: string = MomentService.getTimeFromMoment(timeMoment);
      docRefs.push(this.firestoreService.getDocumentReference(
        [CC.COLLECTION.KEY.BOOKINGS, date,
        CC.COLLECTION.KEY.TIMES, time]));
      items.push({
        date, time, users, [data.original.uid]: key
      });
    });
  }

  /**
   * firestore予約情報新規作成
   *
   * @param data 予約情報
   * @returns Promise<boolean> 予約情報作成成否
   */
  private firestoreCreateBookingDocument = async (data: BookingData):
    Promise<boolean> => {
    let result = false;

    // Capacity判定およびルーム割当処理用にルーム情報をセット
    await this.roomsService.prepareRooms();

    // 予約対象となる時間帯の各documentを取得、対応する保存用データ作成
    const docRefs: DocumentReference[] = [];
    const items: Booking[] = [];
    // 先にroomAssign取得用のdocRefを追加
    docRefs.push(this.firestoreService.getDocumentReference(
      [CC.COLLECTION.KEY.BOOKINGS, data.date]));
    items.push(null);
    // 次にuser更新用のdocRefを追加
    docRefs.push(this.firestoreService.getDocumentReference(
      [CC.COLLECTION.KEY.USERS, data.original.uid]));
    items.push(null);
    this.prepareFirestoreTransaction(docRefs, items, data);

    // トランザクション
    await this.firestoreService.runTransaction(transaction => {
      const promises: Promise<DocumentSnapshot>[] = [];
      docRefs.forEach(docRef => promises.push(transaction.get(docRef)));
      return Promise.all(promises).then(docs => {

        // 自分が予約済みの時間帯を含んでいればエラーリジェクト
        // 満席の時間帯を含んでいればエラーリジェクト
        // 予約可能回数が0ならばエラーリジェクト（プランはどうでもよい）
        // 満席判定ができるのは、他の予約が1件以上ある場合のみ（普通）
        // いずれも該当しなければトランザクション内で予約データを保存
        let isBooked = false;
        let isFull = false;
        let isCountNotRemain = false;
        let onlineCount: number = 0;
        let oldRoomAssign: RoomAssign;
        docs.forEach(doc => {
          if (doc.id === data.date) {
            oldRoomAssign = doc.get(CC.DOCUMENT.KEY.ROOM_ASSIGN);
          } else if (doc.id === data.original.uid) {
            onlineCount = doc.get(CC.DOCUMENT.KEY.ONLINE_COUNT);
            isCountNotRemain = onlineCount < CC.ONE;
          } else {
            const users: string[] = doc.get(CC.DOCUMENT.KEY.USERS);
            const userKey: string = doc.get(data.original.uid);
            const date: string = doc.get(CC.DOCUMENT.KEY.DATE);
            const time: string = doc.get(CC.DOCUMENT.KEY.TIME);
            isBooked = isBooked || (doc.exists() && !isUndefined(userKey));
            isFull = isFull || (doc.exists()
              && users.length >= this.roomsService.getCapacity(date, time));
          }
        });

        if (isBooked) {
          return Promise.reject(new BookingError(BEC.already_booked));
        } else if (isFull) {
          return Promise.reject(new BookingError(BEC.full_booked));
        } else if (isCountNotRemain) {
          return Promise.reject(new BookingError(BEC.count_not_remain));
        } else {
          data.original.key = items[items.length - 1][data.original.uid];
          // roomAssignのパズルを解く
          // ここで解けないのはルーム数0で満席とする特殊な場合のみ
          const roomAssign: RoomAssign | boolean = this.roomsService
            .assignRooms(data, oldRoomAssign, data.original.key, false);
          if (!roomAssign) {
            return Promise.reject(new BookingError(BEC.room_assign_failed));
          } else {
            items.forEach((item, index) => {
              if (index === CC.ZERO) {
                transaction.set(docRefs[index], { roomAssign });
              } else if (index === CC.ONE) {
                onlineCount--;
                const bookUpdatedAt = FirestoreService.arrayUnion(MomentService
                  .newDate(data.original.key, CC.COLLECTION.BOOKING_KEY_FORMAT));
                transaction.set(docRefs[index], {
                  onlineCount, [data.original.key]: { bookUpdatedAt }
                }, { merge: true });
              } else if (index > CC.ONE) {
                transaction.set(docRefs[index], item, { merge: true });
              }
            });
            return Promise.resolve(true);
          }
        }
      });
    })
      .then(bookingResult => result = bookingResult)
      .catch(error => this.reportingService.errorReport(
        BC.MESSAGE.ERROR.PLEASE_RETRY,
        BC.TITLE.FAIL.SAVE,
        'firestoreCreateBookingDocument', error));
    return result;
  }

  /**
   * firestore予約情報更新
   *
   * @param data 予約情報クラスインスタンス
   * @returns Promise<boolean> 予約情報更新成否
   */
  private firestoreUpdateBookingDocument = async (data: BookingData):
    Promise<boolean> => {
    let result = false;

    // Capacity判定およびルーム割当処理用にルーム情報をセット
    await this.roomsService.prepareRooms();

    // 操作対象となる時間帯の各documentを取得、対応する削除・保存用データ作成
    const docRefs: DocumentReference[] = [];
    const items: Booking[] = [];
    // 先にroomAssign取得用のdocRefを追加
    docRefs.push(this.firestoreService.getDocumentReference(
      [CC.COLLECTION.KEY.BOOKINGS, data.date]));
    items.push(null);
    // 次にuser更新用のdocRefを追加
    docRefs.push(this.firestoreService.getDocumentReference(
      [CC.COLLECTION.KEY.USERS, data.original.uid]));
    items.push(null);
    // 変更前の日時で削除用データ
    this.prepareFirestoreTransaction(docRefs, items, data, true);
    const docRefSplit: number = docRefs.length;
    // 変更後の日時で保存用データ
    this.prepareFirestoreTransaction(docRefs, items, data);

    // トランザクション
    await this.firestoreService.runTransaction(transaction => {
      const promises: Promise<DocumentSnapshot>[] = [];
      docRefs.forEach(docRef => promises.push(transaction.get(docRef)));
      return Promise.all(promises).then(docs => {

        // 【変更前のDocument】
        // 自分が予約済みでない時間帯を含んでいればエラーリジェクト
        // 【変更後のDocument】
        // 自分が別に予約済みの時間帯を含んでいればエラーリジェクト
        // 自分の予約以外で満席の時間帯を含んでいればエラーリジェクト
        // 該当しなければトランザクション内で予約データを削除⇒保存
        let isNotBooked = false;
        let isBooked = false;
        let isFull = false;
        let oldRoomAssign: RoomAssign;
        let docRefIndex: number = CC.ZERO;
        docs.forEach(doc => {
          if (doc.id === data.date) {
            docRefIndex++;
            oldRoomAssign = doc.get(CC.DOCUMENT.KEY.ROOM_ASSIGN);
          } else if (doc.id === data.original.uid) {
            docRefIndex++;
          } else {
            const users: string[] = doc.get(CC.DOCUMENT.KEY.USERS);
            const userKey: string = doc.get(data.original.uid);
            const date: string = doc.get(CC.DOCUMENT.KEY.DATE);
            const time: string = doc.get(CC.DOCUMENT.KEY.TIME);
            docRefIndex++;
            if (docRefIndex <= docRefSplit) {
              isNotBooked = isNotBooked || (!doc.exists()
                || (doc.exists() && userKey !== data.original.key));
            } else {
              isBooked = isBooked || (doc.exists()
                && !isUndefined(userKey)
                && userKey !== data.original.key);
              isFull = isFull || (doc.exists()
                && users.length >= this.roomsService.getCapacity(date, time)
                && isUndefined(userKey));
            }
          }
        });

        if (isNotBooked) {
          return Promise.reject(new BookingError(BEC.unbooked));
        } else if (isBooked) {
          return Promise.reject(new BookingError(BEC.already_booked));
        } else if (isFull) {
          return Promise.reject(new BookingError(BEC.full_booked));
        } else {
          // roomAssignのパズルを解く
          // ここで解けないのは開始時刻を過ぎた時間延長の場合のみ
          const roomAssign: RoomAssign | boolean = this.roomsService
            .assignRooms(data, oldRoomAssign, data.original.key, false);
          if (!roomAssign) {
            return Promise.reject(new BookingError(BEC.room_reassign_failed));
          } else {
            items.forEach((item, index) => {
              if (index === CC.ZERO) {
                transaction.set(docRefs[index], { roomAssign });
              } else if (index === CC.ONE) {
                const bookUpdatedAt = FirestoreService.arrayUnion(
                  MomentService.newDate());
                transaction.set(docRefs[index],
                  { [data.original.key]: { bookUpdatedAt } }, { merge: true });
              } else if (index > CC.ONE) {
                transaction.set(docRefs[index], item, { merge: true });
              }
            });
            return Promise.resolve(true);
          }
        }
      });
    })
      .then(updateResult => result = updateResult)
      .catch(error => this.reportingService.errorReport(
        BC.MESSAGE.ERROR.PLEASE_RETRY,
        BC.TITLE.FAIL.UPDATE,
        'firestoreUpdateBookingDocument', error));
    return result;
  }

  /**
   * firestore予約情報削除
   *
   * @param data 予約情報クラスインスタンス
   * @returns Promise<boolean> 予約情報削除成否
   */
  private firestoreDeleteBookingDocument = async (data: BookingData):
    Promise<boolean> => {
    let result = false;

    // Capacity判定およびルーム割当処理用にルーム情報をセット
    await this.roomsService.prepareRooms();

    // 取消対象となる時間帯の各documentを取得、対応する削除用データ作成
    const docRefs: DocumentReference[] = [];
    const items: Booking[] = [];
    // 先にroomAssign取得用のdocRefを追加
    docRefs.push(this.firestoreService.getDocumentReference(
      [CC.COLLECTION.KEY.BOOKINGS, data.date]));
    items.push(null);
    // 次にuser更新用のdocRefを追加
    docRefs.push(this.firestoreService.getDocumentReference(
      [CC.COLLECTION.KEY.USERS, data.original.uid]));
    items.push(null);
    this.prepareFirestoreTransaction(docRefs, items, data, true);

    // トランザクション
    await this.firestoreService.runTransaction(transaction => {
      const promises: Promise<DocumentSnapshot>[] = [];
      docRefs.forEach(docRef => promises.push(transaction.get(docRef)));
      return Promise.all(promises).then(docs => {

        // 自分が予約済みでない時間帯を含んでいればエラーリジェクト
        // いずれも該当しなければトランザクション内で予約データを削除
        let isNotBooked = false;
        let onlineCount: number = 0;
        let oldRoomAssign: RoomAssign;
        docs.forEach(doc => {
          if (doc.id === data.date) {
            oldRoomAssign = doc.get(CC.DOCUMENT.KEY.ROOM_ASSIGN);
          } else if (doc.id === data.original.uid) {
            onlineCount = doc.get(CC.DOCUMENT.KEY.ONLINE_COUNT);
          } else {
            const userKey: string = doc.get(data.original.uid);
            isNotBooked = isNotBooked || (!doc.exists()
              || (doc.exists() && userKey !== data.original.key));
          }
        });

        if (isNotBooked) {
          return Promise.reject(new BookingError(BEC.unbooked));
        } else {
          // roomAssignのパズルを解く
          // ここでは削除のみ、失敗は異常
          const roomAssign: RoomAssign | boolean = this.roomsService
            .assignRooms(data, oldRoomAssign, data.original.key, true);
          if (!roomAssign) {
            return Promise.reject(new BookingError(BEC.unbooked));
          } else {
            items.forEach((item, index) => {
              if (index === CC.ZERO) {
                transaction.set(docRefs[index], { roomAssign });
              } else if (index === CC.ONE) {
                onlineCount++;
                const bookUpdatedAt = FirestoreService.arrayUnion(
                  MomentService.newDate());
                transaction.set(docRefs[index], {
                  onlineCount, [data.original.key]: { bookUpdatedAt }
                }, { merge: true });
              } else if (index > CC.ONE) {
                transaction.set(docRefs[index], item, { merge: true });
              }
            });
            return Promise.resolve(true);
          }
        }
      });
    })
      .then(deleteResult => result = deleteResult)
      .catch(error => this.reportingService.errorReport(
        BC.MESSAGE.ERROR.PLEASE_RETRY,
        BC.TITLE.FAIL.CANCEL,
        'firestoreDeleteBookingDocument', error));
    return result;
  }

  /**
   * Dashboard予約情報パネル用一覧表示データ生成（firestore接続あり）
   *
   * @param user 対象ユーザー
   * @returns Promise<ListCell[]> 予約情報一覧表示データ
   */
  public firestoreGetBookingListData = async (user: User): Promise<ListCell[]> => {
    LoadingService.on();
    const data: Map<string, BookingCell> = new Map<string, BookingCell>();
    await this.firestoreService.searchDocumentFromCollectionGroup(
      CC.COLLECTION.KEY.TIMES,
      [{ field: CC.DOCUMENT.KEY.USERS, op: CC.DOCUMENT.OP.AC, value: user.uid }])
      .then(snapshot => snapshot.docs.forEach(doc => {
        const date: string = doc.get(CC.DOCUMENT.KEY.DATE);
        const start: string = doc.get(CC.DOCUMENT.KEY.TIME);
        const end: string = this.getOffsetTime(start, CC.ONE);
        const userKey: string = doc.get(user.uid);
        const attendanceStatus: AttendanceStatus =
          user.getAttendance(userKey).status;
        if (!data.has(userKey)) {
          data.set(userKey, {
            mode: BookingCellMode.booked,
            date, start, end,
            uid: user.uid, key: userKey, attendanceStatus
          });
        } else {
          data.get(userKey).end = end;
        }
      }))
      .catch(error => this.reportingService.errorReport(
        BC.MESSAGE.ERROR.PLEASE_RETRY,
        BC.TITLE.FAIL.GETDATA,
        'getBookingListData', error));

    const list: ListCell[] = [];
    let isActive = true;
    data.forEach(cell => {
      const isEnded: boolean = MomentService.isSameOrBefore(
        MomentService.getMomentFromDateTime(cell.date, cell.end));
      const cellMode: ListCellMode = isEnded ? ListCellMode.closed :
        isActive ? ListCellMode.active : ListCellMode.inactive;
      const cellText: string = this.getDisplayInfo(new BookingData().setData(cell));
      const cellClass: string = isEnded ? CC.CLASS.DASHBOARD.CLOSED :
        isActive ? CC.CLASS.DASHBOARD.ACTIVE : CC.CLASS.DASHBOARD.INACTIVE;
      const attendance: string = (cell.attendanceStatus === AttendanceStatus.end
        || cell.attendanceStatus === AttendanceStatus.start)
        ? BC.TEXT.ATTENDANCE : BC.TEXT.ABSENCE;
      const cellTitle: string = isEnded ? attendance : BC.TEXT.OPEN_MODAL;

      if (!isEnded && isActive) {
        isActive = false;
        list.reverse();
      }
      list.push({
        mode: cellMode,
        text: cellText,
        class: cellClass,
        title: cellTitle,
        data: cell
      });
    });
    LoadingService.off();
    return list;
  }

  /**
   * Dashboardの予約情報セルをクリックした場合、
   * modalに渡す情報をこのサービスに保持し、
   * 予約一覧に遷移する
   *
   * @param cell 予約一覧のセルと同等の予約情報
   */
  public bookingListItemClick = (cell: BookingCell): void => {
    this.modalOpenData = cell;
    this.router.navigate([CC.URL.BOOKING]);
  }

  public setModalOpenData = (modalOpenData: BookingCell):
    BookingCell => this.modalOpenData = modalOpenData

  public getModalOpenData = ():
    BookingCell => this.modalOpenData

  public setLastDisplayDate = (lastDisplayDate: string):
    string => this.lastDisplayDate = lastDisplayDate

  public getLastDisplayDate = ():
    string => this.lastDisplayDate || this.getDate()

  public roomKnock = (bookingData: BookingCell): void => {
    this.roomsService.openAssignedRoom(bookingData);
  }
}
