import { CommonConstants as CC } from 'src/app/constants/common.constants';
import { ErrorConstants as EC } from 'src/app/constants/error.constants';

export enum QuestionErrorCode {
    plan_not_match = 'question/plan-not-match',
    count_not_remain = 'question/count-not-remain',
    unknown = 'question/unknown',
}

export class QuestionError {
    code: QuestionErrorCode;
    message?: string;
    constructor(code: QuestionErrorCode, message: string = CC.BLANK) {
        this.code = code;
        this.message = message || EC.ERRORS[code];
    }
}