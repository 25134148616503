import { environment } from '../../environments/environment';
import moment from 'moment';

export class AuthConstants {

    static readonly FUNCTIONS = {
        IS_USER_EXISTS: {
            NAME: 'jsonIsUserExistsByEmailAndBirthdate',
            RESULT: 'isExists',
        },
        A8_SEND: {
            NAME: 'a8Send',
            RESULT: 'result',
        },
    };

    static readonly REQUIREMENTS = {
        MIN_AGE: environment.minAge,
        MAX_AGE: environment.maxAge,
    };

    static readonly FORGET_ME_NOT = {
        COOKIE: {
            KEY: 'fmn',
            VALUE: '1',
            EXPIRATION: 30,
            PATH: '/',
        },
        EXPIRATION: {
            AMOUNT: 3,
            FORMAT: 'd' as moment.unitOfTime.DurationConstructor,
        }
    };

    static readonly GTAG = {
        TRIAL: 'AW_OIxk',
        COURSE: 'AW_uXua',
        LIGHT: 'AW_lQJS',
        EXPIRATION: 3650,
    }

    static readonly PATTERN = {
        EMAIL: '^[a-z0-9._%+\\-]+@[a-z0-9.\\-]+[.]{1}[a-z]{2,}$',
        BIRTHDATE: '^\\d{4}\\d{2}\\d{2}$',
        PHONE: '^0\\d{9,10}$',
        ZIPCODE: '^\\d{3}\\d{4}$',
        PASSWORD: '^(?=.*?[a-zA-Z])(?=.*?\\d)[a-zA-Z\\d]{8,32}$',
    };

    static readonly TITLE = {
        SUCCESS: {
            SIGNUP: 'ユーザー登録に成功しました',
            SIGNIN: 'ログインしました',
            SIGNOUT: 'ログアウトしました',
            FORGET: 'メールを送信しました',
            RESEND: 'メールを送信しました',
            UPDATE: {
                INFO: 'ユーザー情報を変更しました',
                EMAIL: 'メールアドレスを変更しました',
                PASSWORD: 'パスワードを変更しました',
            },
            FORGET_ME_NOT: {
                ON: '自動ログイン：オン',
                OFF: '自動ログイン：オフ',
            },
            PURCHASE: '決済関連処理が完了しました',
        },
        WARNING: {
            EMAIL_NOT_VERIFIED: 'メールアドレスが未認証です。',
        },
        FAIL: {
            UNKNOWN: 'エラーが発生しました',
            SIGNUP: 'ユーザー登録に失敗しました',
            SIGNIN: 'ログインに失敗しました',
            SIGNOUT: 'ログアウトに失敗しました',
            FORGET: 'メール送信に失敗しました',
            RESEND: 'メール送信に失敗しました',
            UPDATE: {
                INFO: 'ユーザー情報変更に失敗しました',
                EMAIL: 'メールアドレス変更に失敗しました',
                PASSWORD: 'パスワード変更に失敗しました',
            },
            GET_USER_INFO: 'ユーザー情報の取得に失敗しました',
            PURCHASE: '決済関連処理が取消されました',
            CLOCK_IS_OFF: 'パソコンの時刻がずれています',
        },
    };

    static readonly MESSAGE = {
        NAME_POSTFIX: '　さん',
        GREETING:
        {
            MORNING: 'おはようございます！',
            AFTERNOON: 'こんにちは！',
            EVENING: 'こんばんは！',
            BYE: 'おつかれさまでした！',
            CONTINUE: '引き続ぎかんばりましょう！',
        },
        INFO: {
            LETS_START: 'さぁ、ログインしてみましょう！',
            VERIFY_EMAIL_SENT: 'メールアドレス認証用メールを送信しました。',
            PLEASE_SIGNIN: '再度ログインしてください。',
            FORGET_ME_NOT: {
                ON_PRE: 'ログイン状態を',
                ON_POST: '日間維持します。' +
                    '共用パソコンなどではチェックしないでください。',
                OFF: 'ブラウザを閉じるとログアウトします。',
            },
        },
        WARNING: {
            EMAIL_NOT_VERIFIED: '「no-reply@member.digskill.net」からのメール' +
                '「【Dig Skill】ご登録メールアドレスの確認」をご確認の上、<br>' +
                'メールアドレス認証を行ってください。',
        },
        ERROR: {
            EMAIL_ALREADY_VERIFIED: '既にメールアドレスが認証済みです。',
            USER_NOT_FOUND: 'ユーザーが見つかりませんでした。',
            PLEASE_RETRY: '再度実行してください。',
            PLEASE_CHECK_PASSWORD: 'パスワードを確認してください',
            CLOCK_IS_OFF: '設定し直してから、再度ログインしてください。',
        },
    };

    static readonly HOURS = {
        MORNING: 4,
        AFTERNOON: 10,
        EVENING: 17,
    };
}
