<div id="trialSignupPanel" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="wrapper">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h1 class="modal-title">新規ユーザー登録</h1>
          </div>
          <div class="modal-body">
            <form id="validationWrap" name="validationWrap" class="needs-validation" novalidate>

                <div class="form-group">
                  <label for="name" class="col-form-label">氏名</label>
                  <div class="">
                    <input id="name" name="name" type="text" class="form-control" required [(ngModel)]="user.name"
                      placeholder="氏名">
                    <span class="invalid-feedback" role="alert">
                      <strong>名前を入力してください</strong>
                    </span>
                  </div>
                </div>

                <div class="form-group">
                  <label for="email" class="col-form-label">メールアドレス</label>
                  <div class="">
                    <input id="email" name="email" type="email" class="form-control" required [(ngModel)]="user.email"
                    placeholder="メールアドレス" pattern="{{patternEmail}}">
                    <span class=" invalid-feedback" role="alert">
                      <strong>メールアドレスを入力してください</strong>
                    </span>
                  </div>
                </div>

                <div class="form-group">
                  <label for="password" class="col-form-label">パスワード</label>
                  <div class="">
                    <input id="password" name="password" type="password" class="form-control"
                    pattern="{{patternPassword}}" required [(ngModel)]="user.password" placeholder="パスワード"
                    data-toggle="tooltip" data-placement="top" title="半角英数混在、<br>8文字以上、32文字以下で<br>入力してください">
                    <span class="invalid-feedback" role="alert">
                      <strong>パスワードを入力してください</strong>
                    </span>
                  </div>
                </div>

                <div class="form-group">
                  <label for="passwordConfirm" class="col-form-label">パスワード確認</label>
                  <div class="">
                    <input id="passwordConfirm" name="passwordConfirm" type="password" class="form-control" required
                    [(ngModel)]="user.passwordConfirm" placeholder="パスワード（再入力）">
                    <span class="invalid-feedback" role="alert">
                      <strong>確認のためにパスワードを再入力してください</strong>
                    </span>
                  </div>
                </div>

                <div class="modal-footer">
                  <p class="privacy-policy">
                    <a href="https://lp.digskill.net/common/policy/" target="_blank">【Dig Skillサービス利用規約】
                      <span class="inline-block">および【個人情報の取扱いについて】</span>
                    </a>
                    <br>
                    に同意の上、お申し込みください。
                  </p>
                  <button type="button" class="btn" (click)="signup()">新規登録</button>
                  <p class="registered"><a href="">アカウントをお持ちの方はこちら</a></p>
                </div>
              </form>
          </div>
        </div>
      </div>
    </div>
</div>
