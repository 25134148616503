import { BookingCell } from '../class/BookingCell';
import { Step } from './Step';

export enum ListCellMode {
  active,
  inactive,
  closed,
  other
}
export interface ListCell {
  mode: ListCellMode;
  text: string;
  class: string;
  title?: string;
  data?: BookingCell | Step;
}
