import { CommonConstants as CC } from 'src/app/constants/common.constants';
import { ErrorConstants as EC } from 'src/app/constants/error.constants';

export enum BookingErrorCode {
    already_booked = 'booking/already-booked',
    full_booked = 'booking/full-booked',
    unbooked = 'booking/unbooked',
    room_assign_failed = 'booking/room-assign-failed',
    room_reassign_failed = 'booking/room-reassign-failed',
    plan_not_match = 'booking/plan-not-match',
    count_not_remain = 'booking/count-not-remain',
    unknown = 'booking/unknown',
}

export class BookingError {
    code: BookingErrorCode;
    message?: string;
    constructor(code: BookingErrorCode, message: string = CC.BLANK) {
        this.code = code;
        this.message = message || EC.ERRORS[code];
    }
}